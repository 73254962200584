import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import Header from "../layout/header";
import Footer from "../layout/footerHome";
import LinesEllipsis from "react-lines-ellipsis";

// import { Carousel } from 'react-responsive-carousel';
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { getBanner, getData } from "../services/getService";

import Loading from "../components/loading";
import TagView from "../components/tagview";

import calendarIcon from "../assets/img/icon/Calendar.svg";
// import bg1 from '../assets/img/sec1.png';
// import bg2 from '../assets/img/sec2.png';
// import vdo from '../assets/img/VDO.png';

const Home = () => {
  // const handleDragStart = (e) => e.preventDefault();

  // const responsive = {
  //     0: { items: 1.2 },
  //     568: { items: 2 },
  //     1024: { items: 5 },
  // };

  const [isLoading, setIsLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  // const [partnersData, setPartnersData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchBanner(), fetchDataNews(), fetchDataEvent()]).then(
        (res) => {
          setIsLoading(false);
        }
      );
    }
    return () => {
      called.current = false;
    };
  }, []);

  const fetchDataNews = async () => {
    getData("news")
      .then((response) => {
        if (response.status === "success") {
          setNewsData(response.data);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const fetchDataEvent = async () => {
    getData("upcomingEvent")
      .then((response) => {
        if (response.status === "success") {
          setEventData(response.data);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const fetchBanner = async () => {
    getBanner()
      .then((response) => {
        if (response.status === "success") {
          setBannerData(response.data);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="home" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="" id="page-wrap">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 position-relative">
                  <div className="w-100 position-relative">
                    <div className="banner-custom">
                      {/* <AliceCarousel mouseTracking
                                                autoPlay
                                                autoPlayInterval={1500}
                                                animationDuration={2000}
                                                animationType="slide"
                                                touchTracking={false}
                                                infinite
                                                handleDragStart
                                                controlsStrategy="alternate"
                                                disableDotsControls
                                                disableButtonsControls
                                                renderPrevButton={() => {
                                                    return <p className="position-absolute">
                                                        <img src={sorn} className="sorn-left" />
                                                    </p>
                                                }}
                                                renderNextButton={() => {
                                                    return <p className="position-absolute end-0">
                                                        <img src={sorn} className="sorn-right" />
                                                    </p>
                                                }} > */}

                      {bannerData.length
                        ? bannerData.map((val, index) => (
                            <div
                              className="position-relative gar-black"
                              key={index}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMG_LINK +
                                  "/uploads/banner/" +
                                  val.id +
                                  "/" +
                                  val.img
                                }
                                alt=""
                                className="w-100 d-none d-md-block"
                              />
                              <img
                                src={
                                  process.env.REACT_APP_IMG_LINK +
                                  "/uploads/banner/" +
                                  val.id +
                                  "/" +
                                  val.img_m
                                }
                                alt=""
                                className="w-100 d-block d-md-none"
                              />
                              {/* <div className='position-absolute box-text-home d-none d-md-block'>
                                                            <a href='/' className="hbtn hb-fill-right">เข้าร่วม</a>
                                                        </div> */}
                            </div>
                          ))
                        : null}
                      {/* </AliceCarousel> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="my-5" id="page-wrap">
            <div className="container-fluid pb-5 px-lg-5">
              <div className="row text-start">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="topic mb-4">
                    Latest News
                    <span className="float-end">
                      <a
                        href={"/newsandgallery/news"}
                        className="font-16 a-null"
                      >
                        Read more
                      </a>
                    </span>
                  </div>
                  {newsData.length ? (
                    newsData.slice(0, 4).map((val, index) => (
                      <a
                        href={"/newsandgallery/news/" + val.id}
                        className="a-news pb-4 mb-5"
                        key={index}
                      >
                        <div className="row text-start">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div>
                              <img
                                src={
                                  process.env.REACT_APP_IMG_LINK +
                                  "/uploads/news/" +
                                  val.id +
                                  "/" +
                                  val.banner
                                }
                                className="w-100 rounded" alt=""
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="news-topic"
                                  dangerouslySetInnerHTML={{
                                    __html: val.topic,
                                  }}
                                />
                            <div className="font-14 mt-2">
                              {moment(val.dateActive).format("D/MM/Y")}
                            </div>
                            {/* <div className="mt-1">
                              <TagView myString={val.tag} />
                            </div> */}
                            <div className="mt-4">
                              <LinesEllipsis
                                text={val.subTopic}
                                maxLine="3"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            </div>
                            <div className="mt-3">
                              <a style={{ color: "#e6c12f" }}>Read More</a>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))
                  ) : (
                    <div>-- No Data --</div>
                  )}
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="ps-lg-5">
                    <div className="topic mb-4">
                      <img
                        src={calendarIcon}
                        className=""
                        alt=""
                        style={{ width: "43px" }}
                      />{" "}
                      Upcoming Events
                      <span className="float-end">
                        <a
                          href={"/activities/upcomingEvent"}
                          className="font-16 a-null"
                        >
                          See all
                        </a>
                      </span>
                    </div>
                    <hr className="mb-5" />
                    {eventData.length ? (
                      eventData.slice(0, 4).map((val, index) => (
                        <a
                          href={"/activities/upcomingEvent/" + val.id}
                          className="a-news mb-2"
                          key={index}
                        >
                          <div className="box-upcoming-event mb-3">
                            <div className="news-topic">{val.topic}</div>
                            <div>{val.dateText}</div>
                            <div>{val.subTopic}</div>
                          </div>
                        </a>
                      ))
                    ) : (
                      <div>-- No Data --</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* s
                    <section className="my-5" id="page-wrap">
                        <div className="container">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-6 col-lg-5">
                                    <div>
                                        <div className='font-28 weight-400'>
                                            SEAMEO Regional Centre for <br />
                                            Sufficiency Economy Philosophy<br />
                                            for Sustainability
                                        </div>
                                        <div className='mt-35'>
                                            <span className='font-60 weight-700 me-3'>SEAMO</span>
                                            <span className='font-100 weight-700'>
                                                <span style={{ color: '#E73E2E' }}>S</span>
                                                <span style={{ color: '#F5B320' }}>E</span>
                                                <span style={{ color: '#62B346' }}>P</span>
                                                <span style={{ color: '#F08E31' }}>S</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-7">
                                    <img src={bg1} className="w-100" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="my-5" id="page-wrap">
                        <div className="container">
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-10">
                                    <div className='text-center'>
                                        <div className='font-42 weight-700 mb-3'>What is SEP?</div>
                                        <div className="font-20">
                                            The Sufficiency Economy Philosophy (SEP) is a fundamental principle developed
                                            by His Majesty the Late King Bhumibol Adulyadej in the 1970s based on the foundational
                                            traits of moderation, reasonableness, and prudence, adding knowledge and virtue as guidelines for living.
                                            This philosophy is an approach to sustainable development that promotes utilizing knowledge and virtue
                                            to create a sense of moderation, reasonableness, and prudence. It stresses the middle path as the overriding
                                            principle for appropriate conduct and way of life for the entire populace.
                                        </div>
                                        <div className='my-5'>
                                            <a href="/about/centresOverview" className='btn btn-about'>
                                                About Us
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mt-5" id="page-wrap">
                        <div className="container">
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className=''>
                                        <div className='font-42 weight-700 mb-3'>NEWS & EVENT</div>
                                        <div className='mb-5'>
                                            <a href="/" className='btn btn-see py-1'>
                                                See All
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container px-3 mt-3">
                            <div className="row justify-content-start text-start">
                                {newsData.length ? newsData.slice(0, 4).map((val, index) => (<>
                                    <div className="col-12 col-md-4 col-lg-3 position-relative" key={index}>
                                        <div className='box-event mt-5 mt-md-3 mt-lg-0'>
                                            <div className='overflow-hidden box-event-img'>
                                                <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + val.id + '/' + val.banner} className="w-100" />
                                            </div>
                                            <div className='px-3 pt-3 pb-5'>
                                                {topicEvent(val.type)}
                                                <div className='font-24 weight-700 mt-2'>{val.topic}</div>
                                                <div className='mt-2'>{val.dateText}</div>
                                            </div>
                                        </div>
                                    </div>
                                </>)) : null}
                            </div>
                        </div>
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 position-relative">
                                    <img src={bg2} className="w-100" />
                                    <div className='position-absolute absolute-center' style={{ top: '45%' }}>
                                        <a href="/activities/trainingProgrammes" className='btn btn-about'>
                                            Training Programmes
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="py-md-0 py-lg-5" style={{ backgroundColor: '#F5F5F5' }}>
                        <div className="container py-lg-5">
                            <div className="row justify-content-center text-start py-5">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className=''>
                                        <div><img src={vdo} className="w-100" /></div>
                                        <div className='font-20 weight-500 mt-5'>
                                            <span className='position-relative px-2' style={{ border: '2px solid #F5B320' }}>
                                                <span className='' style={{ opacity: '0' }}>Video</span>
                                                <span className='absolute-center'>Video</span>
                                                <span className='menu-box2' style={{ background: '#F5F5F5' }}></span>
                                            </span>
                                        </div>
                                        <div className='font-32 weight-700 mt-3'>SEPS Good Practice Story:</div>
                                        <div className='font-20 weight-500'>
                                            eg. Dongkeelek Community, Prachin Buri, Thailand
                                        </div>
                                        <div className='font-20 weight-500 color-gray mt-4'>
                                            8 March 2023
                                        </div>
                                        <div className='mt-5 pb-5 pb-md-5 pb-lg-0'>
                                            <a href="/" className='btn btn-see py-1'>
                                                See All
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className='box-vdo pb-4'>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div><img src={vdo} className="w-100" /></div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className='font-18 weight-500 mt-5 mt-md-0'>
                                                    <span className='position-relative px-2' style={{ border: '2px solid #F5B320' }}>
                                                        <span className='' style={{ opacity: '0' }}>Video</span>
                                                        <span className='absolute-center'>Video</span>
                                                        <span className='menu-box2' style={{ background: '#F5F5F5' }}></span>
                                                    </span>
                                                </div>
                                                <div className='font-24 weight-700 mt-3'>SEPS Good Practice Story:</div>
                                                <div className='font-18 weight-500'>
                                                    eg. Dongkeelek Community, Prachin Buri, Thailand
                                                </div>
                                                <div className='font-18 weight-500 color-gray mt-4'>
                                                    8 March 2023
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='box-vdo pb-4'>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div><img src={vdo} className="w-100" /></div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className='font-18 weight-500 mt-5 mt-md-0'>
                                                    <span className='position-relative px-2' style={{ border: '2px solid #F5B320' }}>
                                                        <span className='' style={{ opacity: '0' }}>Video</span>
                                                        <span className='absolute-center'>Video</span>
                                                        <span className='menu-box2' style={{ background: '#F5F5F5' }}></span>
                                                    </span>
                                                </div>
                                                <div className='font-24 weight-700 mt-3'>SEPS Good Practice Story:</div>
                                                <div className='font-18 weight-500'>
                                                    eg. Dongkeelek Community, Prachin Buri, Thailand
                                                </div>
                                                <div className='font-18 weight-500 color-gray mt-4'>
                                                    8 March 2023
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='box-vdo pb-4'>
                                        <div className='row'>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div><img src={vdo} className="w-100" /></div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className='font-18 weight-500 mt-5 mt-md-0'>
                                                    <span className='position-relative px-2' style={{ border: '2px solid #F5B320' }}>
                                                        <span className='' style={{ opacity: '0' }}>Video</span>
                                                        <span className='absolute-center'>Video</span>
                                                        <span className='menu-box2' style={{ background: '#F5F5F5' }}></span>
                                                    </span>
                                                </div>
                                                <div className='font-24 weight-700 mt-3'>SEPS Good Practice Story:</div>
                                                <div className='font-18 weight-500'>
                                                    eg. Dongkeelek Community, Prachin Buri, Thailand
                                                </div>
                                                <div className='font-18 weight-500 color-gray mt-4'>
                                                    8 March 2023
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pt-md-5">
                        <div className="container pt-5">
                            <div className="row justify-content-center pt-0 pt-md-0">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className='font-42 weight-700 mb-3'>Strategic Partners</div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="row justify-content-center align-items-center py-5">
                                        {partnersData.length ? partnersData.map((val, index) => (
                                            <div className="col-6 col-md-2 col-lg-2 mb-3" key={index}>
                                                <div><img src={process.env.REACT_APP_IMG_LINK + "/uploads/partners/" + val.id + '/' + val.img} className="w-100" /></div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
