import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

import banner from "../../assets/img/banner-research.jpg";
import bgCommunity from "../../assets/img/bg-research.png";
import imgContent from "../../assets/img/img-content.jpg";

import Loading from "../../components/loading";
import { getData } from "../../services/getService";

const Main = () => {
  // const pr = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [mainData, setMainData] = useState([]);

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      // Promise.all([fetchData()]).then(res => {
      setIsLoading(false);
      // });
    }
    return () => {
      called.current = false;
    };
  }, []);

  // const fetchData = async () => {

  //     getData('upcomingEvent', pr.id).then(
  //         response => {
  //             if (response.status === 'success') {
  //                 setMainData(response.data)
  //             }
  //         }).catch(e => {
  //             console.log(e.response.data.error)
  //         })
  // }

  // console.log(mainData.photo ? JSON.parse(mainData.photo) : null)

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="activities" sub="research" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="pb-md-5 bg-top">
            <div style={{ background: "#F5B320", height: "200px" }}></div>
            <div className="container-fluid">
              {/* <div className='position-absolute absolute-center w-100'></div> */}
              <div
                className="position-absolute absolute-center w-100"
                style={{ top: "100px" }}
              >
                <img src={bgCommunity} className="w-100" />
              </div>
              <div
                className="row justify-content-center pb-md-5 text-start"
                style={{ marginTop: "-230px" }}
              >
                <div className="col-12 col-md-12 col-lg-10 pb-5 pt-5 mt-md-4">
                  {/* {mainData ? */}
                  <div className="board-01 py-4 px-3 px-md-5">
                    <div className="mt-4 pb-md-5">
                      <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="topic weight-700 text-center mb-5">
                            {/* {mainData.topic} */}
                            RESEARCH & DEVELOPMENT
                          </div>
                          <div className="">
                            <img src={banner} className="w-100 rounded" />
                            {/* <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + mainData.id + '/' + mainData.banner} className="w-100 rounded" /> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="py-3 p-md-5" style={{}}>
                            <div
                              className="font-18 content"
                              style={{ lineHeight: "2" }}
                            >
                              Lorem ipsum dolor sit amet consectetur. Lacus
                              porttitor nisl ac donec euismod donec malesuada
                              facilisis elit. Netus amet suspendisse diam
                              tristique iaculis volutpat. Ultricies scelerisque
                              aenean metus pulvinar sit. Commodo orci quis
                              vulputate sit faucibus vel porttitor lacus. Nisi
                              adipiscing ipsum lacinia eget velit enim ut. Purus
                              nunc neque consectetur lectus dignissim tortor non
                              amet ridiculus. In mi libero egestas hendrerit
                              risus orci vitae. Orci est dignissim sed
                              scelerisque congue et. Tortor neque ut nisi ut
                              amet tempus morbi vestibulum consequat. Tortor ac
                              id varius vel nulla velit a. Dis bibendum id nec
                              amet faucibus tristique pharetra mi. Ut purus quam
                              tincidunt et id amet in. Malesuada risus netus
                              eget euismod senectus ultrices turpis cursus. Non
                              donec consequat in mi. Viverra gravida mauris est
                              praesent. Vel urna pulvinar in ut risus. Vel diam
                              velit elementum ut cursus viverra tincidunt
                              viverra velit. Velit vitae netus est donec ut.
                              <br />
                              <br />
                              Dignissim habitasse mauris lacus aliquet eu libero
                              rhoncus viverra nibh. Odio elementum mauris nec mi
                              dignissim. Consectetur felis est interdum
                              tincidunt aliquam quisque praesent fusce at. Vel
                              commodo amet lacinia commodo est consectetur. Nunc
                              mus eget dui adipiscing viverra blandit. Amet vel
                              at elementum urna. Ultricies tristique mi aliquet
                              nisl turpis dui vel. Ullamcorper urna ac tempor
                              orci cursus nisi et egestas. Vel eu egestas sit
                              suspendisse. Rutrum ac fringilla in arcu at in.
                              Donec viverra volutpat in pretium consequat nunc.
                              Ultrices pretium ornare id nec quis ornare in.
                              Consectetur diam congue eget habitant posuere.
                              Feugiat tincidunt volutpat facilisis aliquam
                              elementum proin. Quis praesent mi non id
                              vestibulum porttitor amet hac cursus. Placerat
                              mattis morbi eu non tellus urna
                              <div className="py-5 p-md-5">
                                <img
                                  src={imgContent}
                                  className="w-100 rounded"
                                />
                              </div>
                              Lorem ipsum dolor sit amet consectetur. Lacus
                              porttitor nisl ac donec euismod donec malesuada
                              facilisis elit. Netus amet suspendisse diam
                              tristique iaculis volutpat. Ultricies scelerisque
                              aenean metus pulvinar sit. Commodo orci quis
                              vulputate sit faucibus vel porttitor lacus. Nisi
                              adipiscing ipsum lacinia eget velit enim ut. Purus
                              nunc neque consectetur lectus dignissim tortor non
                              amet ridiculus. In mi libero egestas hendrerit
                              risus orci vitae. Orci est dignissim sed
                              scelerisque congue et. Tortor neque ut nisi ut
                              amet tempus morbi vestibulum consequat. Tortor ac
                              id varius vel nulla velit a. Dis bibendum id nec
                              amet faucibus tristique pharetra mi. Ut purus quam
                              tincidunt et id amet in. Malesuada risus netus
                              eget euismod senectus ultrices turpis cursus. Non
                              donec consequat in mi. Viverra gravida mauris est
                              praesent. Vel urna pulvinar in ut risus. Vel diam
                              velit elementum ut cursus viverra tincidunt
                              viverra velit. Velit vitae netus est donec ut.
                              <br />
                              <br />
                              Dignissim habitasse mauris lacus aliquet eu libero
                              rhoncus viverra nibh. Odio elementum mauris nec mi
                              dignissim. Consectetur felis est interdum
                              tincidunt aliquam quisque praesent fusce at. Vel
                              commodo amet lacinia commodo est consectetur. Nunc
                              mus eget dui adipiscing viverra blandit. Amet vel
                              at elementum urna. Ultricies tristique mi aliquet
                              nisl turpis dui vel. Ullamcorper urna ac tempor
                              orci cursus nisi et egestas. Vel eu egestas sit
                              suspendisse. Rutrum ac fringilla in arcu at in.
                              Donec viverra volutpat in pretium consequat nunc.
                              Ultrices pretium ornare id nec quis ornare in.
                              Consectetur diam congue eget habitant posuere.
                              Feugiat tincidunt volutpat facilisis aliquam
                              elementum proin. Quis praesent mi non id
                              vestibulum porttitor amet hac cursus. Placerat
                              mattis morbi eu non tellus urna
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* : null} */}
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Main;
