import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


// import ReactGA from 'react-ga';

// import logo from "./logo.svg";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/style.css";
import "./assets/css/menu.css";
import "./assets/css/radioandcheckbox.css";
import "./assets/css/button.css";
import "./assets/css/respornsive.css";
import "./assets/css/yellowBody.scss";

import Home from "./pages/home";
import AboutCentresOverview from "./pages/about/centresOverview";
import AboutWhoweare from "./pages/about/whoweare";
import AboutSufficiencyEconomy from "./pages/about/sufficiencyEconomy";
import AboutCentresGoverningBoard from "./pages/about/centresGoverningBoard";
import AboutCentresStructure from "./pages/about/centresStructure";
import AboutSepConcept from "./pages/about/sepConsept";
import AboutWhatwedo from "./pages/about/whatwedo";
import AboutOrganisationalStructure from "./pages/about/organisationalStructure";

import ThematicAreasSepCulture from "./pages/thematicAreas/sepCulture";

import ActivitiesTrainingProgrammes from "./pages/activities/trainingProgrammes";
import ActivitiesTrainingProgrammesDetail from "./pages/activities/trainingProgrammesDetail";

import CapacitySep4school from "./pages/activities/capacity/sep4school";
import CapacitySepED from "./pages/activities/capacity/sepED";
import CapacitySepRegionalYouthCamp from "./pages/activities/capacity/sepRegionalYouthCamp";
import CapacityWasteHero from "./pages/activities/capacityWasteHero";

import CommunityDevelopment from "./pages/activities/communityDevelopment";
import CommunitySEPintheCommunity from "./pages/activities/community/sepintheCommunity";

import ResearchDevelopment from "./pages/activities/researchDevelopment";
import ResearchClassroom from "./pages/activities/research/classroomResearch";

import InformationVirtualLearningSeries from "./pages/activities/information/virtualLearningSeries";
import InformationOtherWebinars from "./pages/activities/information/otherWebinars";

import PartnershipWasteHero from "./pages/activities/partnership/partnershipWasteHero";
import Partnership from "./pages/activities/partnership/partnership";
import PartnershipPromotion from "./pages/activities/partnership/promotionSchools";

import Webinars from "./pages/activities/webinars";
import WebinarsDetail from "./pages/activities/information/webinarsDetail";

import ActivitiesUpcomingEvent from "./pages/activities/upcomingEvent";
import ActivitiesUpcomingEventDetail from "./pages/activities/upcomingEventDetail";

import KnowledgeHubDecoding from "./pages/knowledge/decoding";
import KnowledgeHubSepsTalk from "./pages/knowledge/sepsTalk";
import KnowledgeHubPublications from "./pages/knowledge/publications";

import NewsAndGalleryNews from "./pages/newsandgallery/news";
import NewsAndGalleryGallery from "./pages/newsandgallery/gallery";
import NewsAndGalleryNewsDetail from "./pages/newsandgallery/newsDetail";
import NewsAndGalleryGalleryDetail from "./pages/newsandgallery/galleryDetail";
import NewsAndGalleryGalleryArchives from "./pages/newsandgallery/archives";
import NewsAndGalleryGalleryArchivesDetail from "./pages/newsandgallery/archivesDetail";
import NewsAndGalleryGalleryArchivesWasteDetail from "./pages/newsandgallery/wasteDetail";
import NewsAndGalleryGalleryArchivesAwardsDetail from "./pages/newsandgallery/awardsDetail";

import AwardsGrantsSeaWasteHeroAwards from "./pages/awards/seaWasteHeroAwards";
import AwardsGrantsSeaWasteHeroAwards2024 from "./pages/awards/seaWasteHeroAwards2024";
import AwardsGrantsResearchGrants from "./pages/awards/researchGrants";

import Contact from "./pages/contact";



// import RouteChangeTracker from './components/RouteChangeTracker'

// const TRACKING_ID = "G-4Q4S8TSKYP"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname);

function App() {
  return (
    <div className="App">

      {/* <RouteChangeTracker /> */}
      <BrowserRouter basename="">
        {/* <ScrollTotop /> */}

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/about/centresOverview"
            element={<AboutCentresOverview />}
          />
          <Route
            path="/about/whoweare"
            element={<AboutWhoweare />}
          />
          <Route
            path="/about/sufficiencyEconomy"
            element={<AboutSufficiencyEconomy />}
          />
          <Route
            path="/about/centresGoverningBoard"
            element={<AboutCentresGoverningBoard />}
          />
          <Route
            path="/about/centresStructure"
            element={<AboutCentresStructure />}
          />
          <Route
            path="/about/sepconcept"
            element={<AboutSepConcept />}
          />
          <Route
            path="/about/whatwedo"
            element={<AboutWhatwedo />}
          />
          <Route
            path="/about/organisationalStructure"
            element={<AboutOrganisationalStructure />}
          />

          <Route
            path="/thematicAreas/:id"
            element={<ThematicAreasSepCulture />}
          />

          <Route
            path="/activities/capacitySep4school"
            element={<CapacitySep4school />}
          />
          <Route
            path="/activities/capacityWasteHero"
            element={<CapacityWasteHero />}
          />
          <Route
            path="/activities/capacitySepEd"
            element={<CapacitySepED />}
          />
          <Route
            path="/activities/capacitySepRegionalYouthCamp"
            element={<CapacitySepRegionalYouthCamp />}
          />
          <Route
            path="/activities/communityDevelopment"
            element={<CommunityDevelopment />}
          />
          <Route
            path="/activities/communitySEPintheCommunity"
            element={<CommunitySEPintheCommunity />}
          />

          <Route
            path="/activities/researchDevelopment"
            element={<ResearchDevelopment />}
          />
          <Route
            path="/activities/researchClassroom"
            element={<ResearchClassroom />}
          />


          <Route path="/activities/informationVirtualLearningSeries" element={<InformationVirtualLearningSeries />} />
          <Route path="/activities/informationOtherWebinars" element={<InformationOtherWebinars />} />


          <Route path="/activities/partnershipOurPartners" element={<Partnership />} />
          <Route path="/activities/partnershipWasteHero" element={<PartnershipWasteHero />} />
          <Route path="/activities/partnershipPromotion" element={<PartnershipPromotion />} />


          <Route path="/activities/webinars" element={<Webinars />} />
          <Route path="/activities/webinarsDetail" element={<WebinarsDetail />} />
          <Route
            path="/activities/trainingProgrammes"
            element={<ActivitiesTrainingProgrammes />}
          />
          <Route
            path="/activities/trainingProgrammesDetail/:id"
            element={<ActivitiesTrainingProgrammesDetail />}
          />

          <Route
            path="/activities/upcomingEvent"
            element={<ActivitiesUpcomingEvent />}
          />
          <Route
            path="/activities/upcomingEvent/:id"
            element={<ActivitiesUpcomingEventDetail />}
          />

          <Route
            path="/knowledge/decoding"
            element={<KnowledgeHubDecoding />}
          />
          <Route
            path="/knowledge/sepstalk"
            element={<KnowledgeHubSepsTalk />}
          />
          <Route
            path="/knowledge/publications"
            element={<KnowledgeHubPublications />}
          />

          <Route path="/newsandgallery/news" element={<NewsAndGalleryNews />} />
          <Route
            path="/newsandgallery/gallery"
            element={<NewsAndGalleryGallery />}
          />
          <Route
            path="/newsandgallery/gallery/:id"
            element={<NewsAndGalleryGalleryDetail />}
          />
          <Route
            path="/newsandgallery/news/:id"
            element={<NewsAndGalleryNewsDetail />}
          />
          <Route
            path="/newsandgallery/archives"
            element={<NewsAndGalleryGalleryArchives />}
          />
          <Route
            path="/newsandgallery/archives/:id"
            element={<NewsAndGalleryGalleryArchivesDetail />}
          />
          <Route
            path="/newsandgallery/archivesWaste/:id"
            element={<NewsAndGalleryGalleryArchivesWasteDetail />}
          />
          <Route
            path="/newsandgallery/archivesAwards/:id"
            element={<NewsAndGalleryGalleryArchivesAwardsDetail />}
          />

          <Route
            path="/awardsGrants/seaWasteHeroAwards2024"
            element={<AwardsGrantsSeaWasteHeroAwards2024 />}
          />
          <Route
            path="/awardsGrants/seaWasteHeroAwards"
            element={<AwardsGrantsSeaWasteHeroAwards />}
          />
          <Route
            path="/awardsGrants/researchGrants"
            element={<AwardsGrantsResearchGrants />}
          />

          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
