import React from "react";
import YouTube from "react-youtube";

const ViewYoutube = ({ youtubeId, ...data }) => {

    const opts = {
        playerVars: {
            width: "100%",
            // https://developers.google.com/youtube/player_parameters,
            autoplay: 0,
            rel: 0
        }
    };

    return <YouTube
        containerClassName="video-container"
        className="video-container"
        videoId={youtubeId}
        rel="0"
        opts={opts}
    />

}

export default ViewYoutube