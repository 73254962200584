import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";

import fb from '../assets/img/icon/FB.svg';
import yt from '../assets/img/icon/YT.svg';
import ig from '../assets/img/icon/IG.svg';
import linetop from '../assets/img/icon/LineTop.svg';

import { getData } from "../services/getService";

const Footer = (data) => {

    let history = useNavigate();

    // const { t } = useTranslation();
    const url = window.location.pathname;

    // const [menuactive, setMenuactive] = useState(false);
    
    const [memenu, setMemenu] = useState('0');
    const [datacount, setDatacount] = useState('0');

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([getDataIp()]).then(
                (res) => {
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const getDataIp = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        // console.log(res.data);
        fetchData(res.data.ip)
    };

    const fetchData = async (iP) => {
        getData("visitordata", iP)
            .then((response) => {
                if (response.status === "success") {
                    setDatacount(response.data.count)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div className='box-footer text-start pt-0 pt-md-0'>
                {/* <div className='row pt-0 pt-md-0'>
                    <div className="col-12 col-md-12 col-lg-8 pt-0 pt-md-0 pt-lg-5">
                        <div className='row'>
                            <div className="col-12 col-md-3 col-lg-3 pt-3 pt-lg-5">
                                <Link to='/' className='btn btn-default d-block text-start py-0'>About Us</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Centre's Activities</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Knowledge Hubs</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>News & Gallery</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Contact</Link>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pt-3 pt-lg-5">
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Centre's Overview</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Centre's Governing Board</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Centre's Structure</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>SEAMEO</Link>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pt-3 pt-lg-5">
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Training Programmes</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Webinars & Workshops</Link>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pt-3 pt-lg-5">
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Education in SEC</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>SEP & Sustainability</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Teaching & Leaning Materials</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>SEP & Organizations</Link>
                                <Link to='/' className='btn btn-default d-block text-start py-0'>Publications</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 pt-md-0 pt-lg-5">
                        <div className='mb-5 px-3 px-md-3 px-lg-0 pt-3 pt-md-0'>
                            <div className='weight-700 font-28'>ADDRESS</div>
                            <div className='weight-500 font-18'>
                                11th Floor, Natural and Environmental Building,
                                928, Sukhumvit Road, Phra Khanong, Khlong Toei,
                                Bangkok, 10110 Thailand
                            </div>
                            <div className='my-4'>
                                <Link to="/" className='btn btn-see py-1'>
                                    Direction Google map
                                </Link>
                            </div>
                            <div className='weight-500 font-18'>
                                E-mail : <a href='' className='color-black'>seps4sdg@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='row'>
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className='px-3'>
                            <img src={linetop} alt="" className="w-100" style={{ height: '2px' }} />
                        </div >
                        <div className='weight-500 px-3 pb-3'>
                            <a href='https://www.facebook.com/seameoseps' className='d-inline-block p-0' target="_blank" ><img src={fb} alt="" style={{ height: "30px" }} /></a>
                            <a href='https://www.youtube.com/@seameoseps9118' className='d-inline-block px-2' target="_blank"><img src={yt} alt="" style={{ height: "30px" }} /></a>
                            <a href='https://www.instagram.com/seameoseps' className='d-inline-block p-0' target="_blank"><img src={ig} alt="" style={{ height: "30px" }} /></a>
                            <span className='float-end d-none d-md-block'>Visitor : {datacount} | © 2023 by SEAMEO SEPS - All rights reserved.</span>
                        </div>
                        <div className='d-block d-md-none font-12 text-center'>
                            Visitor : {datacount} | © 2023 by SEAMEO SEPS - All rights reserved.
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Footer;