import React, { useState, useEffect, useRef } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

import footer from "../../assets/img/bg-archives.jpg";

import Accordion from 'react-bootstrap/Accordion';
import Loading from "../../components/loading";

import { getData } from "../../services/getService";

const CentresOverview = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});
    const [dataTwo, setDataTwo] = useState({});
    const [dataH, setDataH] = useState({});
    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("vinformation", "")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" sub="archives" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    {/* <div className="position-relative">
                        <img src={footer} className="w-100" />
                        <div className="position-absolute absolute-center-center w-100">
                            <div className="container ">
                                <div className="row text-start justify-content-center" >
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="topic">
                                            Archives
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <section className="my-5" id="page-wrap">
            <div className="container-fluid pb-5 px-lg-5">
              <div className="row text-start justify-content-center">
                <div className="col-12 col-md-11 col-lg-11"> */}

                    <section className="position-relative pb-5 pt-5 bg-top-cover" style={{ backgroundImage: `url(${footer})`, backgroundRepeat: 'no-repeat' }}>
                        <div className="container-fluid pb-5 px-lg-5">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-11 col-lg-11">

                                    <div className="row align-items-center mb-3 mb-md-5 mt-lg-5">
                                        <div className="col-12 col-md-12 col-lg-12 text-start">
                                            <div className="font-60 weight-700">Archives</div>
                                        </div>
                                    </div>
                                    <div className="text-start">
                                        <div className="sub-topic mb-4 yellow-black">
                                            Programmes and Activities
                                        </div>
                                        <Accordion defaultActiveKey="0" className="custom-li">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Information Exchange</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        {dataOne.length ? dataOne.filter((value) => value.catId !== 'waste' && value.catId !== 'awards').map((val, index) => (
                                                            <li key={index}>
                                                                {val.name} <a href={"/newsandgallery/archives/" + val.id}>{val.year}</a>
                                                            </li>
                                                        )) : null}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Partnership and Linkages</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        {dataOne.length ? dataOne.filter((value) => value.catId === 'waste').map((val, index) => (
                                                            <li key={index}>
                                                                {val.name} <a href={"/newsandgallery/archivesWaste/" + val.id}>{val.year}</a>
                                                            </li>
                                                        )) : null}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>

                                    <div className="text-start mt-5 pt-5">
                                        <div className="sub-topic mb-4 yellow-black">
                                            Awards and Grants
                                        </div>
                                        <Accordion defaultActiveKey="0" className="custom-li">
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>SEAMEO SEPS Awards</Accordion.Header>
                                                <Accordion.Body>
                                                    <ul>
                                                        {dataOne.length ? dataOne.filter((value) => value.catId === 'awards').map((val, index) => (
                                                            <li key={index}>
                                                                {val.name} <a href={"/newsandgallery/archivesAwards/" + val.id}>{val.year}</a>
                                                            </li>
                                                        )) : null}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    {/* <a href="/newsandgallery/archives/1" className="a-news box-archives mb-4">
                                        <div className="row text-start justify-content-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-4"><img src={img01} className="w-100" alt="" style={{ borderRadius: '15px' }} /></div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="position-relative pb-3 px-3 p-md-0 h-100">
                                                    <div className="sub-topic pt-md-4">Waste Hero: Reduce to
                                                        Zero Development Co-campaign</div>
                                                    <div className="position-md-absolute" style={{ bottom: '25px' }}>
                                                        <a className="btn btn-see btn-res-50 me-2 my-2 my-md-0" >2023</a>
                                                        <a className="btn btn-see btn-res-50">อาจมีปีอื่นๆ</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/newsandgallery/archives/1" className="a-news box-archives mb-4">
                                        <div className="row text-start justify-content-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-4"><img src={img02} className="w-100" alt="" style={{ borderRadius: '15px' }} /></div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="position-relative pb-3 px-3 p-md-0 h-100">
                                                    <div className="sub-topic pt-md-4">SEP Webinars</div>
                                                    <div className="position-md-absolute" style={{ bottom: '25px' }}>
                                                        <a className="btn btn-see btn-res-50 me-2 my-2 my-md-0">2023</a>
                                                        <a className="btn btn-see btn-res-50">อาจมีปีอื่นๆ</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer page="news" sub="archives" />
                </div>
            </div>
        </>
    );
};

export default CentresOverview;
