import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

import nextIcon from "../../assets/img/icon/sorn.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loading from "../../components/loading";
import { getData } from "../../services/getService";

import Modalgallery from "../../components/modalGallery";

const Main = () => {
  const pr = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [mainData, setMainData] = useState();
  const [newsData, setNewsData] = useState([]);

  const [modalgallery, setModalgallery] = useState({ view: false });

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchDataNews()]).then((res) => {
        setIsLoading(false);
      });
    }
    return () => {
      called.current = false;
    };
  }, []);

  // const fetchData = async () => {

  //     getData('news').then(
  //         response => {
  //             if (response.status === 'success') {
  //                 setMainData(response.data)
  //             }
  //         }).catch(e => {
  //             console.log(e.response.data.error)
  //         })
  // }

  const fetchDataNews = async () => {
    getData("news", pr.id)
      .then((response) => {
        if (response.status === "success") {
          setNewsData(response.data.recomment);
          setMainData(response.data.main);

          // console.log(response.data.recomment[0].id);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const tagView = (myString) => {
    var myArray = myString.split(",");

    const vview =
      myArray.length &&
      myArray.map((val, index) => (
        <span key={index} className="view-tag">
          {val}
        </span>
      ));

    return vview;
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      // <div
      //   className={className}
      //   style={{ ...style, display: "block", background: "red" }}
      //   onClick={onClick}
      // />
      <div className={className}>
        <img
          src={nextIcon}
          className="ms-2"
          style={{ width: "12px", marginTop: "-20px" }}
          onClick={onClick}
        />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      // <div
      //   className={className}
      //   style={{ ...style, display: "block", background: "green" }}
      //   onClick={onClick}
      // />
      <div className={className}>
        <img
          src={nextIcon}
          className="me-2"
          style={{
            transform: "rotate(180deg)",
            width: "12px",
            marginTop: "-20px",
          }}
          onClick={onClick}
        />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    // className: "center",
    // centerMode: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    dots: true,
    infinite: true,
    // className: "center",
    // centerMode: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // console.log(mainData.photo ? JSON.parse(mainData.photo) : null)

  // console.log(newsData.sort((a, b) => (a.dateActive < b.dateActive ? 1 : -1)));

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="news" sub="news" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="bg-top">
            <div className="container-fluid">
              <div className="row justify-content-center text-start">
                <div className="col-12 col-md-12 col-lg-10">
                  {mainData ? (
                    <div className="pt-4 px-3 px-md-3 px-lg-5">
                      <div className="mt-4">
                        <div className="row">
                          {/* <div className="col-12 col-md-12 col-lg-12">
                            <div className="mt-3">
                              <Link
                                to="/newsandGallery/news"
                                className="btn btn-back ps-0 weight-700"
                              >
                                <img
                                  src={backIcon}
                                  style={{ width: "30px" }}
                                  alt=""
                                />{" "}
                                Back
                              </Link>
                            </div>
                          </div> */}
                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="">
                              {/* <div className="font-32 weight-700"> */}
                              {/* {mainData.topic} */}
                              <div className="font-32 weight-700"
                                dangerouslySetInnerHTML={{
                                  __html: mainData.topic,
                                }}
                              />
                              {/* </div> */}
                              <div className="font-14 mt-2">
                                {moment(mainData.dateActive).format("D/MM/Y")}
                              </div>
                              {/* <div className="mt-1">
                                {tagView(mainData.tag)}
                              </div> */}
                            </div>
                          </div>

                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="">
                              <div className="pt-4">
                                {/* <img src={content1} className="w-100 rounded-30" /> */}
                                <img
                                  src={
                                    process.env.REACT_APP_IMG_LINK +
                                    "/uploads/news/" +
                                    mainData.id +
                                    "/" +
                                    mainData.banner
                                  }
                                  className="w-100 rounded"
                                  alt=""
                                />
                              </div>
                              <div className="pb-5 " style={{}}>
                                {/* <div className="mt-2 font-18">
                                  {mainData.dateText}
                                </div> */}
                                <div
                                  className="mt-3 content"
                                  style={{ lineHeight: "2" }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: mainData.detail,
                                    }}
                                  />
                                </div>
                                <div className="news-topic mb-2 mt-5">
                                  <a
                                    href={"/newsandgallery/gallery/" + mainData.id}
                                    className="a-null"
                                  >
                                    Photo Gallery{" "}
                                    <img
                                      src={nextIcon}
                                      className="ms-2 yellow-img"
                                      style={{
                                        width: "8px",
                                      }}
                                    />
                                    <img
                                      src={nextIcon}
                                      className="yellow-img"
                                      style={{
                                        width: "8px",
                                      }}
                                    />
                                  </a>
                                </div>
                                <div className="mt-
                                 box-slide-detail">
                                  {JSON.parse(mainData.photo).length < 3 ?
                                    <>
                                      {JSON.parse(mainData.photo).slice(0, 5).map((val, index) => (<>
                                        <div className="d-none d-md-inline-block" key={index} style={{ width: '35%' }}>
                                          <div className="p-1" >
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_IMG_LINK +
                                                "/uploads/news/" +
                                                mainData.id +
                                                "/" +
                                                val
                                              }
                                              className="w-100"
                                            />
                                          </div>
                                        </div>
                                      </>
                                      ))}

                                      <div className="d-block d-md-none">
                                        <Slider {...settings2}>
                                          {JSON.parse(mainData.photo).length ? JSON.parse(mainData.photo).slice(0, 5).map((val, index) => (
                                            <div className="" key={index}>
                                              <div className="p-1">
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_IMG_LINK +
                                                    "/uploads/news/" +
                                                    mainData.id +
                                                    "/" +
                                                    val
                                                  }
                                                  className="w-100"
                                                />
                                              </div>
                                            </div>
                                          ))
                                            : null}
                                        </Slider>
                                      </div>
                                    </> :
                                    <Slider {...settings}>
                                      {JSON.parse(mainData.photo).length ? JSON.parse(mainData.photo).slice(0, 5).map((val, index) => (
                                        <div className="" key={index}>
                                          <div
                                            className="p-1"

                                          // onClick={() =>
                                          //   setModalgallery({
                                          //     view: true,
                                          //     gallery: JSON.parse(
                                          //       mainData.photo
                                          //     ),
                                          //     dataUrl:
                                          //       process.env
                                          //         .REACT_APP_IMG_LINK +
                                          //       "/uploads/news/" +
                                          //       mainData.id +
                                          //       "/" +
                                          //       val,
                                          //     active: index,
                                          //   })
                                          // }
                                          >
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_IMG_LINK +
                                                "/uploads/news/" +
                                                mainData.id +
                                                "/" +
                                                val
                                              }
                                              className="w-100"
                                            />
                                          </div>
                                        </div>
                                      ))
                                        : null}
                                    </Slider>
                                  }
                                </div>

                                {/* <div className="row px-2">
                                  {JSON.parse(mainData.photo).length
                                    ? JSON.parse(mainData.photo).map(
                                        (val, index) => (
                                          <div
                                            className="col-12 col-md-4 col-lg-3 p-1"
                                            key={index}
                                          >
                                            <div
                                              className="gg-box"
                                              style={{
                                                backgroundImage: `url(${
                                                  process.env
                                                    .REACT_APP_IMG_LINK +
                                                  "/uploads/news/" +
                                                  mainData.id +
                                                  "/" +
                                                  val
                                                })`,
                                              }}
                                              onClick={() =>
                                                setModalgallery({
                                                  view: true,
                                                  gallery: JSON.parse(
                                                    mainData.photo
                                                  ),
                                                  dataUrl:
                                                    process.env
                                                      .REACT_APP_IMG_LINK +
                                                    "/uploads/news/" +
                                                    mainData.id +
                                                    "/" +
                                                    val,
                                                  active: index,
                                                })
                                              }
                                            ></div>
                                          </div>
                                        )
                                      )
                                    : null}
                                </div> */}


                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-12 col-md-12 col-lg-9 pe-md-4">
                            <div className="">
                              <div className="news-topic mb-3">
                                Recent News
                                <span className="float-end">
                                  <a
                                    href={"/newsandgallery/news"}
                                    className="font-16 a-null"
                                  >
                                    Read more
                                  </a>
                                </span>
                              </div>
                              <div className="row text-start">
                                {newsData.length ? (
                                  newsData
                                    .sort((a, b) =>
                                      a.dateActive < b.dateActive ? 1 : -1
                                    )
                                    .slice(0, 3)
                                    .map((val, index) => (
                                      <div className="col-12 col-md-12 col-lg-4">
                                        <a
                                          href={
                                            "/newsandgallery/news/" + val.id
                                          }
                                          className="a-news pb-4 mb-5"
                                          key={index}
                                        >
                                          <div className="row text-start">
                                            <div className="col-12 col-md-12 col-lg-12">
                                              <div>
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_IMG_LINK +
                                                    "/uploads/news/" +
                                                    val.id +
                                                    "/" +
                                                    val.banner
                                                  }
                                                  className="w-100 rounded"
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">

                                              <div className="news-topic mt-3"
                                                dangerouslySetInnerHTML={{
                                                  __html: val.topic,
                                                }}
                                              />
                                              <div className="font-14 mt-2">
                                                {moment(val.dateActive).format(
                                                  "D/MM/Y"
                                                )}
                                              </div>
                                              {/* <div className="mt-1">
                                                {tagView(val.tag)}
                                              </div> */}
                                              <div className="mt-4">
                                                <LinesEllipsis
                                                  text={val.subTopic}
                                                  maxLine="3"
                                                  ellipsis="..."
                                                  trimRight
                                                  basedOn="letters"
                                                />
                                              </div>
                                              <div className="mt-3">
                                                <a style={{ color: "#e6c12f" }}>
                                                  Read More
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    ))
                                ) : (
                                  <div> -- No Data --</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-lg-3 pe-md-0">
                            <div className="news-topic mb-3">Facebook Page</div>
                            <div>
                              <iframe
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fseameoseps&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                width="100%"
                                height="500"
                                style={{
                                  border: "noneo",
                                  overflow: "hidden",
                                }}
                                scrolling="no"
                                frameborder="0"
                                allowfullscreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
          <Footer page="news" sub="news" />
        </div>
      </div>

      {modalgallery.view ? (
        <Modalgallery
          show={modalgallery.view}
          onHide={() => setModalgallery({ view: false })}
          dataUrl={modalgallery.dataUrl}
          folder={"news"}
          gallery={modalgallery.gallery}
          active={modalgallery.active}
          id={pr.id}
        />
      ) : null}
    </>
  );
};

export default Main;
