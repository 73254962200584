import React, { useEffect } from "react";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

const CentresOverview = () => {

    useEffect(() => {
        // fetchData(0)
    }, []);

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="awards" sub="researchGrants" />
                <div className="wrapper bg-white box-index">
                    <div className="position-relative py-5 ">
                        <div className="w-100 py-5">
                            <div className="container my-5">
                                <div className="row text-center">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="topic">
                                            {/* Research Grants */}
                                        </div>
                                        <div className="sub-topic">
                                            {/* Coming Soon */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer page="awards" sub="researchGrants"/>
                </div>
            </div>
        </>
    );
};

export default CentresOverview;
