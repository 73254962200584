import React from "react";

const TagView = ({ myString, ffunction, ...data }) => {

    var myArray = myString.split(',');

    const setttag = (val) => {
        ffunction(val)
    }

    const vview = myArray.length && myArray.map((val, index) => (
        <span key={index} className="view-tag cursor-pointer yellow-black" onClick={(e) => setttag(val)}>{val}</span>
    ))

    return vview

}

export default TagView;