import React, { useState, useCallback, useEffect } from "react";
// import { useNavigate, Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { menuData } from "../configs/menu";

import {
  Form,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

// import ModalLogin from "../layout/modal/modalLogin";

import search from "../assets/img/icon/Search.svg";
import sorn from "../assets/img/icon/sorn.svg";
import close from "../assets/img/icon/Close.svg";
import logo from "../assets/img/logo.png";

import fb from "../assets/img/icon/FB.svg";
import yt from "../assets/img/icon/YT.svg";
import ig from "../assets/img/icon/IG.svg";
import linetop from "../assets/img/icon/LineTop.svg";

import eye from "../assets/img/icon/eye.svg";
import eyeB from "../assets/img/icon/eyeb.svg";

// import special_lecture from '../assets/pdf/special_lecture.pdf';
// import seminar from '../assets/pdf/seminar.pdf';
// import presentation from '../assets/pdf/presentation.pdf';
// import skills from '../assets/pdf/skills.pdf';

// import { Link } from "react-tiger-transition";

// import PostService from "../components/services_member/post.service";

// import Menu from '../components/menu';

const Header = (data) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [menusubOpen, setMenusubOpen] = useState({ view: false, id: 0 });
  const [menusubOpenB, setMenusubOpenB] = useState({ view: false, id: 0 });

  const [menuMore, setMenuMore] = useState(false);
  const [bodyColor, setBodyColor] = useState({ body: 'normal', font: 'normal' });

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('bodyColor'));
    if (items) {
      setBodyColor({ body: items.body, font: items.font });

      document.querySelector('html').className = items.body;
      document.querySelector('body').className = items.font;
    }

  }, []);

  const handleMenuChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const toggleMenu = () => {
    // setMenuOpen(true);
    setMenuOpen(false);
  };

  const handleMenusubChange = (state, id) => {
    if (menusubOpen.id === id) {
      setMenusubOpen({ view: !menusubOpen.view, id: id });
    } else {
      setMenusubOpen({ view: true, id: id });
    }
  };

  const handleMenusubChangeB = (state, id) => {
    if (menusubOpenB.id === id) {
      setMenusubOpenB({ view: !menusubOpenB.view, id: id });
    } else {
      setMenusubOpenB({ view: true, id: id });
    }
  };

  const callback = () => {
    setMenuMore(!menuMore)
  }

  const handlemenuColor = (e) => {
    localStorage.setItem('bodyColor', JSON.stringify({ 'body': e, font: bodyColor.font }));
    setBodyColor((prev) => ({ ...prev, body: e }));
    document.querySelector('html').className = e;
  }

  const handlemenuFont = (e) => {
    localStorage.setItem('bodyColor', JSON.stringify({ 'body': bodyColor.body, font: e }));
    setBodyColor((prev) => ({ ...prev, font: e }));
    document.querySelector('body').className = e;
  }

  // console.log(bodyColor)

  return (
    <>
      <div className="d-md-block d-lg-none menu-res">
        <div className="line-gra position-absolute"></div>
        <div className="logo-home text-start">
          <a href="/" className="btn-link-default border-0 ps-3">
            <img src={logo} alt="" className="py-2" style={{ width: "75px" }} />
          </a>
        </div>
        <div className="right">
          <Menu
            className="bg-menu-m box-bg-img-cover"
            right
            pageWrapId={"page-wrap"}
            uterContainerId={"outer-container"}
            isOpen={menuOpen}
            onStateChange={(state) => handleMenuChange(state)}
          >
            <div
              onClick={() => toggleMenu()}
              className="py-3 text-end px-3 font-20"
            >
              <img src={close} className="" style={{ width: "15px" }} alt="" />
            </div>

            <div className="text-end">
              <div className="container-fluid font-14 px-4">
                <a
                  href="https://www.facebook.com/seameoseps"
                  className="d-inline-block p-0"
                  target="_blank"
                >
                  <img src={fb} alt="" style={{ height: "30px" }} />
                </a>
                <a
                  href="https://www.youtube.com/@seameoseps9118"
                  className="d-inline-block px-2"
                  target="_blank"
                >
                  <img src={yt} alt="" style={{ height: "30px" }} />
                </a>
                <a
                  href="https://www.instagram.com/seameoseps"
                  className="d-inline-block p-0"
                  target="_blank"
                >
                  <img src={ig} alt="" style={{ height: "30px" }} />
                </a>
                <div className="overflow-hidden">
                  <img
                    src={linetop}
                    alt=""
                    className=""
                    style={{ height: "2px" }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 px-3 ps-4">
              <div className="d-inline-block">
                <form className="d-flex w-100 position-relative mb-3">
                  <input
                    className="form-control mr-sm-2 text-end pe-5"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button
                    type="submit"
                    className="btn btn-outline position-absolute"
                    style={{ right: "0" }}
                  >
                    <img
                      src={search}
                      alt=""
                      className=""
                      style={{ width: "20px" }}
                    />
                  </button>
                </form>
              </div>
            </div>
            <ul className="menu-m">

              {menuData.filter(a => a.status === true).map((val, index) => (

                val.submenu.length ?

                  <li
                    className={
                      menusubOpen.id === val.id
                        ? menusubOpen.view
                          ? "nav-item has-submenu menu-open"
                          : "nav-item has-submenu"
                        : "nav-item has-submenu"
                    }
                    key={index}
                  >
                    <a
                      className="nav-link"
                      href="#"
                      onClick={(state) => handleMenusubChange(state, val.id)}
                    >
                      {val.name}
                      <img
                        src={sorn}
                        className="arrow90 ms-2"
                        style={{ width: "6px" }}
                        alt=""
                      />
                    </a>
                    <ul className="submenu collapse">
                      {val.submenu.filter(a => a.status === true).map((vall, indexx) => (
                        vall.supersubmenu.length ?
                          <li
                            className={
                              menusubOpenB.id === vall.id
                                ? menusubOpenB.view
                                  ? "nav-item has-submenuB menu-openB"
                                  : "nav-item has-submenuB"
                                : "nav-item has-submenuB"
                            }
                            key={indexx}
                          >
                            <a
                              className="nav-link"
                              href="#"
                              onClick={(state) => handleMenusubChangeB(state, vall.id)}
                              role=""
                            >
                              {vall.name}
                              <img
                                src={sorn}
                                className="arrow90 ms-2"
                                style={{ width: "6px" }}
                                alt=""
                              />
                            </a>
                            <ul className="submenuB collapse">
                              {vall.supersubmenu.filter(a => a.status === true).map((valll, indexxx) => (
                                <li key={indexxx}>
                                  <a
                                    href={valll.link}
                                    className="btn-link-default"
                                    target={valll.target}
                                  >
                                    {valll.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                          :
                          <li key={indexx}>
                            <a
                              href={vall.link}
                              className="btn-link-default"
                              target={vall.target}
                            >
                              {vall.name}
                            </a>
                          </li>
                      ))}
                    </ul>
                  </li>
                  :
                  <li key={index}>
                    <a
                      href={val.link}
                      className={
                        data.page === val.active
                          ? "btn-link-default un active"
                          : "btn-link-default un"
                      }
                      target={val.target}
                    >
                      {val.name}
                    </a>
                  </li>
              ))}

            </ul>
          </Menu>
        </div>
      </div>

      <header className="position-sticky top-0 d-none d-lg-block bg-white bg-menu-top">
        <div>
          <img src={linetop} alt="" className="w-100 align-text-top" />
        </div>
        <div className="line-gra position-absolute"></div>
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "-18px" }}>
            <div className="col-12 col-md-12 col-lg-12 position-relative">
              <nav className="navbar navbar-light bg-white py-3">
                <div className="container-fluid align-items-end">
                  <div className="logo-home text-start pe-3">
                    <a href="/" className="btn-link-default border-0 px-0 ">
                      <img src={logo} alt="" className="" />
                    </a>
                  </div>
                  <div className="navbar-nav navbar-right align-items-end">
                    <div id="menubar">
                      <ul>
                        {menuData.filter(a => a.status === true).map((val, index) => (

                          val.submenu.length ?

                            <li
                              className={
                                data.page === val.active
                                  ? "true-drop active"
                                  : "true-drop"
                              }
                              key={index}
                            >
                              {val.name}
                              <ul>
                                <div className="box-sub">
                                  {val.submenu.filter(a => a.status === true).map((vall, indexx) => (
                                    <a
                                      href={vall.link}
                                      className={
                                        data.sub === vall.active
                                          ? "active a-box-sub"
                                          : "a-box-sub"
                                      }
                                      target={vall.target}
                                      key={indexx}
                                    >
                                      {vall.supersubmenu.length ?
                                        <li className="sssub">
                                          <span className="position-relative px-2 b-text-menu">
                                            <span className="" style={{ opacity: "0", border: "2px solid red" }}>
                                              {vall.name}
                                            </span>
                                            <span className="absolute-center w-100">
                                              {vall.name}
                                            </span>
                                            <span className="menu-box"></span>
                                          </span>
                                          <ul className="super-sub">
                                            <div className="box-super-sub">
                                              {vall.supersubmenu.filter(a => a.status === true).map((valll, indexxx) => (
                                                <a href={valll.link} key={indexxx} target={valll.target}>
                                                  <li>{valll.name}</li>
                                                </a>
                                              ))}
                                            </div>
                                          </ul>
                                        </li>
                                        :
                                        <li>
                                          <span className="position-relative px-2 b-text-menu">
                                            <span className="" style={{ opacity: "0", border: "2px solid red" }}>
                                              {vall.name}
                                            </span>
                                            <span className="absolute-center w-100">
                                              {vall.name}
                                            </span>
                                            <span className="menu-box"></span>
                                          </span>
                                        </li>
                                      }
                                    </a>
                                  ))}
                                </div>
                              </ul>
                            </li>
                            :
                            <a href={val.link} key={index} className={
                              data.page === val.active
                                ? "active"
                                : ""
                            } target={val.target}>
                              <li>{val.name}</li>
                            </a>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* {modalLogin.view ?
                <ModalLogin show={modalLogin.view} onHide={() => setModalLogin({ view: false })} handleModalhide={(e) => handleModalhide()} />
                : null} */}
      <div className="position-fixed text-end res-fii" style={{ top: '120px', right: '15px', zIndex: '9' }}>
        <div className={`box-eye ${menuMore && 'active'}`}>
          <span className="align-middle me-3 font-14 weight-700 text-plugin-eye">Click to Enhance <br /> Readability</span>
          <img src={eye} className="cursor-pointer d-inline-block" alt="" style={{ width: '55px' }} onClick={(e) => callback()} />
        </div>

        {menuMore &&
          <div className="position-absolute text-start me-2 box-acc overflow-hidden">
            <div className="text-white weight-700 py-2 px-3 font-18 box-add-header" style={{ background: '#E51A3D' }}>
              <img src={eyeB} className="pe-2" alt="" style={{ width: '35px' }} />
              <span className="align-middle">Accessibility</span>
              <img src={close} className="pe-2 float-end mt-2 cursor-pointer" alt="" style={{ width: '25px', filter: 'brightness(0) invert(1)' }} onClick={(e) => callback()} />
            </div>
            <div className="p-3 d-block w-100 ">
              <div className="row align-items-center">
                <div className="col-6 weight-700">
                  <div className="">Font size</div>
                </div>
                <div className="col-6 font-14">
                  <div className="d-block w-100">
                    <ul className="f-s">
                      <li className={bodyColor.font === 'normal' ? "font-14 active" : "font-14"} onClick={e => handlemenuFont('normal')}>A</li>
                      <li className={bodyColor.font === 'big' ? "font-16 active" : "font-16"} onClick={e => handlemenuFont('big')}>A</li>
                      <li className={bodyColor.font === 'superbig' ? "font-18 active" : "font-18"} onClick={e => handlemenuFont('superbig')}>A</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6 weight-700">
                  <div className="pt-2">Color contrast</div>
                </div>
                <div className="col-6 font-14">
                  <div className="d-block w-100">
                    <ul className="acc">
                      <li className={bodyColor.body === 'normal' && "active"} onClick={() => handlemenuColor('normal')}>
                        <div className="d-inline-block align-middle" style={{ background: '#F5B320', width: '10px', height: '20px' }}></div>
                        <div className="d-inline-block align-middle" style={{ background: '#E51A3D', width: '10px', height: '20px' }}></div>
                        <div className="d-inline-block align-middle ms-2 ps-1">Normal</div>
                      </li>
                      <li className={bodyColor.body === 'w-b' && "active"} onClick={() => handlemenuColor('w-b')}>
                        <div className="d-inline-block align-middle" style={{ background: '#fff', width: '10px', height: '20px' }}></div>
                        <div className="d-inline-block align-middle" style={{ background: '#000', width: '10px', height: '20px' }}></div>
                        <div className="d-inline-block align-middle ms-2 ps-1">White-Black</div></li>
                      <li className={bodyColor.body === 'y-b' && "active"} onClick={() => handlemenuColor('y-b')}>
                        <div className="d-inline-block align-middle" style={{ background: '#FFCC00', width: '10px', height: '20px' }}></div>
                        <div className="d-inline-block align-middle" style={{ background: '#000', width: '10px', height: '20px' }}></div>
                        <div className="d-inline-block align-middle ms-2 ps-1">Yellow-Black</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Header;
