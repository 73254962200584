import React, { useState, useEffect, useRef, useCallback, useId } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

import TagView from "../../components/tagview";
import bgImg from "../../assets/img/bg-plications.png";
import boxbanner from "../../assets/img/box-banner-publications.png";
import binIcon from "../../assets/img/icon/Bin.svg";
import searchIcon from "../../assets/img/icon/Search.svg";
import closeIcon from "../../assets/img/icon/CloseB.svg";

import Loading from "../../components/loading";
import ModalViewPDF from "../../components/modalViewPdf";

import { getData } from "../../services/getService";

const Main = () => {
  const pr = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [mainData, setMainData] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [inputData, setInputData] = useState({ tag: [], year: "", key: "" });
  const [modalViewPdf, setModalViewPdf] = useState({ view: false });

  const called = useRef(true);
  const myFormRef = useRef(true);
  const tagRef = useRef(null);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchData()]).then((res) => {
        setIsLoading(false);
      });
    }
    return () => {
      called.current = false;
    };
  }, []);

  const fetchData = async () => {
    getData("publications")
      .then((response) => {
        if (response.status === "success") {
          setMainData(response.data);
          setFilteredList(response.data);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const dataForm = new FormData(event.target);

    // const name = get

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (tagRef.current.value) {
        setInputData((prev) => ({
          ...prev,
          tag: [...inputData.tag, tagRef.current.value],
        }));
        // tagRef.current.value == '';
        setInputData((prev) => ({ ...prev, key: "" }));
      }

      console.log(inputData.tag);
    }
  };

  const cancelCourse = (ev) => {
    ev.preventDefault();
    myFormRef.current.reset();
    setInputData({ tag: [], year: "", key: "" });
    setMainData(filteredList);
  };

  const TagSearch = (myArray) => {
    if (myArray.length) {
      const vview =
        myArray.length &&
        myArray.map((val, index) => (
          <span
            key={index}
            className="view-tag pe-4 position-relative"
            style={{ paddginRight: "26px" }}
          >
            {val}
            <img
              src={closeIcon}
              onClick={(e) => removeSecond(index)}
              className="position-absolute absolute-center-right cursor-pointer"
              alt=""
              style={{ width: "13px", right: "4px" }}
            />
          </span>
        ));
      return vview;
    }
  };

  const setTag = (tag) => {
    // console.log(tag)
    // alert(tag)
    setInputData({ tag: [tag], year: "", key: "" });
  }

  const removeSecond = (eid) => {
    const newItems =
      inputData.tag && inputData.tag.filter((element, i) => i !== eid);
    setInputData((prev) => ({ ...prev, tag: newItems }));
  };

  const handleYear = (val) => {
    // console.log(val)
    setInputData((prev) => ({ ...prev, year: val }));
    if (val) {
      setMainData(filteredList.filter((element, i) => element.year === val));
    } else {
      setMainData(filteredList);
    }
  };

  // const addTag = useCallback(
  //     (tag) => () => {
  //         if (!tags.includes(tag)) {
  //             return setTags((prevTags) => [...prevTags, tag]);
  //         }
  //     },
  //     [tags]
  // );

  // const deleteTag = useCallback(
  //     (tagId) => () => {
  //         const tagsFiltered = tags.filter((tag) => {
  //             return tag !== tagId;
  //         });
  //         setTags(tagsFiltered);
  //     },
  //     [tags]
  // );

  const matchTags = (current, target) => {
    // console.log(current, target)
    return target.every((tag) => current.includes(tag));
  };

  // console.log(inputData)
  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="knowledge" sub="publications" />
        <div className="wrapper bg-white box-index yellow-bg-white">
          {isLoading ? <Loading /> : null}
          <section className="bg-top">
            <div className="container-fluid">
              <div
                className="row justify-content-center text-start bg-top-contain"
                style={{
                  backgroundImage: `url(${bgImg})`,
                  backgroundSize: "100%",
                }}
              >
                <div className="col-12 col-md-12 col-lg-10 top-about-line">
                  {/* {mainData ? */}

                  <div className="px-3 px-md-5">
                    <div className="font-60 weight-700 yellow-black">Publications</div>
                    <div className="mt-3 mb-5">
                      <Form noValidate onSubmit={handleSubmit} ref={myFormRef}>
                        <div className="row text-start align-items-center">
                          <div className="col-5 col-md-3 col-lg-2 pe-0">
                            <div className="mb-3">
                              <select
                                className="form-select py-2"
                                onChange={(e) => handleYear(e.target.value)}
                                style={{ backgroundColor: "unset" }}
                              >
                                <option value="">Publication Date</option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-7 col-md-6 col-lg-4">
                            <div className="position-relative mb-3">
                              <input
                                ref={tagRef}
                                type="text"
                                className="form-control py-2"
                                name="search"
                                placeholder="Search Tag"
                                style={{ backgroundColor: "unset" }}
                                onChange={(e) =>
                                  setInputData((prev) => ({
                                    ...prev,
                                    key: e.target.value,
                                  }))
                                }
                                value={inputData.key}
                              />
                              <div className="position-absolute">
                                {TagSearch(inputData.tag)}
                              </div>
                              <button className="btn btn-stan position-absolute absolute-center-right">
                                <img
                                  src={searchIcon}
                                  className=""
                                  alt=""
                                  style={{ width: "20px" }}
                                />
                              </button>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-lg-2">
                            <div className="mb-3 text-end text-md-start">
                              <span
                                className="font-14 ms-auto cursor-pointer yellow-black"
                                onClick={cancelCourse}
                              >
                                {" "}
                                <img
                                  src={binIcon}
                                  className="me-2"
                                  alt=""
                                  style={{ width: "15px" }}
                                />{" "}
                                Clear
                              </span>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-10">
                  <div className="py-5 px-3 px-md-4">
                    <div className="row">
                      {mainData.length ? (
                        mainData
                          .filter((proj) => matchTags(proj.tags, inputData.tag)).sort((a, b) => new Date(b.year) - new Date(a.year))
                          .map((val, index) => (
                            <div
                              className="col-12 col-md-12 col-lg-6"
                              key={index}
                            >
                              <div className="mb-5 pb-5">
                                <div className="row align-items-end">
                                  <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0">
                                    <div className="">
                                      <div>
                                        <div className="px-5">
                                          <img
                                            src={
                                              process.env.REACT_APP_IMG_LINK +
                                              "/uploads/publications/" +
                                              val.id +
                                              "/" +
                                              val.img
                                            }
                                            className="w-100 px-4"
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <img
                                            src={boxbanner}
                                            className="w-100"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="pb-5">
                                      <div className="mb-3">
                                        <span
                                          className="px-2 yellow-year"
                                          style={{ background: "#F5B320" }}
                                        >
                                          {val.year}
                                        </span>
                                      </div>
                                      <div className="news-topic yellow-black">
                                        {val.topic}
                                      </div>
                                      <div className="mt-1">
                                        <TagView myString={val.tag} ffunction={(e) => setTag(e)}/>
                                      </div>
                                      <div className="mt-5 w-100">
                                        {val.file &&
                                          <a
                                            href={
                                              process.env.REACT_APP_IMG_LINK +
                                              "/uploads/publications/" +
                                              val.id +
                                              "/" +
                                              val.file
                                            }
                                            target="_blank"
                                            className="btn btn-see-lang py-1 px-3 me-2 yellow-black"
                                          // onClick={() => setModalViewPdf({ view: true,dataUrl: process.env.REACT_APP_IMG_LINK + "/uploads/publications/" + val.id + '/' + val.file})}
                                          >
                                            EN
                                          </a>
                                        }

                                        {val.fileTh &&
                                          <a
                                            href={
                                              process.env.REACT_APP_IMG_LINK +
                                              "/uploads/publications/" +
                                              val.id +
                                              "/" +
                                              val.fileTh
                                            }
                                            target="_blank"
                                            className="btn btn-see-lang py-1 px-3 me-2 yellow-black"
                                          // onClick={() => setModalViewPdf({ view: true,dataUrl: process.env.REACT_APP_IMG_LINK + "/uploads/publications/" + val.id + '/' + val.file})}
                                          >
                                            TH
                                          </a>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div>-- No data -- </div>
                      )}
                    </div>
                  </div>
                  {/* : null} */}
                </div>
              </div>
            </div>
          </section>

          <Footer page="knowledge" sub="publications"/>
        </div>
      </div>

      {modalViewPdf.view ? (
        <ModalViewPDF
          show={modalViewPdf.view}
          onHide={() => setModalViewPdf({ view: false })}
          dataUrl={modalViewPdf.dataUrl}
        />
      ) : null}
    </>
  );
};

export default Main;
