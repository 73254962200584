import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form } from "react-bootstrap";
import moment from 'moment';

// import { getDataPagination } from '../services/getService';

import Header from '../layout/header';
import Footer from '../layout/footer';
import Loading from "../components/loading";

import bgImg from '../assets/img/bg-contact.jpg';
// import searchIcon from '../../assets/img/icon/Search.svg';
// import closeIcon from '../../assets/img/icon/Close.svg';

const Home = () => {

    // const handleDragStart = (e) => e.preventDefault();

    // const responsive = {
    //     0: { items: 1.2 },
    //     568: { items: 2 },
    //     1024: { items: 5 },
    // };

    const [isLoading, setIsLoading] = useState(false)
    // const [partnersData, setPartnersData] = useState([]);

    const [keySearch, setKeySearch] = useState('')
    const [toggle, setToggle] = useState(false)


    const called = useRef(true)

    // useEffect(() => {
    //     if (called.current) {
    //         Promise.all([fetchDataNews(1, '')]).then(res => {
    //             setIsLoading(false)
    //         });
    //     }
    //     return () => {
    //         called.current = false
    //     }
    // }, [])

    // const fetchDataNews = async (page, keySearch) => {

    //     getDataPagination(page, keySearch, 'newsPagination').then(
    //         response => {
    //             if (response.status === 'success') {
    //                 setNewsData(response.data.dataNews)
    //                 setFilteredList(response.data.dataNews)
    //                 setPagination({ 'pager': response.data.pager, 'link': response.data.links })
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //         })
    // }

    // const handlePagination = (page) => {
    //     fetchDataNews(page, keySearch)
    // }

    // const filterBySearch = (event) => {
    //     const query = event.target.value;
    //     setKeySearch(query)
    // }


    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            // $('html, body').animate({
            //     scrollTop: $(errorElements[0]).offset().top
            //   }, 2000);

        } else {
            // alert()
            // fetchDataNews(1, keySearch)
        }
    };


    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="contact" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}

                    <section className="mb-5" id="page-wrap">
                        <div className="container-fluid">
                            <div className="row text-start justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-12 col-lg-12 position-relative d-none d-md-block">
                                            <img src={bgImg} className="w-100" alt="" />
                                            <div className='position-absolute absolute-center-right w-100'>
                                                <div className="container">
                                                    <div className="row justify-content-end">
                                                        <div className="col-12 col-md-6 col-lg-6 position-relative">
                                                            <div className='px-5 ms-md-5'>
                                                                <div className='sub-topic mb-3 yellow-black'>
                                                                    ADDRESS
                                                                </div>
                                                                <div className='font-18 yellow-black'>
                                                                    11th Floor, Nature and Environment Building
                                                                    <br/>928, Sukhumvit Road, Phra Khanong, Khlong Toei, Bangkok, 10110 Thailand

                                                                    <div className='d-block mt-4 yellow-black'>E-mail : <a href="mailto:secretariat@seameoseps.org" className='a-null text-reset text-decoration-underline'>secretariat@seameoseps.org</a></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 position-relative d-block d-md-none bg-top-rigth" style={{ backgroundImage: `url(${bgImg})` }}>
                                            <div className='px-5 ms-md-5 py-5'>
                                                <div className='sub-topic mb-3 yellow-black'>
                                                    ADDRESS
                                                </div>
                                                <div className='font-18 yellow-black'>
                                                    11th Floor, Nature and Environment Building
                                                    928, Sukhumvit Road, Phra Khanong, Khlong Toei, Bangkok, 10110 Thailand

                                                    <div className='d-block mt-4 yellow-black'>E-mail : <a href="mailto:secretariat@seameoseps.org" className='a-null text-reset text-decoration-underline'>secretariat@seameoseps.org</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 col-md-6 col-lg-6">
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <div className='px-3 px-md-0 py-5 py-md-0'>
                                                        <div className='topic mb-3'>
                                                            Contact
                                                        </div>
                                                        <div className='position-relative'>
                                                            <div className=''>
                                                                <Form noValidate onSubmit={handleSubmit} >
                                                                    <div className="row text-start">
                                                                        <div className="mb-3 col-12">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="name"
                                                                                placeholder="Name"
                                                                            />
                                                                        </div>
                                                                        <div className="mb-3 col-12">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="email"
                                                                                placeholder="Email"
                                                                            />
                                                                        </div>
                                                                        <div className="mb-3 col-12">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="subject"
                                                                                placeholder="Subject"
                                                                            />
                                                                        </div>
                                                                        <div className="mb-5 col-12">
                                                                            <textarea className="form-control" name="message" rows="3" placeholder="Message"></textarea>
                                                                        </div>
                                                                        <div className='col-12 text-end'>
                                                                            <button className="btn btn-about font-size-20 font-weight-500">
                                                                                SEND
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d968.9892871589764!2d100.5818059!3d13.7210442!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f79e88743bf%3A0x86d6ad3a0c6befb7!2sSEAMEO%20SEPS!5e0!3m2!1sth!2sth!4v1699242344786!5m2!1sth!2sth"
                                                width="100%" height="300" style={{ border: 0 }}
                                                allowFullScreen="" loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade">
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer page="contact"/>
                </div>
            </div>
        </>
    );
};

export default Home;