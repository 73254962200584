import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { Animated } from "react-animated-css";

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

import banner from "../../../assets/img/banner-partner.png";

import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataOne, setDataOne] = useState({});
  const [dataTwo, setDataTwo] = useState({});
  const [dataH, setDataH] = useState({});
  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchDataOne(), fetchDataTwo()]).then(
        (res) => {
          setIsLoading(false);
        }
      );
    }
    return () => {
      called.current = false;
    };
  }, []);

  const fetchDataOne = async () => {
    getData("pageawards", "waste")
      .then((response) => {
        if (response.status === "success") {
          setDataOne(response.data)
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const fetchDataTwo = async (id) => {
    getData("partners")
      .then((response) => {
        if (response.status === "success") {
          setDataTwo(response.data);
          setDataH(response.data);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  // console.log(dataH.length / 2)

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="activities" sub="partnership" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="pb-md-5 bg-top text-start">

            <div className="w-100 position-relative d-none d-md-block">
              <Animated
                animationIn="fadeInDown"
                animationOut="fadeOut"
                animationInDelay={700}
                animationInDuration={2000}
                isVisible={true}
              >
               
              <div className="text-start w-100 bg-top" style={{ backgroundImage: `url(${banner})` }}>
                <div className="container">
                  <div className="row justify-content-center pb-md-5 align-items-end">
                    <div className="col-12 col-md-11 col-lg-10">
                      <div className="font-60 weight-700 yellow-black mt-5 pt-5">
                        Our Partners
                      </div>
                      <div className="mt-5">
                        <ul className="partner-logo">
                          {dataTwo.length && dataTwo.map((val, index) => (
                            <li key={index}><img src={process.env.REACT_APP_IMG_LINK + "/uploads/partners/" + val.id + '/' + val.img} alt="" /></li>
                          ))}

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Animated>
            </div>

            <div className="w-100 position-relative d-block d-md-none">
              {/* <Animated
                animationIn="fadeInDown"
                animationOut="fadeOut"
                animationInDelay={700}
                animationInDuration={2000}
                isVisible={true}
              >
                <img src={banner} className="w-100" />
              </Animated> */}
              <div className="text-start w-100 bg-top" style={{ backgroundImage: `url(${banner})` }}>
                <div className="container">
                  <div className="row justify-content-center pb-md-5 align-items-end">
                    <div className="col-12 col-md-11 col-lg-10">
                      <div className="topic mt-5 yellow-black">
                        Our Partners
                      </div>
                      <div className="mt-3">
                        <ul className="partner-logo">
                          {dataTwo.length && dataTwo.map((val, index) => (
                            <li key={index}><img src={process.env.REACT_APP_IMG_LINK + "/uploads/partners/" + val.id + '/' + val.img} alt="" /></li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* <div className="container">
              <div className="row justify-content-center pb-md-5 align-items-end">
                <div className="col-12 col-md-11 col-lg-10">
                  <Animated
                    animationIn="fadeInDown"
                    animationOut="fadeOut"
                    animationInDelay={700}
                    animationInDuration={2000}
                    isVisible={true}
                  >
                    <div className="row justify-content-center pb-md-5 align-items-end mt-5">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="topic weight-500">Join us as</div>
                        <div className="topic">SEPS FOLLOWERS</div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="mb-3 mt-3 text-start">
                          <a href="#" className="btn btn-register">
                            Join us
                          </a>
                        </div>
                      </div>
                    </div>
                  </Animated>
                </div>
              </div>
            </div> */}
          </section>

          <Footer page="activities" sub="partnership" />
        </div>
      </div>
    </>
  );
};

export default Main;
