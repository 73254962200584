import React, { useState, useEffect, useRef } from "react";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

// import bg1 from "../../assets/img/about-bg.png";
import logoSeps1 from "../../assets/img/LOGO-SEPS1.svg";
import footer from "../../assets/img/about-footer.png";
import bgfoot from "../../assets/img/about-bg-foot.png";
import bgBottom from "../../assets/img/about-bg-bottom.jpg";

import m1 from "../../assets/img/icon/Mission-01.svg";
import m2 from "../../assets/img/icon/Mission-02.svg";
import m3 from "../../assets/img/icon/Mission-03.svg";
import m4 from "../../assets/img/icon/Mission-04.svg";
import m5 from "../../assets/img/icon/Mission-05.svg";

import { getData } from "../../services/getService";
import Loading from "../../components/loading";

const CentresOverview = () => {

    const ref01 = useRef(null);
    const ref11 = useRef(null);
    const ref02 = useRef(null);
    const ref22 = useRef(null);
    const ref03 = useRef(null);
    const ref04 = useRef(null);
    const ref44 = useRef(null);
    const ref05 = useRef(null);
    // const handleDragStart = (e) => e.preventDefault();

    // const responsive = {
    //     0: { items: 1.2 },
    //     568: { items: 2 },
    //     1024: { items: 5 },
    // };
    const called = useRef(true);

    const [isLoading, setIsLoading] = useState(true);
    const [dataWhoWeAre, setDataWhoWeAre] = useState({});
    const [dataVision, setDataVision] = useState({});
    const [dataLogo, setDataLogo] = useState({});

    const [isLoadingB, setIsLoadingB] = useState(false);

    useEffect(() => {


        if (called.current) {
            Promise.all([fetchDataWhoWeAre(), fetchDataVision(), fetchDataLogo()]).then(
                (res) => {
                    setIsLoading(false);

                    window.addEventListener('scroll', handleScroll, {
                        passive: true
                    });

                    return () => {
                        window.removeEventListener('scroll', handleScroll);
                    };
                }
            );
        }
        return () => {
            called.current = false;
        };


    }, []);

    const handleScroll = () => {

        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom) {
            console.log('at the bottom');
            setIsLoadingB(true)
        } else {
            setIsLoadingB(false)
        }
    };

    const fetchDataWhoWeAre = async () => {
        getData("pageawards", "weare")
            .then((response) => {
                if (response.status === "success") {
                    setDataWhoWeAre(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    const fetchDataVision = async () => {
        getData("pageawards", "vision")
            .then((response) => {
                if (response.status === "success") {
                    setDataVision(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };
    const fetchDataLogo = async () => {
        getData("pageawards", "logo")
            .then((response) => {
                if (response.status === "success") {
                    setDataLogo(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };



    const handleClick = (id) => {

        id === 1 ?
            ref01.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
            : id === 2 ?
                ref02.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
                : id === 3 ?
                    ref03.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
                    : id === 4 ?
                        ref04.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
                        : id === 5 ?
                            ref05.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
                            : id === 11 ?
                                ref11.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
                                : id === 22 ?
                                    ref22.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
                                    :
                                    ref44.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="about" sub="whoweare" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <div className={isLoadingB ? "menu-about-left text-start block-effect" : "menu-about-left text-start"}>
                        <ul className="align-items-center">
                            <li className="d-none d-md-block" onClick={e => handleClick(1)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>01</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">Who We Are</div>
                            </li>
                            <li className="d-table-cell d-md-none" onClick={e => handleClick(1)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>01</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">Who We Are</div>
                            </li>
                            <li className="d-none d-md-block" onClick={e => handleClick(2)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>02</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">Our Vision</div>
                            </li>
                            <li className="d-table-cell d-md-none" onClick={e => handleClick(2)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>02</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">Our Vision</div>
                            </li>
                            <li onClick={e => handleClick(3)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>03</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">Our Mission</div>
                            </li>
                            <li className="d-none d-md-block" onClick={e => handleClick(4)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>04</div>
                                <div className="font-20 weight-300 pe-3 text-res-menu yellow-black">Our Goals</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">and Roles</div>
                            </li>
                            <li className="d-table-cell d-md-none" onClick={e => handleClick(44)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>04</div>
                                <div className="font-20 weight-300 pe-3 text-res-menu yellow-black">Our Goals</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">and Roles</div>
                            </li>
                            <li onClick={e => handleClick(5)}>
                                <div className="font-38 weight-600 topic-res-menu yellow-black" style={{ lineHeight: '1' }}>05</div>
                                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu yellow-black">Our Logo</div>
                            </li>
                        </ul>
                    </div>

                    {/* <div
                        className="position-relative pt-5 d-block d-md-none bg-top "
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_IMG_LINK +
                                "/uploads/awards/" +
                                dataWhoWeAre.id +
                                "/" +
                                dataWhoWeAre.imgM})`
                        }}
                    >

                        <div className="w-100 pb-5 box-res-white menuleft-margin">
                            <div className="container">
                                <div className="row text-start">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="font-60 weight-700 yellow-black" ref={ref11} style={{ scrollMarginTop: '50px' }}>
                                            {dataWhoWeAre.topic}
                                        </div>
                                        <div className="font-18 text-justify content-custom yellow-black">
                                            <div className="yellow-black"
                                                dangerouslySetInnerHTML={{
                                                    __html: dataWhoWeAre.detail,
                                                }}
                                            />
                                        </div>
                                        <div ref={ref22} style={{ scrollMarginTop: '70px' }}></div>
                                        <div className="sub-topic weight-700 mb-2 mt-5 yellow-black">
                                            {dataVision.topic}
                                        </div>
                                        <div className="text-justify content-custom yellow-black">
                                            <div className="yellow-black"
                                                dangerouslySetInnerHTML={{
                                                    __html: dataVision.detail,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="position-relative" ref={ref01}>
                        {/* <img src={footer} className="w-100" alt="" /> */}
                        {/* <img
                            src={
                                process.env.REACT_APP_IMG_LINK +
                                "/uploads/awards/" +
                                dataWhoWeAre.id +
                                "/" +
                                dataWhoWeAre.img
                            }
                            className="w-100 d-none d-md-block"
                            alt=""
                        />
                        <img
                            src={
                                process.env.REACT_APP_IMG_LINK +
                                "/uploads/awards/" +
                                dataWhoWeAre.id +
                                "/" +
                                dataWhoWeAre.imgM
                            }
                            className="w-100 d-block d-md-none"
                            alt=""
                        /> */}
                        <div className="w-100 top-about-line pt-5 pt-md-0 d-none d-md-block" style={{
                            backgroundImage: `url(${process.env.REACT_APP_IMG_LINK +
                                "/uploads/awards/" +
                                dataWhoWeAre.id +
                                "/" +
                                dataWhoWeAre.img})`, backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'bottom right'
                        }}>
                            <div className="container">
                                <div className="row text-start justify-content-center" >
                                    <div className="col-12 col-md-12 col-lg-8">
                                        <div className="font-60 weight-700 yellow-black">
                                            {dataWhoWeAre.topic}
                                        </div>
                                        <div className="text-justify content-custom">

                                            <div className="yellow-black"
                                                dangerouslySetInnerHTML={{
                                                    __html: dataWhoWeAre.detail,
                                                }}
                                            />
                                            <div ref={ref02} style={{ scrollMarginTop: '100px' }}></div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-2"></div>
                                </div>
                                <div className="pb-5">
                                    <div className="row text-start justify-content-center mt-3 pt-3 mb-5" >
                                        <div className="col-12 col-md-12 col-lg-7">
                                            <div className="sub-topic weight-700 yellow-black">
                                                {dataVision.topic}
                                            </div>
                                            <div className="text-justify content-custom">
                                                <div className="yellow-black"
                                                    dangerouslySetInnerHTML={{
                                                        __html: dataVision.detail,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-100 top-about-line pt-5 pt-md-0 d-block d-md-none" style={{
                            backgroundImage: `url(${process.env.REACT_APP_IMG_LINK +
                                "/uploads/awards/" +
                                dataWhoWeAre.id +
                                "/" +
                                dataWhoWeAre.imgM})`, backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'bottom right'
                        }}>
                            <div className="container pb-5">
                                <div className="row text-start justify-content-center pb-5" >
                                    <div className="col-12 col-md-12 col-lg-8">
                                        <div className="font-60 weight-700 yellow-black">
                                            {dataWhoWeAre.topic}
                                        </div>
                                        <div className="text-justify content-custom">

                                            <div className="yellow-black"
                                                dangerouslySetInnerHTML={{
                                                    __html: dataWhoWeAre.detail,
                                                }}
                                            />
                                            <div ref={ref02} style={{ scrollMarginTop: '100px' }}></div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-2"></div>
                                </div>
                                <div className="pb-5">
                                    <div className="row text-start justify-content-center mb-5" >
                                        <div className="col-12 col-md-12 col-lg-7">
                                            <div className="sub-topic weight-700 yellow-black">
                                                {dataVision.topic}
                                            </div>
                                            <div className="text-justify content-custom">
                                                <div className="yellow-black"
                                                    dangerouslySetInnerHTML={{
                                                        __html: dataVision.detail,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section ref={ref03}
                        className="py-md-4 bg-top"
                        style={{ backgroundImage: `url(${bgfoot})`, scrollMarginTop: '100px' }}
                    >

                        <div className="container-fluid pb-5">
                            <div className="row justify-content-center py-md-5">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="sub-topic weight-700 mb-2 mt-5 mt-md-0">
                                        Our Mission
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-11 px-5 px-md-0">
                                    <div className="row justify-content-center py-md-5 text-start">
                                        <div className="col-12 col-md-4 col-lg-2 mb-3 col-md-20">
                                            <div
                                                className="position-relative box-mission h-100"
                                                style={{}}
                                            >
                                                <div className="position-absolute icon-mission">
                                                    <img src={m1} className="w-100" alt="" />
                                                </div>
                                                <div className="py-5 px-4 mission-1" style={{}}>
                                                    <div className="font-80 weight-700 yellow-opacity" style={{ opacity: '0.1' }}>1</div>
                                                    <div className="weight-700 text-center pb-4">
                                                        Promote the application of SEP to achieve
                                                        sustainable development
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-2 mb-3 col-md-20">
                                            <div
                                                className="position-relative box-mission h-100"
                                                style={{}}
                                            >
                                                <div className="position-absolute icon-mission">
                                                    <img src={m2} className="w-100" alt="" />
                                                </div>
                                                <div className="py-5 px-4 mission-2" style={{}}>
                                                    <div className="font-80 weight-700 yellow-opacity" style={{ opacity: '0.1' }}>2</div>
                                                    <div className="weight-700 text-center pb-4">
                                                        Establish a learning and knowledge resource centre
                                                        on SEP
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-2 mb-3 col-md-20">
                                            <div
                                                className="position-relative box-mission h-100"
                                                style={{}}
                                            >
                                                <div className="position-absolute icon-mission">
                                                    <img src={m3} className="w-100" alt="" />
                                                </div>
                                                <div className="py-5 px-4 mission-3" style={{}}>
                                                    <div className="font-80 weight-700 yellow-opacity" style={{ opacity: '0.1' }}>3</div>
                                                    <div className="weight-700 text-center pb-4">
                                                        Consolidate knowledge and share expertise on SEP
                                                        through research and dialogue
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-2 mb-3 col-md-20">
                                            <div
                                                className="position-relative box-mission h-100"
                                                style={{}}
                                            >
                                                <div className="position-absolute icon-mission">
                                                    <img src={m4} className="w-100" alt="" />
                                                </div>
                                                <div className="py-5 px-4 mission-4" style={{}}>
                                                    <div className="font-80 weight-700 yellow-opacity" style={{ opacity: '0.1' }}>4</div>
                                                    <div className="weight-700 text-center pb-4">
                                                        Promote human resource development based on SEP
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-2 mb-3 col-md-20">
                                            <div
                                                className="position-relative box-mission h-100"
                                                style={{}}
                                            >
                                                <div className="position-absolute icon-mission">
                                                    <img src={m5} className="w-100" alt="" />
                                                </div>
                                                <div className="py-5 px-4 mission-5" style={{}}>
                                                    <div className="font-80 weight-700 yellow-opacity" style={{ opacity: '0.1' }}>5</div>
                                                    <div className="weight-700 text-center pb-4">
                                                        Develop and expand partnerships and linkages on SEP
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="position-relative d-none d-md-block" ref={ref04} style={{ scrollMarginTop: '100px' }}>
                        <img src={bgBottom} className="w-100" alt="" />
                        <div className="position-absolute absolute-center-center w-100">
                            <div className="container-fluid">
                                <div className="row text-center justify-content-center">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="sub-topic weight-700 mb-5 yellow-black">
                                            Our Goals and Roles
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-11">
                                        <div className="row text-center ">
                                            <div className="col-6 col-md-3 col-lg-3 border-right">
                                                <div className="mb-2">
                                                    <span className="box-cicle position-relative">
                                                        <span className="position-absolute absolute-center-center">
                                                            1
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="yellow-black">
                                                    Promote SEP’s principles of moderation, reasonableness and
                                                    prudence in support of building the 21st Century society
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3 border-right">
                                                <div className="mb-2">
                                                    <span className="box-cicle position-relative">
                                                        <span className="position-absolute absolute-center-center">
                                                            2
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="yellow-black">
                                                    Develop and promote the co-creation of knowledge and
                                                    skills consistent with a balanced development strategy
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3 border-right">
                                                <div className="mb-2">
                                                    <span className="box-cicle position-relative">
                                                        <span className="position-absolute absolute-center-center">
                                                            3
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="yellow-black">
                                                    Strengthen cooperation and collaboration among SEAMEO
                                                    Member Countries
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3 border-right">
                                                <div className="mb-2">
                                                    <span className="box-cicle position-relative">
                                                        <span className="position-absolute absolute-center-center">
                                                            4
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="yellow-black">
                                                    Strengthen networks and partnerships
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="position-relative d-block d-md-none"
                        style={{ backgroundImage: `url(${bgBottom})` }}
                    > <div ref={ref44} style={{ scrollMarginTop: '100px' }}></div>
                        <div className="w-100">
                            <div className="container" >

                                <div className="row text-center">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="new-topic weight-700 mt-5 mb-5">
                                            Our Goals and Roles
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-3 border-right mb-5">
                                        <div className="mb-2">
                                            <span className="box-cicle position-relative">
                                                <span className="position-absolute absolute-center-center">
                                                    1
                                                </span>
                                            </span>
                                        </div>
                                        <div className="font-20">
                                            Promote SEP’s principles of moderation, reasonableness and
                                            prudence in support of building the 21st Century society
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-3 border-right mb-5">
                                        <div className="mb-2">
                                            <span className="box-cicle position-relative">
                                                <span className="position-absolute absolute-center-center">
                                                    2
                                                </span>
                                            </span>
                                        </div>
                                        <div className="font-20">
                                            Develop and promote the co-creation of knowledge and
                                            skills consistent with a balanced development strategy
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-3 border-right mb-5">
                                        <div className="mb-2">
                                            <span className="box-cicle position-relative">
                                                <span className="position-absolute absolute-center-center">
                                                    3
                                                </span>
                                            </span>
                                        </div>
                                        <div className="font-20">
                                            Strengthen cooperation and collaboration among SEAMEO
                                            Member Countries
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-3 border-right mb-5" >
                                        <div className="mb-2">
                                            <span className="box-cicle position-relative">
                                                <span className="position-absolute absolute-center-center">
                                                    4
                                                </span>
                                            </span>
                                        </div>
                                        <div className="font-20">
                                            Strengthen networks and partnerships
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div ref={ref05} style={{ scrollMarginTop: '100px' }}></div>
                    <div className="position-relative" >
                        <div className="w-100"  >
                            <div className="container">
                                <div className="row text-start justify-content-center align-items-center">

                                    <div className="col-12 col-md-7 col-lg-6">
                                        <div className="sub-topic weight-700 mb-3 mt-5 mt-md-0">
                                            {dataLogo.topic}
                                        </div>
                                        <div className="text-justify content-custom">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: dataLogo.detail,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-4">
                                        <div className="p-5 text-center">
                                            <img
                                                src={
                                                    process.env.REACT_APP_IMG_LINK +
                                                    "/uploads/awards/" +
                                                    dataLogo.id +
                                                    "/" +
                                                    dataLogo.img
                                                }
                                                className="w-100  p-md-3"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer page="about" sub="whoweare" />
                </div>
            </div>
        </>
    );
};

export default CentresOverview;
