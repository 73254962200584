import React, { useState, useEffect, useRef } from "react";
// import { useParams, Link } from "react-router-dom";
// import { Accordion } from 'react-bootstrap';

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// import banner from "../../../assets/img/banner-wasteHero.png";
import img001 from "../../../assets/img/Main Web Banner_2024 Waste Hero School Competition1.jpg";
// import img002 from "../../../assets/img/hero_02.jpg";
// import img01 from "../../../assets/img/hero_1.jpg";
// import img02 from "../../../assets/img/hero_2.jpg";
// import img03 from "../../../assets/img/hero_3.jpg";
// import vector1 from "../../../assets/img/icon/vector_1.svg";
// import vector2 from "../../../assets/img/icon/vector_2.svg";
// import vector3 from "../../../assets/img/icon/vector_3.svg";
// import vector4 from "../../../assets/img/icon/vector_4.svg";
// import vector5 from "../../../assets/img/icon/vector_5.svg";
// import vector6 from "../../../assets/img/icon/Malaysia.svg";

import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "waste")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="partnership" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="">
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                                    <div className="font-50 weight-700">{dataOne.topic}</div>
                                    <div className="mb-5 pt-3">
                                        <div className="font-18 text-justify content-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: dataOne.detail,
                                            }}
                                        />
                                    </div>
                                    
                                    <div className="topic weight-700 mb-3 mt-5 pt-5">Programme of Activities</div>
                                    <div className="mt-4">
                                        <div className="box-archives mb-4">
                                            <div className="row text-start justify-content-center">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="p-4"><img src={img001} className="w-100" alt="" style={{ borderRadius: '15px' }} /></div>
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-7">
                                                    <div className="position-relative pb-3 px-3 p-md-0 h-100">
                                                        <div className="font-28 weight-700 pt-md-4 pe-md-5">2024 Waste Hero School Competition </div>
                                                        <div>May – July 2024</div>
                                                        <div className="mt-5 mb-4" >
                                                            <a href="/awardsGrants/seaWasteHeroAwards2024" rel="noreferrer" className="btn btn-watch btn-res-50 me-2 my-2 my-md-0">See Details</a>
                                                            <a href="https://seameoseps.org/newsandgallery/news/52" rel="noreferrer" className="btn btn-news btn-res-50">News</a>
                                                        </div>
                                                        {/* <div className='acc-custom pb-5 mt-3'>
                                                            <Accordion defaultActiveKey="">
                                                                <Accordion.Item eventKey={4}>
                                                                    <Accordion.Header>
                                                                        <div className="ont-14 weight-700">Webinars</div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body className='color-gold'>
                                                                        <div><a href="https://www.youtube.com/watch?v=aAwrV83Oa4s" className="a-underline" target={'_blank'}>Kindergarten and Primary Level</a></div>
                                                                        <div><a href="https://www.youtube.com/watch?v=2o_PPpCdX8M" className="a-underline" target={'_blank'}>Secondary Level</a></div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="box-archives mb-4">
                                            <div className="row text-start justify-content-center">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="p-4"><img src={img002} className="w-100" alt="" style={{ borderRadius: '15px' }} /></div>
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-7">
                                                    <div className="position-relative pb-3 px-3 p-md-0 h-100">
                                                        <div className="font-28 weight-700 pt-md-4 pe-md-5">Southeast Asian Waste Hero Virtual Award Ceremony</div>
                                                        <div>19 January 2024</div>
                                                        <div className="mt-5" >
                                                            <a href="https://drive.google.com/file/d/1JWA6TzSjW6suTxtYnLcWIlaEebtonDDD/view?usp=sharing" rel="noreferrer" target={'_blank'} className="btn btn-watch btn-res-50 me-2 my-2 my-md-0">See Details</a>
                                                            <a href="https://seameoseps.org/newsandgallery/news/43" className="btn btn-news btn-res-50">News</a>
                                                        </div>
                                                        <div className='acc-custom pb-5 mt-3'>
                                                            <a href="https://youtu.be/5PKTh1N7T9c?si=uKkpg888Dc0iBM0K" rel="noreferrer" target={'_blank'} className="btn weight-700">Award Ceremony</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-archives mb-4">
                                            <div className="row text-start justify-content-center">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="p-4"><img src={img02} className="w-100" alt="" style={{ borderRadius: '15px' }} /></div>
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-7">
                                                    <div className="position-relative pb-3 px-3 p-md-0 h-100">
                                                        <div className="font-28 weight-700 pt-md-4 pe-md-5">Southeast Asian Waste Hero Awards</div>
                                                        <div>Aug – Nov 2023</div>
                                                        <div className="position-md-absolute" style={{ bottom: '25px' }}>
                                                            <a href={'https://seameoseps.org/awardsGrants/seaWasteHeroAwards'} target={'_blank'} className="btn btn-watch btn-res-50 me-2 my-2 my-md-0">See Details</a>
                                                            <a href={'https://seameoseps.org/newsandgallery/news/35'} target={'_blank'} className="btn btn-news btn-res-50">News</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-archives mb-4">
                                            <div className="row text-start justify-content-center">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="p-4"><img src={img03} className="w-100" alt="" style={{ borderRadius: '15px' }} /></div>
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-7">
                                                    <div className="position-relative pb-3 px-3 p-md-0 h-100">
                                                        <div className="font-28 weight-700 pt-md-4 pe-md-5">Waste Hero: Reduce to Zero Master Teacher Training for Southeast Asian Countries</div>


                                                        <div className='acc-custom pb-5'>
                                                            <Accordion defaultActiveKey="">
                                                                <Accordion.Item eventKey={4}>
                                                                    <Accordion.Header>
                                                                        <div className="ont-14">24 October 2023 <img src={vector5} className="" alt="" /></div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body className='color-gold'>
                                                                        <div><a href="https://www.youtube.com/live/7EidCcf5ooI?si=OJIF56yVfV-FdEI" className="a-underline" target={'_blank'}>Kindergarten & Primary Level</a></div>
                                                                        <div><a href="https://www.youtube.com/live/z5TsCy69yfw?si=5kikhzj5V60eZY7g" className="a-underline" target={'_blank'}>Secondary and TVET Level</a></div>
                                                                        <div><a href="https://seameoseps.org/newsandgallery/news/34" className="a-underline" target={'_blank'}>News</a></div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey={3}>
                                                                    <Accordion.Header>
                                                                        <div className="font-14">7 October 2023 <img src={vector4} className="" alt="" /><img src={vector6} className="ms-1" alt="" style={{ width: '20px' }} /></div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body className='color-gold'>
                                                                        <div><a href="https://www.youtube.com/live/_5BYBLisJho?si=fxbFPLnCWNB-wvZT" className="a-underline" target={'_blank'}>Kindergarten & Primary Level</a></div>
                                                                        <div><a href="https://www.youtube.com/live/CKuyVb3uJPQ?si=NZwBtEdJdxbDpdGn" className="a-underline" target={'_blank'}>Secondary and TVET Level</a></div>
                                                                        <div><a href="https://seameoseps.org/newsandgallery/news/27" className="a-underline" target={'_blank'}>News</a></div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey={2}>
                                                                    <Accordion.Header>
                                                                        <div className="font-14">6 October 2023 <img src={vector3} className="" alt="" /></div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body className='color-gold'>
                                                                        <div><a href="https://www.youtube.com/live/WloyZRXbP6o?si=FiluC68no4vmDgsP" className="a-underline" target={'_blank'}>Kindergarten & Primary Level</a></div>
                                                                        <div><a href="https://www.youtube.com/live/35Mj1urcs9o?si=DwFVsry43fVftu8A" className="a-underline" target={'_blank'}>Secondary and TVET Level</a></div>
                                                                        <div><a href="https://seameoseps.org/newsandgallery/news/27" className="a-underline" target={'_blank'}>Secondary and TVET</a></div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey={1}>
                                                                    <Accordion.Header>
                                                                        <div className="font-14">23 July 2023 <img src={vector2} className="" alt="" /></div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body className='color-gold'>
                                                                        <div><a href="https://www.youtube.com/live/35Mj1urcs9o?si=zGI1NeLU6fAS82qQ" className="a-underline" target={'_blank'}>Kindergarten & Primary Level</a></div>
                                                                        <div><a href="https://www.youtube.com/live/1lOteGrnJK0?si=TGmPu7907zh9IpZk" className="a-underline" target={'_blank'}>Secondary and TVET Level</a></div>
                                                                        <div><a href="https://seameoseps.org/newsandgallery/news/21" className="a-underline" target={'_blank'}>News</a></div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey={0}>
                                                                    <Accordion.Header>
                                                                        <div className="font-14">22 July 2023 <img src={vector1} className="" alt="" /></div>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body className='color-gold'>
                                                                        <div><a href="https://www.youtube.com/live/fcqA0wiyCDo?si=wygtanu7h-Tx7Lwa" className="a-underline" target={'_blank'}>Kindergarten & Primary Level</a></div>
                                                                        <div><a href="https://www.youtube.com/live/MnwDZRtwaXU?si=QpSPCyjadml0966_" className="a-underline" target={'_blank'}>Secondary and TVET Level</a></div>
                                                                        <div><a href="https://seameoseps.org/newsandgallery/news/21" className="a-underline" target={'_blank'}>News</a></div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>

                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-archives mb-4">
                                            <div className="row text-start justify-content-center">
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <div className="p-4"><img src={img01} className="w-100" alt="" style={{ borderRadius: '15px' }} /></div>
                                                </div>
                                                <div className="col-12 col-md-7 col-lg-7">
                                                    <div className="position-relative pb-3 px-3 p-md-0 h-100">
                                                        <div className="font-28 weight-700 pt-md-4 pe-md-5">SEAMEO SEPS - IVL Webinar on World Environment Day: Beating Plastic Pollution</div>
                                                        <div>5 June 2023</div>
                                                        <div className="position-md-absolute" style={{ bottom: '25px' }}>
                                                            <a href="https://www.youtube.com/live/PVX7QZB9oYE?si=3-lBVAKKh2rZIeRp&t=36" rel="noreferrer" target={'_blank'} className="btn btn-watch btn-res-50 me-2 my-2 my-md-0">Watch</a>
                                                            <a href="https://seameoseps.org/newsandgallery/news/13" target={'_blank'} className="btn btn-news btn-res-50">News</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="topic mt-5 mb-3">Previous Activities</div>
                                    <div>
                                        <a href="/newsandgallery/archivesWaste/3" className="btn btn-watch-passtel font-16 px-5 py-2">2023/2024</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="partnership" />
                </div>
            </div>
        </>
    );
};

export default Main;
