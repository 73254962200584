import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

import banner from "../../assets/img/banner-hero.jpg";
import download from "../../assets/img/icon/download.svg";

import Loading from "../../components/loading";
import { getData } from "../../services/getService";

const Main = () => {
  // const pr = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [mainData, setMainData] = useState([]);

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      // Promise.all([fetchData()]).then(res => {
      setIsLoading(false);
      // });
    }
    return () => {
      called.current = false;
    };
  }, []);

  // const fetchData = async () => {

  //     getData('upcomingEvent', pr.id).then(
  //         response => {
  //             if (response.status === 'success') {
  //                 setMainData(response.data)
  //             }
  //         }).catch(e => {
  //             console.log(e.response.data.error)
  //         })
  // }

  // console.log(mainData.photo ? JSON.parse(mainData.photo) : null)

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="activities" sub="capacity" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="pb-md-5 bg-top">
            <div className="container-fluid">
              <div className="row justify-content-center pb-md-5 text-start">
                <div className="col-12 col-md-12 col-lg-10 pb-5 pt-5 mt-md-5">
                  {/* {mainData ? */}
                  <div className="board-01 py-4 px-3 px-md-5">
                    <div className="mt-4 pb-md-5">
                      <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="">
                            <img src={banner} className="w-100 rounded" />
                            {/* <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + mainData.id + '/' + mainData.banner} className="w-100 rounded" /> */}
                          </div>
                          <div className="sub-topic mt-3">
                            Waste Hero: Reduce to Zero Online Master Teacher
                            Training in Southeast Asia
                          </div>
                          <div className="">July - October 2023</div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="py-3" style={{}}>
                            <hr />
                            <div
                              className="font-18 content"
                              style={{ lineHeight: "2" }}
                            >
                              <div className="weight-700">A. Background</div>
                              In response to the priority areas of{" "}
                              <a
                                href="https://www.seameo.org/Main_centres/117#demo14"
                                className=""
                                target="_blank"
                              >
                                SEAMEO Regional Centre for Sufficiency Economy
                                Philosophy for Sustainability (SEAMEO SEPS)
                              </a>
                              on environmental issues and sustainable
                              development under the UN Sustainable Development
                              Goals (SDGs), SEAMEO SEPS joins hand with Indorama
                              Ventures PCL (IVL), and other global and regional
                              partners to promote the “Waste Hero: Reduce to
                              Zero” educational resources to schools and create
                              the Active Actions in waste management and saving
                              environment of students in families, schools and
                              communities.
                              <div className="weight-700 mt-4">
                                B. Waste Hero: Reduce to Zero
                              </div>
                              <div>
                                Waste Hero: Reduce to Zero is a{" "}
                                <a
                                  href="https://www.wasteheroeducation.com/th"
                                  className=""
                                  target="_blank"
                                >
                                  free global zero waste education programme
                                </a>{" "}
                                with the aim to educate 1 million people by 2030
                                on recycling fundamentals, creating zero waste,
                                and how to build a circular economy. Waste Hero
                                consists of 19 free lesson plans and workshops
                                for K-12 and university level students. The
                                lessons are designed to be engaging and fun, but
                                also action oriented for young learners to apply
                                the principals of the circular economy in both
                                the classroom and in their community. The Waste
                                Hero consists of 19 free lesson plans and
                                workshops for K-12 and university level
                                students.
                              </div>
                              <div className="weight-700 mt-4">
                                C. Organisers
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <a
                                      href="https://www.seameo.org/Main_centres/117#demo14"
                                      target="_blank"
                                    >
                                      SEAMEO Regional Centre for Sufficiency
                                      Economy Philosophy for Sustainability
                                      (SEAMEO SEPS)
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.indoramaventures.com/en/home"
                                      target="_blank"
                                    >
                                      Indorama Ventures PCL (IVL)
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="weight-700 mt-4">
                                D. Supporting Partners
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <a
                                      href="https://bangkok.unesco.org/"
                                      target="_blank"
                                    >
                                      UNESCO Multisectoral Regional Office in
                                      Bangkok (UNESCO Bangkok)
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://yunus-thailand.org/th"
                                      target="_blank"
                                    >
                                      Yunus Thailand Foundation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.seaohun.org/"
                                      target="_blank"
                                    >
                                      Southeast Asia One Health University
                                      Network (SEAOHUN)
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://www.seameo.org/"
                                      target="_blank"
                                    >
                                      Southeast Asian Ministers of Education
                                      Organization Secretariat (SEAMEO
                                      Secretariat)
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.seamolec.org/"
                                      target="_blank"
                                    >
                                      SEAMEO Regional Open Learning Centre
                                      (SEAMEO SEAMOLEC - Indonesia)
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://seameoted.org/"
                                      target="_blank"
                                    >
                                      SEAMEO Regional Centre for Technical
                                      Education Development (SEAMEO TED –
                                      Cambodia)
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://www.recsam.edu.my/"
                                      target="_blank"
                                    >
                                      SEAMEO Regional Centre for Education in
                                      Science and Mathematics (SEAMEO RECSAM-
                                      Malaysia)
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.vnseameo.org/"
                                      target="_blank"
                                    >
                                      SEAMEO Regional Training Centre (SEAMEO
                                      RETRAC - Vietnam)
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="weight-700 mt-4">
                                E. Objectives
                              </div>
                              <div>
                                The Master Teacher Training Programme is a major
                                activity under the Waste Hero Development
                                Co-campaign, spearheaded by the SEAMEO SEPS.
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    To empower kindergarten, primary, secondary
                                    and vocational & technical school teachers
                                    in Southeast Asian countries, to have
                                    understanding in waste management and
                                    circular economy and be able to create the
                                    lesson plan and apply the Waste Hero
                                    educational materials in the classroom
                                    activities and co-curriculum programmes,
                                  </li>
                                  <li>
                                    To build awareness of kindergarten, primary,
                                    secondary, and vocational & technical school
                                    students on environmental issues, waste
                                    management, and circular economy, and take
                                    ACTIVE actions in saving environment in
                                    their schools, families and communities, and
                                  </li>
                                  <li>
                                    To share and exchange ideas, creativities
                                    and best practices in Waste Hero Actions and
                                    Circular Economy among schools, teachers and
                                    students of Southeast Asian communities.
                                  </li>
                                </ul>
                              </div>
                              <div className="weight-700 mt-4">
                                F. Expected Outcomes
                              </div>
                              <div>
                                By the end of the training session, participants
                                will…
                              </div>
                              <div>
                                <ol>
                                  <li>
                                    Understand Waste Hero Education and the main
                                    objectives of the programme.
                                  </li>
                                  <li>
                                    Understand the core pillars of Waste Hero,
                                    the material details and structure of the
                                    lesson packages.
                                  </li>
                                  <li>
                                    Understand how to navigate the lesson
                                    packages, key aspects that add value to
                                    teachers, and where to access them online to
                                    easily share with other teachers.
                                  </li>
                                  <li>
                                    Understand how to effectively design and
                                    prepare Waste Hero lesson activities with
                                    proven best practices both online and
                                    in-person.
                                  </li>
                                  <li>
                                    Understand how to conduct an in-person
                                    “classroom” activity and fully engage
                                    students in the lesson activities.
                                  </li>
                                </ol>
                              </div>
                              <div className="weight-700 mt-4">
                                G. Participants
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    Teachers of kindergarten, primary,
                                    secondary, and vocational & technical
                                    education level (equivalent to Grade 12)
                                  </li>
                                  <li>
                                    Participants should have interest in
                                    environmental education, waste management,
                                    circular economy, climate change, global
                                    warming and other relevant issues.
                                  </li>
                                  <li>Good command in English</li>
                                  <li>
                                    Be able to participate in the online
                                    training session.
                                  </li>
                                  <li>
                                    Commitment to submit the Post-Training
                                    Report to SEAMEO SEPS after participating in
                                    the training course 1-2 months of training.
                                  </li>
                                </ul>
                              </div>
                              <div className="weight-700 mt-4">
                                H. Training Platform
                              </div>
                              <div>
                                <ul>
                                  <li>Zoom meeting platform</li>
                                  <li>Live by YouTube</li>
                                </ul>
                              </div>
                              <div className="weight-700 mt-4">
                                I. Registration Fee
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    Free of charge as it will be fully supported
                                    by SEAMEO SEPS, and Indorama Ventures PCL.
                                  </li>
                                </ul>
                              </div>
                              <div className="weight-700 mt-4">
                                J. Online Training Schedule
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    Philippines
                                    <ul>
                                      <li>
                                        Kindergarten and Primary School
                                        Teachers:
                                        <ul>
                                          <li>
                                            Saturday 22 July 2023, 09.00-12.00
                                            hrs (PH Time/GMT+8)
                                          </li>
                                          <li>
                                            <a
                                              href="https://www.youtube.com/live/fcqA0wiyCDo?feature=share"
                                              target="_blank"
                                            >
                                              Watch Training Session
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        Secondary School Teachers:
                                        <ul>
                                          <li>
                                            Saturday 22 July 2023, 09.00-12.00
                                            hrs (PH Time/GMT+8)
                                          </li>
                                          <li>
                                            <a
                                              href="https://www.youtube.com/live/MnwDZRtwaXU?feature=share"
                                              target="_blank"
                                            >
                                              Watch Training Session
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Indonesia
                                    <ul>
                                      <li>
                                        Kindergarten and Primary School
                                        Teachers:
                                        <ul>
                                          <li>
                                            Sunday 23 July 2023, 09.00-12.00 hrs
                                            (ID Time/GMT+7)
                                          </li>
                                          <li>
                                            <a
                                              href="https://www.youtube.com/live/35Mj1urcs9o?feature=share"
                                              target="_blank"
                                            >
                                              Watch Training Session
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        Secondary School and Vocational &
                                        Technical School Teachers:
                                        <ul>
                                          <li>
                                            Sunday 23 July 2023, 13.00-16.00 hrs
                                            (ID Time/GMT+7)
                                          </li>
                                          <li>
                                            <a
                                              href="https://www.youtube.com/live/1lOteGrnJK0?feature=share"
                                              target="_blank"
                                            >
                                              Watch Training Session
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Cambodia
                                    <ul>
                                      <li>
                                        Secondary School and Vocational &
                                        Technical School Teachers:
                                        <ul>
                                          <li>
                                            Friday 6 October 2023, 09.00-12.00
                                            hrs (Cambodia Time/GMT+7)
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        Kindergarten and Primary School
                                        Teachers:
                                        <ul>
                                          <li>
                                            Friday 6 October 2023, 09.00-12.00
                                            hrs (Cambodia Time/GMT+7)
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                Note: The translation to Cambodian language is
                                provided throughout the session.
                              </div>
                              <div>
                                <a
                                  href="https://docs.google.com/forms/d/e/1FAIpQLSdne5Tdw587OzN4J0w2PKNy3ekBX7S3_s-pcwYBpzpFBWNokg/viewform"
                                  target="_blank"
                                >
                                  Link to Registration Form for Special Session
                                  of Cambodian Teachers
                                </a>
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    Vietnam, Malaysia, and other countries
                                    <ul>
                                      <li>
                                        Kindergarten and Primary School
                                        Teachers:
                                        <ul>
                                          <li>
                                            Saturday 7 October 2023, 09.00-12.00
                                            hrs (BKK Time/GMT +7)
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        Secondary School Teachers:
                                        <ul>
                                          <li>
                                            Saturday 7 October 2023, 13.00-16.00
                                            hrs (BKK Time/GMT+7)
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <a
                                  href="https://docs.google.com/forms/d/e/1FAIpQLScHTBiGmE7rdzNB8KvmltyeIQmVvHq3p_G0meMSQnEfl0ZzZg/viewform"
                                  target="_blank"
                                >
                                  Link to Registration Form
                                </a>
                              </div>
                              <div className="weight-700 mt-4">
                                K. Training Programme
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Training programme for kindergarten and primary
                                school teachers
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-4 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Training programme for secondary and vocational
                                & technical school teachers
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div className="weight-700 mt-4">
                                L. Resource Persons/Trainers​
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    Training specialists from Ministry of
                                    Education of Thailand
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Trainers for Primary Education Level​
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-4 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Trainers for Secondary Education Level​
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div className="weight-700 mt-4">
                                M. Certification
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    e-Certificate of Accomplishment will be
                                    provided to the teachers who
                                  </li>
                                </ul>
                                <ol>
                                  <li>attend the whole trainings session,</li>
                                  <li>
                                    submit the Training Session Evaluation which
                                    its link will be presented at the end of the
                                    training session, and
                                  </li>
                                  <li>
                                    submit the Post-Training Report to SEAMEO
                                    SEPS after 1-2 months of training.
                                  </li>
                                </ol>
                              </div>
                              <div className="weight-700 mt-4">
                                N. Post Training Report
                              </div>
                              <div>
                                <ul>
                                  <li>Philippines and Indonesia</li>
                                </ul>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Guidelines for submitting the Post -Training
                                Report
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <a
                                      href="https://shorturl.at/jwDUW"
                                      target="_blank"
                                    >
                                      Link to submit the Post-Training Report
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                Important Note: If you have difficulty to submit
                                the report to the above Google Form,you can
                                simply download
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                the Post-Training Report Template
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div>
                                and directly submit the report to email:
                                <a
                                  href="mailto:wastehero@seameoseps.org"
                                  target="_blank"
                                >
                                  wastehero@seameoseps.org
                                </a>
                              </div>
                              <div className="weight-700 mt-4">
                                O. Focal Person
                              </div>
                              <div>
                                ​Ms Piyapa Su-angavatin Programme Officer
                              </div>
                              <div>
                                Email:{" "}
                                <a
                                  href="mailto:wastehero@seameoseps.org"
                                  target="_blank"
                                >
                                  wastehero@seameoseps.org
                                </a>
                              </div>
                              <div className="weight-700 mt-4">
                                P. Other References
                              </div>
                              <div>
                                <ul>
                                  <li>
                                    <a
                                      href="https://wasteheroeducation.com/"
                                      target="_blank"
                                    >
                                      Waste Hero Education Website
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.youtube.com/@wasteheroeducation"
                                      target="_blank"
                                    >
                                      Waste Hero Introduction Video Clips
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Overview about Waste Hero Education and SEAMEO
                                SEPS Waste Hero Development Co-campaign
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Presentation of Waste Hero Master Teacher
                                Training: Kindergarten to Primary Education
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Presentation of Waste Hero Master Teacher
                                Training: Secondary Education
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                              <div
                                className="px-3 rounded mt-2 position-relative mb-2 box-ddd"
                                style={{ background: "#F5F5F5" }}
                              >
                                Logo of Waste Hero
                                <div className="position-absolute absolute-center-right">
                                  <a className="btn btn-download">
                                    <img
                                      src={download}
                                      className=""
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* : null} */}
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Main;
