import React, { useState, useEffect, useRef } from 'react';


import Header from '../../layout/header';
import Footer from '../../layout/footer';

import bg1 from '../../assets/img/bg-structure.png';
import mail from '../../assets/img/icon/Mail.svg';

import Loading from "../../components/loading";
import { getData } from '../../services/getService';

const CentresStructure = () => {

    const [isLoading, setIsLoading] = useState(true)

    const [mainData, setMainData] = useState([]);

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false)
            });
        }
        return () => {
            called.current = false
        }
    }, []);

    const fetchData = async () => {

        getData('staff').then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="about" sub="structure" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="py-md-5 bg-top" style={{ backgroundImage: `url(${bg1})` }}>
                        <div className="container py-5">
                            <div className="row justify-content-center py-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-12 mb-5">
                                    <div className='font-60 weight-700'>Executive and <br />Staff Members</div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 px-4 px-md-0">
                                    <div className="row justify-content-md-center justify-content-lg-start">
                                        {mainData.length ? mainData.map((val, index) => (
                                            <div className="col-12 col-md-9 col-lg-6 mb-4" key={index}>
                                                <div className='position-relative h-100' style={{}}>
                                                    <div className='py-5 px-4 board-01' style={{}}>
                                                        <div className='font-28 weight-700'>
                                                            {val.name}
                                                        </div>
                                                        <div className='mt-1 mb-4'>
                                                            {val.position}
                                                        </div>
                                                        <div className='position-relative py-2 ps-3' style={{ border: '1px solid #000', borderRadius: '10px', paddingRight: '80px' }}>
                                                            <a href={"mailto:" + val.email} className='color-black weight-700 font-18 font-res-12 d-block'>
                                                                Email : {val.email}
                                                                <div className='position-absolute icon-mail'><img src={mail} className="w-100" /></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer />
                </div>
            </div>

        </>
    );
};

export default CentresStructure;