import React, { useState, useEffect, useRef } from "react";

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import { Link } from "react-router-dom";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";

import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});


    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "camp")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)                   
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="capacity" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="">
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-md-4">
                                    <div className="font-60 weight-700">{dataOne.topic}</div>
                                    <div className="mb-5 pt-3">
                                        <div className="text-justify font-18 content-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: dataOne.detail,
                                            }}
                                        />
                                    </div>
                                    {dataOne.news && dataOne.news.length ?
                                    <div>
                                        <div className="topic mb-4 mt-5">News</div>
                                        <div className="row">
                                            {/* {dataNews.length ? dataNews.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => ( */}
                                            {dataOne.news.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => (
                                                <div
                                                    className="col-12 col-md-12 col-lg-4"
                                                    key={index}
                                                >
                                                    <Link
                                                        to={"/newsandgallery/news/" + val.newsId}
                                                        className="a-news pb-4 mb-4 mb-md-5"
                                                    >
                                                        <div className="row text-start">
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_IMG_LINK +
                                                                            "/uploads/news/" +
                                                                            val.newsId +
                                                                            "/" +
                                                                            val.banner
                                                                        }
                                                                        className="w-100 rounded"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <div className="font-14 mt-2">
                                                                    {moment(val.dateActive).format("D MMMM Y")}
                                                                </div>

                                                                <div className="news-topic mt-3 mt-md-0"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: val.topic,
                                                                    }}
                                                                />
                                                                {/* <div className="mt-1">
                                                                    <TagView myString={val.tag} />
                                                                </div> */}
                                                                <div className="mt-4">
                                                                    <LinesEllipsis
                                                                        text={val.subTopic}
                                                                        maxLine="3"
                                                                        ellipsis="..."
                                                                        trimRight
                                                                        basedOn="letters"
                                                                    />
                                                                </div>
                                                                <div className="mt-3">
                                                                    <a style={{ color: "#e6c12f" }}>
                                                                        Read More
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    : null }
                                </div>

                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="capacity" />
                </div>
            </div>
        </>
    );
};

export default Main;
