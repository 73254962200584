import React, { useState, useEffect, useCallback } from 'react';

// import { PDFViewer } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from "react-pdf";


import SinglePagePDFViewer from "../components/pdf/single-page";
// import AllPagesPDFViewer from "../components/pdf/all-pages";
// import samplePDF from '../1696651085f.pdf';


import { Modal, Button } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import sorn from '../assets/img/icon/sorn.svg';

// import MyDocument from '../components/MyDocument'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Modalgallery = ({ dataUrl, ...datat }) => {

    const handleDragStart = (e) => e.preventDefault();


    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    };
    console.log(datat)
    return (
        <>
            <Modal {...datat} className="modal-gallery" size="lg" backdrop="static" centered keyboard={false}>
                <Modal.Header closeButton closeVariant="white" className='px-0'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 ">
                    <div className="d-block w-100 scroll-custom">
                        <div className='h-100per-modal'>
                            {/* <div className=''>
                                <img src="http://localhost/wedding-service/uploads/reviews/27/1660893977a.jpg" alt="" className="w-100" />
                            </div> */}
                            <div className=''>
                                <div className=''>
                                    {/* <img src={dataUrl} alt="" className="w-100" /> */}
                                    {/* <PDFViewer>
                                        <MyDocument dataUrl={dataUrl} />
                                    </PDFViewer> */}
                                    {/* <SinglePagePDFViewer pdf={samplePDF} /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Modalgallery;