import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import moment from "moment";

import { getData } from "../../services/getService";

import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Loading from "../../components/loading";

import binIcon from "../../assets/img/icon/Bin.svg";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [inputData, setInputData] = useState({ name: "", month: "", year: "" });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = ["2024", "2023", "2022", "2021", "2020"];

  const called = useRef(true);
  const myFormRef = useRef(true);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchData()]).then((res) => {
        setIsLoading(false);
      });
    }
    return () => {
      called.current = false;
    };
  }, []);

  const fetchData = async (page, keySearch) => {
    getData("upcomingEvent")
      .then((response) => {
        if (response.status === "success") {
          setNewsData(response.data);
          setFilteredList(response.data);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const dataForm = new FormData(event.target);

    // const name = get

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      // $('html, body').animate({
      //     scrollTop: $(errorElements[0]).offset().top
      //   }, 2000);
    } else {
      var filtered = filteredList;

      const keyword = inputData.name;
      const keymonth = inputData.month;
      const keyyear = inputData.year;
      if (keyword) {
        filtered = filtered.filter((entry) =>
          entry.topic.toLowerCase().includes(keyword.toLowerCase())
        );
        if (keymonth) {
          filtered = filtered.filter((entry) =>
            entry.dateText.toLowerCase().includes(keymonth.toLowerCase())
          );
          if (keyyear) {
            filtered = filtered.filter((entry) =>
              entry.dateText.toLowerCase().includes(keyyear.toLowerCase())
            );
          }
        } else {
          if (keyyear) {
            filtered = filtered.filter((entry) =>
              entry.dateText.toLowerCase().includes(keyyear.toLowerCase())
            );
          }
        }
      } else {
        if (keymonth) {
          filtered = filtered.filter((entry) =>
            entry.dateText.toLowerCase().includes(keymonth.toLowerCase())
          );
          if (keyyear) {
            filtered = filtered.filter((entry) =>
              entry.dateText.toLowerCase().includes(keyyear.toLowerCase())
            );
          }
        } else {
          if (keyyear) {
            filtered = filtered.filter((entry) =>
              entry.dateText.toLowerCase().includes(keyyear.toLowerCase())
            );
          }
        }
      }
      setNewsData(filtered);
    }
  };

  const cancelCourse = (ev) => {
    // myFormRef.reset();
    ev.preventDefault();
    myFormRef.current.reset();
    setInputData({ name: "", month: "", year: "" });
    setNewsData(filteredList);
  };

  // console.log(inputData)

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="activities" sub="upcoming" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}

          <section className="my-5" id="page-wrap">
            <div className="container-fluid pb-5 px-lg-5">
              <div className="row text-start justify-content-center">
                <div className="col-12 col-md-11 col-lg-11">
                  <div className="row mb-3 mb-md-5 mt-lg-5">
                    <div className="col-12 col-md-5 col-lg-3 pe-md-3 pe-lg-5">
                      <div className="topic mb-4 pb-2 d-block d-md-none">
                        Upcoming Events
                      </div>
                      <div className="sub-topic pt-2 d-flex align-middle align-items-center">
                        Search
                        <span
                          className="font-14 ms-auto cursor-pointer"
                          onClick={cancelCourse}
                        >
                          {" "}
                          <img
                            src={binIcon}
                            className="me-2"
                            alt=""
                            style={{ width: "15px" }}
                          />
                          Clear
                        </span>
                      </div>
                      <div className="mt-3 mb-5">
                        <Form
                          noValidate
                          onSubmit={handleSubmit}
                          ref={myFormRef}
                        >
                          <div className="row text-start">
                            <div className="mb-2 col-12">
                              <div className="mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="search"
                                  placeholder="Event Name"
                                  // defaultValue={keySearch}
                                  onChange={(e) =>
                                    setInputData((prev) => ({
                                      ...prev,
                                      name: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                              <div className="mb-2">
                                <select
                                  className="form-select"
                                  onChange={(e) =>
                                    setInputData((prev) => ({
                                      ...prev,
                                      month: e.target.value,
                                    }))
                                  }
                                >
                                  <option>Month</option>
                                  {months.map((val, index) => (
                                    <option value={val} key={index}>
                                      {val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mb-2">
                                <select
                                  className="form-select"
                                  onChange={(e) =>
                                    setInputData((prev) => ({
                                      ...prev,
                                      year: e.target.value,
                                    }))
                                  }
                                >
                                  <option>Year</option>
                                  {years.map((val, index) => (
                                    <option value={val} key={index}>
                                      {val}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="mt-4 text-end">
                                <button className="btn btn-outline-dark btn-search-box px-5">
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div className="col-12 col-md-7 col-lg-6">
                      <div className="topic mb-4 pb-2 d-none d-md-block">
                        Upcoming Events
                      </div>
                      <div>
                        {newsData.length ? (
                          newsData.map((val, index) => (
                            <Link
                              to={"/activities/upcomingEvent/" + val.id}
                              className="a-news mb-1"
                              key={index}
                            >
                              <div className="box-upcoming-event mb-2">
                                <div className="event-topic">{val.topic}</div>
                                <div>{val.dateText}</div>
                                <div>{val.subTopic}</div>
                              </div>
                            </Link>
                          ))
                        ) : (
                          <div className="text-center">-- No Data --</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer page="activities" sub="upcoming"/>
        </div>
      </div>
    </>
  );
};

export default Home;
