import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";

import Loading from "../../components/loading";
import backIcon from "../../assets/img/icon/back.svg";
import nextIcon from "../../assets/img/icon/sorn.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getData } from "../../services/getService";
import Modalgallery from "../../components/modalGallery";

const CentresOverview = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});
    const [dataTwo, setDataTwo] = useState({});
    const [dataH, setDataH] = useState({});
    const [mainData, setMainData] = useState();
    const [dataThree, setDataThree] = useState([]);
    const called = useRef(true);

    const [modalgallery, setModalgallery] = useState({ view: false });

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            // <div
            //   className={className}
            //   style={{ ...style, display: "block", background: "red" }}
            //   onClick={onClick}
            // />
            <div className={className}>
                <img
                    src={nextIcon}
                    className="ms-2"
                    style={{ width: "12px", marginTop: "-20px" }}
                    onClick={onClick}
                />
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            // <div
            //   className={className}
            //   style={{ ...style, display: "block", background: "green" }}
            //   onClick={onClick}
            // />
            <div className={className}>
                <img
                    src={nextIcon}
                    className="me-2"
                    style={{
                        transform: "rotate(180deg)",
                        width: "12px",
                        marginTop: "-20px",
                    }}
                    onClick={onClick}
                />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        // className: "center",
        // centerMode: true,
        centerPadding: "60px",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("vinformation", pr.id)
            .then((response) => {
                if (response.status === "success") {
                    setMainData(response.data)
                    fetchDataThree(response.data.catId)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    const fetchDataThree = async (catId) => {
        var iid
        if (catId === 'virtual') {
            iid = 'previous'
        } else {
            iid = catId
        }
        getData("pageawards", iid)
            .then((response) => {
                if (response.status === "success") {
                    setDataThree(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" sub="archives" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="my-5" id="page-wrap">
                        <div className="container-fluid">
                            <div className="row justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-10">
                                    {mainData ? (<>
                                        <div className="px-3 px-md-3 px-lg-5">
                                            <div className="row align-items-center mb-3 mb-md-5 mt-lg-5">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="font-60 weight-700 yellow-black">
                                                        Archives
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-10">
                                                    <div className="">
                                                        <span className="">Programmes and Activities</span>|
                                                        <span className="">Information Exchange </span>|
                                                        <span className="text-decoration-underline">{mainData.name + ' ' + mainData.year}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-2 text-end">
                                                    <div className="">
                                                        <Link
                                                            to="/newsandgallery/archives"
                                                            className="btn btn-back ps-0 weight-700"
                                                        >
                                                            <img
                                                                src={backIcon}
                                                                style={{ width: "30px" }}
                                                                alt=""
                                                            />{" "}
                                                            Back
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="mt-3">
                                                        {/* <div className="font-32 weight-700"> */}
                                                        {/* {mainData.topic} */}
                                                        <div className="topic weight-700"
                                                            dangerouslySetInnerHTML={{
                                                                __html: mainData.name,
                                                            }}
                                                        />
                                                        {/* </div> */}
                                                        {/* <div className="font-14 mt-2">
                                                                {moment(mainData.dateActive).format("D/MM/Y")}
                                                            </div> */}

                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="">
                                                        <div className="pt-4">
                                                            {mainData.img ?
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_IMG_LINK +
                                                                        "/uploads/vinformation/" +
                                                                        mainData.id +
                                                                        "/" +
                                                                        mainData.img
                                                                    }
                                                                    className="w-100 rounded"
                                                                    alt=""
                                                                />
                                                                : <div style={{ background: "#D9D9D9", height: '400px' }}></div>}
                                                        </div>

                                                        <div className="topic mb-4 mt-5 pt-5">Programme of Activities</div>
                                                        <div>
                                                            <div className="row ">
                                                                {dataThree.length ? dataThree.sort((a, b) => parseInt(b.id) - parseInt(a.id)).map((val, index) => (
                                                                    <div className="col-12 col-md-4 col-lg-4 mb-5" key={index}>
                                                                        <a
                                                                            href={val.detailSub}
                                                                            target="_blank"
                                                                            className="box-web-col rounded-30 pb-3 overflow-hidden"
                                                                        >
                                                                            <div>
                                                                                <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + val.id + '/' + val.img} className="w-100" alt="" />
                                                                            </div>
                                                                            <div className="news-topic p-3">
                                                                                {val.topic}
                                                                            </div>
                                                                            <div className="pb-4 px-3 mt-4 text-center">
                                                                                <div className="row ">
                                                                                    {val.subtopic &&
                                                                                        <div className="col-6 col-md-6 pe-1">
                                                                                            <a className="btn btn-about text-white font-16 w-100 px-0" href={val.subtopic} target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px' }}>Details</a>
                                                                                        </div>
                                                                                    }
                                                                                    <div className="col-6 col-md-6 ps-1">
                                                                                        <a className="btn btn-blue font-16 w-100 px-0" href={val.detailSub} target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px' }}>Watch</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                )) : null}

                                                            </div>
                                                        </div>

                                                        {mainData.news && mainData.news.length ?
                                                            <div>
                                                                <div className="topic mb-4 mt-5">News</div>
                                                                <div className="row">
                                                                    {/* {dataNews.length ? dataNews.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => ( */}
                                                                    {mainData.news.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => (
                                                                        <div
                                                                            className="col-12 col-md-12 col-lg-4"
                                                                            key={index}
                                                                        >
                                                                            <Link
                                                                                to={"/newsandgallery/news/" + val.newsId}
                                                                                className="a-news pb-4 mb-4 mb-md-5"
                                                                            >
                                                                                <div className="row text-start">
                                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                                        <div>
                                                                                            <img
                                                                                                src={
                                                                                                    process.env.REACT_APP_IMG_LINK +
                                                                                                    "/uploads/news/" +
                                                                                                    val.newsId +
                                                                                                    "/" +
                                                                                                    val.banner
                                                                                                }
                                                                                                className="w-100 rounded"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                                        <div className="font-14 mt-2">
                                                                                            {moment(val.dateActive).format("D MMMM Y")}
                                                                                        </div>

                                                                                        <div className="news-topic mt-3 mt-md-0"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: val.topic,
                                                                                            }}
                                                                                        />
                                                                                        {/* <div className="mt-1">
                                                                    <TagView myString={val.tag} />
                                                                </div> */}
                                                                                        <div className="mt-4">
                                                                                            <LinesEllipsis
                                                                                                text={val.subTopic}
                                                                                                maxLine="3"
                                                                                                ellipsis="..."
                                                                                                trimRight
                                                                                                basedOn="letters"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="mt-3">
                                                                                            <a style={{ color: "#e6c12f" }}>
                                                                                                Read More
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            : null}

                                                        <div className="pb-5 " style={{}}>
                                                            <div class="topic mb-4"> Photos of Activities</div>
                                                            {/* <div
                                                                    className="mt-3 content"
                                                                    style={{ lineHeight: "2" }}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: mainData.detail,
                                                                        }}
                                                                    />
                                                                </div> */}

                                                            <div className="mt-3 box-slide-detail">
                                                                <Slider {...settings}>
                                                                    {JSON.parse(mainData.photo).length
                                                                        ? JSON.parse(mainData.photo)
                                                                            .slice(0, 5)
                                                                            .map((val, index) => (
                                                                                <div className="" key={index}>
                                                                                    <div
                                                                                        className="px-1 cursor-pointer"

                                                                                        onClick={() =>
                                                                                            setModalgallery({
                                                                                                view: true,
                                                                                                gallery: JSON.parse(
                                                                                                    mainData.photo
                                                                                                ),
                                                                                                dataUrl:
                                                                                                    process.env
                                                                                                        .REACT_APP_IMG_LINK +
                                                                                                    "/uploads/vinformation/" +
                                                                                                    mainData.id +
                                                                                                    "/" +
                                                                                                    val,
                                                                                                active: index,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                process.env.REACT_APP_IMG_LINK +
                                                                                                "/uploads/vinformation/" +
                                                                                                mainData.id +
                                                                                                "/" +
                                                                                                val
                                                                                            }
                                                                                            className="w-100 rounded"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        : null}
                                                                </Slider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer page="news" sub="news" />
                </div>
            </div>
            {modalgallery.view ? (
                <Modalgallery
                    show={modalgallery.view}
                    onHide={() => setModalgallery({ view: false })}
                    dataUrl={modalgallery.dataUrl}
                    folder={"vinformation"}
                    gallery={modalgallery.gallery}
                    active={modalgallery.active}
                    id={pr.id}
                />
            ) : null}
        </>
    );
};

export default CentresOverview;
