import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';

import Header from '../../layout/header';
import Footer from '../../layout/footer';


import bg1 from '../../assets/img/bg-training.png';


import content1 from '../../assets/img/content1.png';
import content2 from '../../assets/img/content2.jpg';
import go from '../../assets/img/icon/Go.svg';

import Loading from "../../components/loading";
import { getData } from '../../services/getService';

const TrainingProgrammes = () => {

    const [isLoading, setIsLoading] = useState(true)

    const [mainData, setMainData] = useState([]);

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false)
            });
        }
        return () => {
            called.current = false
        }
    }, []);

    const fetchData = async () => {

        getData('activity').then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const truncateTopic = (str) => {
        return str.length > 50 ? str.substring(0, 50) + "..." : str;
    }
    const truncate = (str) => {
        return str.length > 100 ? str.substring(0, 100) + "..." : str;
    }

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="training" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start" style={{ backgroundColor: '#F5F5F5' }}>
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className='position-relative'>
                                        <img src={bg1} className="w-100" />
                                        <div className='position-absolute absolute-center-left w-100'>
                                            <div className="container">
                                                <div className="row justify-content-center py-md-5 text-start">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className='font-60 weight-700'>SEAMEO SEPS' <br />Activities</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-11 box-margin-top pb-5 mb-5 px-4 px-md-0">
                                    <div className="row justify-content-md-center justify-content-lg-start">
                                        {mainData.length ? mainData.map((val, index) => (
                                            <div className="col-12 col-md-10 col-lg-6 my-4 my-md-5" key={index}>
                                                <Link to={"/activities/trainingProgrammesDetail/" + val.id} className='pe-5 position-relative box-structor' style={{}}>
                                                    <div className='h-100 board-01' style={{}}>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-md-5 col-lg-5">
                                                                <div className='box-activities'>
                                                                    <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + val.id + '/' + val.banner} className="w-100" />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-7 col-lg-7">
                                                                <div className='py-4 ps-3 pe-3 ps-md-0 pe-md-5' style={{}}>
                                                                    <div className='font-26 weight-700'>
                                                                        {truncateTopic(val.topic)}
                                                                    </div>
                                                                    <div className='mt-2 font-size-16'>
                                                                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                            <div dangerouslySetInnerHTML={{ __html: truncate(val.detail) }} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='absolute-center-right icon-go'>
                                                        <img src={go} className="w-100" />
                                                    </div>
                                                </Link>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer />
                </div>
            </div>

        </>
    );
};

export default TrainingProgrammes;