import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { Animated } from "react-animated-css";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

import bg1 from "../../assets/img/about-bg.png";
import logoSeps1 from "../../assets/img/LOGO-SEPS1.svg";
import footer from "../../assets/img/about-footer.png";
import bgfoot from "../../assets/img/about-bg-foot.png";
import bgBottom from "../../assets/img/about-bg-bottom.jpg";

import m1 from "../../assets/img/icon/Mission-01.svg";
import m2 from "../../assets/img/icon/Mission-02.svg";
import m3 from "../../assets/img/icon/Mission-03.svg";
import m4 from "../../assets/img/icon/Mission-04.svg";
import m5 from "../../assets/img/icon/Mission-05.svg";

const CentresOverview = () => {
  const handleDragStart = (e) => e.preventDefault();

  const responsive = {
    0: { items: 1.2 },
    568: { items: 2 },
    1024: { items: 5 },
  };

  useEffect(() => {
    // fetchData(0)
  }, []);

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="about" sub="overview" />
        <div className="wrapper bg-white box-index">
          <div
            className="position-relative d-none d-md-block"
            style={{ marginTop: "-100px", zIndex: "1" }}
          >
            <img src={bg1} className="w-100" />
            <div className="position-absolute absolute-center-left w-100">
              <div className="container pb-md-5 w-100">
                <div className="row align-items-center justify-content-center text-start">
                  <div className="col-12 col-md-12 col-lg-10">
                    <div className="font-100 weight-700">
                      <Animated
                        animationIn="fadeInLeft"
                        animationOut="fadeOut"
                        animationInDelay={600}
                        // animationInDuration={2000}
                        isVisible={true}
                      >
                        <div>WHO</div>
                      </Animated>
                      <Animated
                        animationIn="fadeInLeft"
                        animationOut="fadeOut"
                        animationInDelay={700}
                        // animationInDuration={2000}
                        isVisible={true}
                      >
                        <div style={{ lineHeight: "0.5" }}>WE </div>
                      </Animated>
                      <Animated
                        animationIn="fadeInLeft"
                        animationOut="fadeOut"
                        animationInDelay={800}
                        // animationInDuration={2000}
                        isVisible={true}
                      >
                        <div>ARE</div>
                      </Animated>
                    </div>
                    <div className="font-20 mt-5">
                      The SEAMEO Regional Centre for Sufficiency Economy
                      Philosophy for Sustainability (SEAMEO SEPS) is one of 26
                      Regional Centres of SEAMEO.
                    </div>
                    <div className="font-20 mt-4">
                      <a
                        href="https://www.seameo.org/"
                        target={"_blank"}
                        rel="noreferrer"
                        className="btn btn-register"
                      >
                        SEAMEO
                      </a>
                    </div>
                    <div className="font-20 mt-5">
                      The Centre was approved for establishment during the 49th
                      SEAMEO Council Conference, held in Jakarta, Indonesia, in
                      July 2019. Hosted by the Thai government, SEAMEO SEPS
                      commenced operations in November 2019 from its office on
                      the 11th Floor of the Natural Science Building in Bangkok.
                      <br />
                      <br />
                      The Centre is guided by a clear mandate:
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="position-relative d-block d-md-none bg-top"
            style={{ zIndex: "1", backgroundImage: `url(${bg1})` }}
          >
            {/* <img src={bg1} className="w-100" /> */}
            <div className="w-100 py-5">
              <div className="container pb-md-5 w-100">
                <div className="row align-items-center justify-content-center text-start">
                  <div className="col-12 col-md-12 col-lg-10">
                    <div className="font-100 weight-700">
                      <div>WHO</div>
                      <div style={{ lineHeight: "0.5" }}>WE </div>
                      <div>ARE</div>
                    </div>
                    <div className="font-20 mt-5">
                      The SEAMEO Regional Centre for Sufficiency Economy
                      Philosophy for Sustainability (SEAMEO SEPS) is one of 26
                      Regional Centres of SEAMEO.
                    </div>
                    <div className="font-20 mt-4">
                      <a
                        href="https://www.seameo.org/"
                        target={"_blank"}
                        rel="noreferrer"
                        className="btn btn-register"
                      >
                        SEAMEO
                      </a>
                    </div>
                    <div className="font-20 mt-5">
                      The Centre was approved for establishment during the 49th
                      SEAMEO Council Conference, held in Jakarta, Indonesia, in
                      July 2019. Hosted by the Thai government, SEAMEO SEPS
                      commenced operations in November 2019 from its office on
                      the 11th Floor of the Natural Science Building in Bangkok.
                      <br />
                      <br />
                      The Centre is guided by a clear mandate:
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="position-relative d-none d-md-block"
            style={{ marginTop: "-100px" }}
          >
            <img src={footer} className="w-100" />
            <div className="position-absolute absolute-center-center w-100">
              <div className="container">
                <div className="row text-start">
                  <div className="col-12 col-md-12 col-lg-6">
                    <div className="font-60 weight-700 mb-3">
                      <span className="weight-300 me-3">Our</span>VISION
                    </div>
                    <div className="font-20">
                      A Regional Centre of Excellence to promote the Sufficiency
                      Economy Philosophy (SEP) for Sustainable Development
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="position-relative py-5 d-block d-md-none bg-top"
            style={{ backgroundImage: `url(${footer})` }}
          >
            {/* <img src={footer} className="w-100" /> */}
            <div className="w-100">
              <div className="container">
                <div className="row text-start">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="font-60 weight-700 mb-3">
                      <span className="weight-300 me-3">Our</span>VISION
                    </div>
                    <div className="font-20">
                      A Regional Centre of Excellence to promote the Sufficiency
                      Economy Philosophy (SEP) for Sustainable Development
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section
            className="py-md-5 bg-top"
            style={{ backgroundImage: `url(${bgfoot})` }}
          >
            <div className="container py-5">
              <div className="row justify-content-center py-md-5">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="font-60 weight-700 mb-3">
                    <span className="weight-300 me-3">Our</span>MISSION
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 px-5 px-md-0">
                  <div className="row justify-content-center py-md-5 text-start ">
                    <div className="col-12 col-md-4 col-lg-3 mb-3">
                      <div
                        className="position-relative box-mission h-100"
                        style={{}}
                      >
                        <div className="position-absolute icon-mission">
                          <img src={m1} className="w-100" />
                        </div>
                        <div className="py-5 px-4 mission-1" style={{}}>
                          <div className="font-60 weight-700">01</div>
                          <div>
                            Promote the application of SEP to achieve
                            sustainable development
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mb-3">
                      <div
                        className="position-relative box-mission h-100"
                        style={{}}
                      >
                        <div className="position-absolute icon-mission">
                          <img src={m2} className="w-100" />
                        </div>
                        <div className="py-5 px-4 mission-2" style={{}}>
                          <div className="font-60 weight-700">02</div>
                          <div>
                            Establish a learning and knowledge resource centre
                            on SEP
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mb-3">
                      <div
                        className="position-relative box-mission h-100"
                        style={{}}
                      >
                        <div className="position-absolute icon-mission">
                          <img src={m3} className="w-100" />
                        </div>
                        <div className="py-5 px-4 mission-3" style={{}}>
                          <div className="font-60 weight-700">03</div>
                          <div>
                            Consolidate knowledge and share expertise on SEP
                            through research and dialogue
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center text-start ">
                    <div className="col-12 col-md-4 col-lg-3 mb-3">
                      <div
                        className="position-relative box-mission h-100"
                        style={{}}
                      >
                        <div className="position-absolute icon-mission">
                          <img src={m4} className="w-100" />
                        </div>
                        <div className="py-5 px-4 mission-4" style={{}}>
                          <div className="font-60 weight-700">04</div>
                          <div>
                            Promote human resource development based on SEP
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mb-3">
                      <div
                        className="position-relative box-mission h-100"
                        style={{}}
                      >
                        <div className="position-absolute icon-mission">
                          <img src={m5} className="w-100" />
                        </div>
                        <div className="py-5 px-4 mission-5" style={{}}>
                          <div className="font-60 weight-700">05</div>
                          <div>
                            Develop and expand partnerships and linkages on SEP
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="position-relative d-none d-md-block">
            <img src={bgBottom} className="w-100" />
            <div className="position-absolute absolute-center-center w-100">
              <div className="container">
                <div className="row text-center">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="font-60 weight-700 mb-5">
                      <span className="weight-300 me-3">Our</span>GOALS AND
                      ROLES
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          1
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Promote SEP’s principles of moderation, reasonableness and
                      prudence in support of building the 21st Century society
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          2
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Develop and promote the co-creation of knowledge and
                      skills consistent with a balanced development strategy
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          3
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Strengthen cooperation and collaboration among SEAMEO
                      Member Countries
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          4
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Strengthen networks and partnerships
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="position-relative d-block d-md-none"
            style={{ backgroundImage: `url(${bgBottom})` }}
          >
            <div className="w-100">
              <div className="container">
                <div className="row text-center">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="font-60 weight-700 mt-5 mb-5">
                      <span className="weight-300 me-3">Our</span>GOALS AND
                      ROLES
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right mb-5">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          1
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Promote SEP’s principles of moderation, reasonableness and
                      prudence in support of building the 21st Century society
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right mb-5">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          2
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Develop and promote the co-creation of knowledge and
                      skills consistent with a balanced development strategy
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right mb-5">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          3
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Strengthen cooperation and collaboration among SEAMEO
                      Member Countries
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3 border-right mb-5">
                    <div className="mb-2">
                      <span className="box-cicle position-relative">
                        <span className="position-absolute absolute-center-center">
                          4
                        </span>
                      </span>
                    </div>
                    <div className="font-20">
                      Strengthen networks and partnerships
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative">
            <div className="w-100 py-5">
              <div className="container">
                <div className="row text-start align-items-center">
                  <div className="col-12 col-md-5 col-lg-5">
                    <div className="p-5 text-center">
                      <img src={logoSeps1} className="w-75 p-md-5" />
                    </div>
                  </div>
                  <div className="col-12 col-md-7 col-lg-7">
                    <div className="font-60 weight-700 mb-3">
                      <span className="weight-300 me-3">Our</span>LOGO
                    </div>
                    <div className="font-20">
                      The SEAMEO SEPS flower-shaped logo illustrates the close
                      alignment of Sufficiency Economy Philosophy and the United
                      Nations’ 17 Sustainable Development Goals (SDG). The
                      central image honours His Majesty the late King Bhumibol
                      Adulyadej of Thailand (Rama IX) who conceived the
                      Philosophy.  It combines a stylized number 9 from the Thai
                      script, designed in such a way as to resemble the glasses
                      worn by the late King. The colour of each petal connects
                      to a specific SDG.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default CentresOverview;
