import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

import banner from "../../assets/img/banner-web.jpg";
import slide01 from "../../assets/img/slide-01.jpg";
import web01 from "../../assets/img/web-01.jpg";
import web02 from "../../assets/img/web-02.jpg";
import web03 from "../../assets/img/web-03.jpg";

import Loading from "../../components/loading";
import { getData } from "../../services/getService";

const Main = () => {
  // const pr = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [mainData, setMainData] = useState([]);

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      // Promise.all([fetchData()]).then(res => {
      setIsLoading(false);
      // });
    }
    return () => {
      called.current = false;
    };
  }, []);

  // const fetchData = async () => {

  //     getData('upcomingEvent', pr.id).then(
  //         response => {
  //             if (response.status === 'success') {
  //                 setMainData(response.data)
  //             }
  //         }).catch(e => {
  //             console.log(e.response.data.error)
  //         })
  // }

  // console.log(mainData.photo ? JSON.parse(mainData.photo) : null)
  const settings = {
    dots: true,
    infinite: true,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="activities" sub="webinars" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="pb-md-5 bg-top">
            <div className="container-fluid">
              <div className="row justify-content-center pb-md-5 text-start">
                <div className="col-12 col-md-12 col-lg-10 pt-md-5 mt-md-5">
                  {/* {mainData ? */}
                  <div className="pt-4 px-3 px-md-5">
                    <div className="mt-4 ">
                      <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="">
                            <img src={banner} className="w-100 rounded" />
                            {/* <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + mainData.id + '/' + mainData.banner} className="w-100 rounded" /> */}
                          </div>
                          <div className="sub-topic my-5 text-center">
                            SEAMEO SEPS Webinars
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* : null} */}
                </div>
                <div className="col-12 col-md-12 col-lg-12 p-0">
                  <div>
                    <Slider {...settings}>
                      <div className="px-1">
                        <div className="p-4 box-slide">
                          <div className="row align-items-center">
                            <div className="col-12 col-md-4 col-lg-4">
                              <div>
                                <img
                                  src={slide01}
                                  className="w-100 rounded"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-8 col-lg-8">
                              <div className="pt-3 pt-md-0 px-md-5">
                                <div className="sub-topic">
                                  Climate Action and World Heritage
                                </div>
                                <div>
                                  Wed 27 September 2023, 14.00-15.30 hrs
                                  (GMT+7/BKK Time)
                                </div>
                                <div className="mt-5">
                                  <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSdzq9yTMPyXTBsbNEPyB1xA8wF_fwXjODmfgWAo8LzJIVPeLw/viewform"
                                    target="_blank"
                                    className="btn btn-register"
                                  >
                                    Registration
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-1">
                        <div className="p-4 box-slide">
                          <div className="row align-items-center">
                            <div className="col-12 col-md-4 col-lg-4">
                              <div>
                                <img
                                  src={slide01}
                                  className="w-100 rounded"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-8 col-lg-8">
                              <div className="pt-3 pt-md-0 px-md-5">
                                <div className="sub-topic">
                                  Climate Action and World Heritage
                                </div>
                                <div>
                                  Wed 27 September 2023, 14.00-15.30 hrs
                                  (GMT+7/BKK Time)
                                </div>
                                <div className="mt-5">
                                  <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSdzq9yTMPyXTBsbNEPyB1xA8wF_fwXjODmfgWAo8LzJIVPeLw/viewform"
                                    target="_blank"
                                    className="btn btn-register"
                                  >
                                    Registration
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-1">
                        <div className="p-4 box-slide">
                          <div className="row align-items-center">
                            <div className="col-12 col-md-4 col-lg-4">
                              <div>
                                <img
                                  src={slide01}
                                  className="w-100 rounded"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-8 col-lg-8">
                              <div className="pt-3 pt-md-0 px-md-5">
                                <div className="sub-topic">
                                  Climate Action and World Heritage
                                </div>
                                <div>
                                  Wed 27 September 2023, 14.00-15.30 hrs
                                  (GMT+7/BKK Time)
                                </div>
                                <div className="mt-5">
                                  <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSdzq9yTMPyXTBsbNEPyB1xA8wF_fwXjODmfgWAo8LzJIVPeLw/viewform"
                                    target="_blank"
                                    className="btn btn-register"
                                  >
                                    Registration
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 pt-5 mt-md-5">
                  <div className="box-go position-relative px-4 py-3 mt-5 mt-md-0">
                    <div
                      className="news-topic text-white px-2"
                      style={{ lineHeight: "1.3" }}
                    >
                      SEP-SDGs in Action: <br /> Virtual Learning Series
                    </div>
                    <a
                      href="/activities/webinarsDetail"
                      className="go-i position-absolute absolute-center-right weight-700"
                    >
                      GO
                    </a>
                  </div>
                </div>
                <div className="col-11 col-md-12 col-lg-10 pt-5 mt-md-5">
                  <div className="topic mb-4">Previous Webinars</div>
                  <div>
                    <div className="row ">
                      <div className="col-12 col-md-4 col-lg-4 mb-5">
                        <a
                          href="https://www.youtube.com/live/TAziOvsgjFY?feature=shared"
                          target="_blank"
                          className="box-web-col rounded-30 pb-3"
                        >
                          <div>
                            <img src={web01} className="w-100 " />
                          </div>
                          <div className="news-topic p-3">
                            SEP-SDGs in Action: Virtual Learning Series"SEP to
                            SDGs"
                          </div>
                          <div className="pb-5 px-3 mt-2">
                            <span className="btn btn-blue py-2">Watch Now</span>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 mb-5">
                        <a
                          href="https://www.youtube.com/watch?v=PVX7QZB9oYE&t=31s"
                          target="_blank"
                          className="box-web-col rounded-30 pb-3"
                        >
                          <div>
                            <img src={web02} className="w-100 " />
                          </div>
                          <div className="news-topic p-3">
                            SEAMEO SEPS-IVL Webinar on World Environment
                            DayTheme: Beating Plastic Pollution
                          </div>
                          <div className="pb-5 px-3 mt-2">
                            <span className="btn btn-blue py-2">Watch Now</span>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 mb-5">
                        <a
                          href="https://www.youtube.com/live/5tbrQngDyPo?feature=share&t=237"
                          target="_blank"
                          className="box-web-col rounded-30 pb-3"
                        >
                          <div>
                            <img src={web03} className="w-100 " />
                          </div>
                          <div className="news-topic p-3">
                            SEAMEO SEPS Webinar on Sufficiency Economy
                            Philosophy and Sustainability
                          </div>
                          <div className="pb-5 px-3 mt-2">
                            <span className="btn btn-blue py-2">Watch Now</span>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 mb-5">
                        <a
                          href="https://www.youtube.com/watch?v=5tbrQngDyPo&t=237s"
                          target="_blank"
                          className="box-web-col rounded-30 pb-3"
                        >
                          <div>
                            <img src={web03} className="w-100 " />
                          </div>
                          <div className="news-topic p-3">
                            SEAMEO SEPS Webinar on Sufficiency Economy
                            Philosophy and Sustainability
                          </div>
                          <div className="pb-5 px-3 mt-2">
                            <span className="btn btn-blue py-2">Watch Now</span>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 mb-5">
                        <a
                          href="https://www.youtube.com/live/TAziOvsgjFY?feature=shared"
                          target="_blank"
                          className="box-web-col rounded-30 pb-3"
                        >
                          <div>
                            <img src={web01} className="w-100 " />
                          </div>
                          <div className="news-topic p-3">
                            SEP-SDGs in Action: Virtual Learning Series"SEP to
                            SDGs"
                          </div>
                          <div className="pb-5 px-3 mt-2">
                            <span className="btn btn-blue py-2">Watch Now</span>
                          </div>
                        </a>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 mb-5">
                        <a
                          href="https://www.youtube.com/watch?v=PVX7QZB9oYE&t=31s"
                          target="_blank"
                          className="box-web-col rounded-30 pb-3"
                        >
                          <div>
                            <img src={web02} className="w-100 " />
                          </div>
                          <div className="news-topic p-3">
                            SEAMEO SEPS-IVL Webinar on World Environment
                            DayTheme: Beating Plastic Pollution
                          </div>
                          <div className="pb-5 px-3 mt-2">
                            <span className="btn btn-blue py-2">Watch Now</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Main;
