import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import sorn from "../assets/img/icon/sorn.svg";

const Modalgallery = ({ dataUrl, ...datat }) => {
  const handleDragStart = (e) => e.preventDefault();

  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 1 },
  };

  console.log(datat);

  return (
    <>
      <Modal
        {...datat}
        className="modal-gallery"
        size="lg"
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white" className="px-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 ">
          <div className="d-block w-100 scroll-custom">
            <div className="h-100per-modal">
              {/* <div className=''>
                                <img src="http://localhost/wedding-service/uploads/reviews/27/1660893977a.jpg" alt="" className="w-100" />
                            </div> */}
              <div className="gallery-full">
                {/* <div className=''>
                                <img src={dataUrl} alt="" className="w-100" />
                            </div> */}
                <AliceCarousel
                  mouseTracking
                  responsive={responsive}
                  infinite
                  controlsStrategy="alternate"
                  disableDotsControls
                  // autoHeight
                  activeIndex={datat.active}
                  renderPrevButton={() => {
                    return (
                      <p className="" style={{ left: "30px" }}>
                        <img src={sorn} className="sorn-left" />
                      </p>
                    );
                  }}
                  renderNextButton={() => {
                    return (
                      <p className="" style={{ right: "30px" }}>
                        <img src={sorn} className="sorn-right" />
                      </p>
                    );
                  }}
                >
                  {datat.gallery.map((value, index) => (
                    <img
                      src={
                        process.env.REACT_APP_IMG_LINK +
                        "/uploads/" +
                        datat.folder +
                        "/" +
                        datat.id +
                        "/" +
                        value
                      }
                      onDragStart={handleDragStart}
                      className="w-100"
                      key={index}
                    />
                  ))}
                </AliceCarousel>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Modalgallery;
