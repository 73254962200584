import React, { useState, useEffect, useRef } from "react";
// import { useParams, Link } from "react-router-dom";
// import { Accordion } from 'react-bootstrap';

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// import banner from "../../../assets/img/banner-wasteHero.png";
import img001 from "../../../assets/img/Main Web Banner_2024 Waste Hero School Competition1.jpg";
// import img002 from "../../../assets/img/hero_02.jpg";
// import img01 from "../../../assets/img/hero_1.jpg";
// import img02 from "../../../assets/img/hero_2.jpg";
// import img03 from "../../../assets/img/hero_3.jpg";
// import vector1 from "../../../assets/img/icon/vector_1.svg";
// import vector2 from "../../../assets/img/icon/vector_2.svg";
// import vector3 from "../../../assets/img/icon/vector_3.svg";
// import vector4 from "../../../assets/img/icon/vector_4.svg";
// import vector5 from "../../../assets/img/icon/vector_5.svg";
// import vector6 from "../../../assets/img/icon/Malaysia.svg";

import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "promotion")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="partnership" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="">
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                                    <div className="font-50 weight-700">{dataOne.topic}</div>
                                    <div className="mb-5 pt-3">
                                        <div className="font-18 text-justify content-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: dataOne.detail,
                                            }}
                                        />
                                    </div>                                    
                                   
                                </div>

                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="partnership" />
                </div>
            </div>
        </>
    );
};

export default Main;
