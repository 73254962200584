import React, { useState, useEffect, useRef } from "react";

import { useParams, Link } from "react-router-dom";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";


import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

import banner from "../../../assets/img/banner-information.jpg";
import imgContent from "../../../assets/img/content-web.jpg";
import web01 from "../../../assets/img/webi-01.jpg";
import web02 from "../../../assets/img/webi-02.jpg";
import web03 from "../../../assets/img/webi-03.jpg";
import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});
    const [dataTwo, setDataTwo] = useState({});
    const [dataThree, setDataThree] = useState([]);
    const [dataNews, setDataNews] = useState([]);

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne(), fetchDataTwo(), fetchDataThree()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "virtual")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };
    const fetchDataTwo = async () => {
        getData("pageawards", "upcoming")
            .then((response) => {
                if (response.status === "success") {
                    setDataTwo(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };
    const fetchDataThree = async () => {
        getData("pageawards", "previous")
            .then((response) => {
                if (response.status === "success") {
                    setDataThree(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };


    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="information" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="">
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100 rounded" alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                                    <div className="font-60 weight-700">{dataOne.topic}</div>
                                    <div className="mb-md-5 pt-3">
                                        <div className="font-18 text-justify content-custom"
                                            dangerouslySetInnerHTML={{
                                                __html: dataOne.detail,
                                            }}
                                        />
                                    </div>

                                    {/* <div className="row justify-content-center py-5 mb-md-5 text-start">
                                        <div className="col-12 col-md-8 col-lg-6">
                                            <div className="position-relative px-4 py-3 mt-3 mt-md-5 btn-delay">
                                                <div
                                                    className="news-topic text-white px-2 res-font-14"
                                                    style={{ lineHeight: "1.3" }}
                                                >
                                                    SEP-SDGs in Action: <br /> Virtual Learning Series
                                                </div>
                                                <a
                                                    href="/activities/webinarsDetail"
                                                    className="go-see position-absolute absolute-center-right weight-700 font-20"
                                                >
                                                    See Details
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                    {dataTwo && <>
                                        <div className="font-60 weight-700 mt-3">Upcoming Webinars</div>
                                        <div className="box-archives mt-5" style={{ background: '#F5F5F5', boxShadow: '0px 20px 30px 0px rgba(0, 0, 0, 0.10)' }}>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="p-4">
                                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataTwo.id + '/' + dataTwo.img} className="w-100 rounded" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <div className="pb-5 pb-md-0 pt-3 pt-md-3 px-4 px-md-5">
                                                        <div className="sub-topic">
                                                            {dataTwo.topic}
                                                        </div>
                                                        <div className="font-14">
                                                            {dataTwo.subtopic}
                                                        </div>
                                                        <div className="mt-3">
                                                            <div className="text-justify"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: dataTwo.detail,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="mt-5">
                                                            <a
                                                                href={dataTwo.detailSub}
                                                                target="_blank"
                                                                className="btn btn-register"
                                                            >
                                                                Registration
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}

                                    <div className="topic mb-3 mt-5 pt-5">Previous Activities</div>
                                    <div>
                                        <a href="/newsandgallery/archives/1" className="btn btn-blue-passtel font-16 px-5 py-2">2023</a>
                                    </div>

                                    {/* <div className="topic mb-4 mt-5 pt-5">Previous Webinars</div>
                                    <div>
                                        <div className="row ">
                                            {dataThree.length ? dataThree.sort((a, b) => parseInt(b.id) - parseInt(a.id)).map((val, index) => (
                                                <div className="col-12 col-md-4 col-lg-4 mb-5" key={index}>
                                                    <span

                                                        className="box-web-col rounded-30 pb-3 overflow-hidden cursor-pointer"
                                                    >
                                                        <div>
                                                            <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + val.id + '/' + val.img} className="w-100" alt="" />
                                                        </div>
                                                        <div className="news-topic p-3">
                                                            {val.topic}
                                                        </div>
                                                        <div className="pb-4 px-3 mt-4 text-center">
                                                            <div className="row ">
                                                                {val.subtopic &&
                                                                    <div className="col-6 col-md-6 pe-1">
                                                                        <a className="btn btn-about text-white font-16 w-100 px-0" href={val.subtopic} target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px' }}>Details</a>
                                                                    </div>
                                                                }
                                                                <div className="col-6 col-md-6 ps-1">
                                                                    <a className="btn btn-blue font-16 w-100 px-0" href={val.detailSub} target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px' }}>Watch</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            )) : <div className="col-12 col-md-12 col-lg-12 mb-5">-- No data --</div>}

                                        </div>
                                    </div> */}
                                    {/* {dataOne.news && dataOne.news.length ?
                                        <div>
                                            <div className="topic mb-4 mt-5">News</div>
                                            <div className="row">
                                                
                                                {dataOne.news.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => (
                                                    <div
                                                        className="col-12 col-md-12 col-lg-4"
                                                        key={index}
                                                    >
                                                        <Link
                                                            to={"/newsandgallery/news/" + val.newsId}
                                                            className="a-news pb-4 mb-4 mb-md-5"
                                                        >
                                                            <div className="row text-start">
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_IMG_LINK +
                                                                                "/uploads/news/" +
                                                                                val.newsId +
                                                                                "/" +
                                                                                val.banner
                                                                            }
                                                                            className="w-100 rounded"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                    <div className="font-14 mt-2">
                                                                        {moment(val.dateActive).format("D MMMM Y")}
                                                                    </div>

                                                                    <div className="news-topic mt-3 mt-md-0"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: val.topic,
                                                                        }}
                                                                    />
                                                                 
                                                                    <div className="mt-4">
                                                                        <LinesEllipsis
                                                                            text={val.subTopic}
                                                                            maxLine="3"
                                                                            ellipsis="..."
                                                                            trimRight
                                                                            basedOn="letters"
                                                                        />
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <a style={{ color: "#e6c12f" }}>
                                                                            Read More
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        : null} */}
                                </div>

                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="information" />
                </div>
            </div>
        </>
    );
};

export default Main;
