import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";

import { getDataPagination } from "../../services/getService";

import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Loading from "../../components/loading";
import TagView from "../../components/tagview";

import nextIcon from "../../assets/img/icon/sorn.svg";
import searchIcon from "../../assets/img/icon/Search.svg";
import closeIcon from "../../assets/img/icon/Close.svg";

import Modalgallery from "../../components/modalGallery";

const Home = () => {
  // const handleDragStart = (e) => e.preventDefault();

  // const responsive = {
  //     0: { items: 1.2 },
  //     568: { items: 2 },
  //     1024: { items: 5 },
  // };

  const [isLoading, setIsLoading] = useState(true);
  // const [partnersData, setPartnersData] = useState([]);
  const [newsData, setNewsData] = useState([]);

  const [filteredList, setFilteredList] = useState([]);
  const [pagination, setPagination] = useState({ pager: "", link: [] });
  const [keySearch, setKeySearch] = useState("");
  const [toggle, setToggle] = useState(false);

  const [modalgallery, setModalgallery] = useState({ view: false });

  const [more, setMore] = useState(6);

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchDataNews(1, "")]).then((res) => {
        setIsLoading(false);
      });
    }
    return () => {
      called.current = false;
    };
  }, []);

  const fetchDataNews = async (page, keySearch) => {
    getDataPagination(page, keySearch, "newsPagination")
      .then((response) => {
        if (response.status === "success") {
          setNewsData(response.data);
          setFilteredList(response.data);
          // setPagination({
          //   pager: response.data.pager,
          //   link: response.data.links,
          // });
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const handlePagination = (page) => {
    fetchDataNews(page, keySearch);
  };

  const filterBySearch = (event) => {
    const query = event.target.value;
    setKeySearch(query);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const dataForm = new FormData(event.target);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      // $('html, body').animate({
      //     scrollTop: $(errorElements[0]).offset().top
      //   }, 2000);
    } else {
      // alert()
      fetchDataNews(1, keySearch);
    }
  };

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="news" sub="gallery" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}

          <section className="my-5" id="page-wrap">
            <div className="container-fluid pb-5 px-lg-5">
              <div className="row text-start justify-content-center">
                <div className="col-12 col-md-11 col-lg-11">
                  <div className="row align-items-center mb-3 mb-md-5 mt-lg-5">
                    <div className="col-12 col-md-6 col-lg-6">
                    <div className="font-60 weight-700">Gallery</div>
                    </div>
                  </div>
                  <div className="row">
                    {newsData.length
                      ? newsData.slice(0, more).map((val, index) => (
                          <div
                            className="col-12 col-md-12 col-lg-4"
                            key={index}
                          >
                            <a
                              href={"/newsandgallery/gallery/" + val.id}
                              className="a-news pb-4 mb-4 mb-md-5 cursor-pointer"
                            >
                              <div
                                className="rounded position-relative"
                                
                              >
                                <div>
                                  <img
                                    src={
                                      process.env.REACT_APP_IMG_LINK +
                                      "/uploads/news/" +
                                      val.id +
                                      "/" +
                                      val.banner
                                    }
                                    className="w-100 rounded"
                                  />
                                </div>
                                {/* <div
                                  className="position-absolute"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    background: "rgba(0,0,0,.5)",
                                  }}
                                ></div>
                                <div
                                  className="position-absolute px-2"
                                  style={{
                                    right: "15px",
                                    top: "5px",
                                  }}
                                >
                                  <div
                                    className="font-14 mt-2"
                                    style={{ color: "#fff" }}
                                  >
                                    {moment(val.dateActive).format("D MMMM Y")}
                                  </div>
                                </div> */}
                                {/* <div
                                  className="position-absolute px-2"
                                  style={{
                                    right: "15px",
                                    bottom: "5px",
                                  }}
                                >
                                  <div
                                    className="font-18 text-center"
                                    style={{ color: "#fff" }}
                                  >
                                    {val.topic}
                                  </div>
                                </div> */}
                              </div>
                              <div className="font-14 mt-2">
                                {moment(val.dateActive).format("D MMMM Y")}
                              </div>
                              <div className="news-topic mt-3 mt-md-0">
                                {val.topic}
                              </div>
                              {/* <div className="mt-4">
                                    <LinesEllipsis
                                      text={val.subTopic}
                                      maxLine="3"
                                      ellipsis="..."
                                      trimRight
                                      basedOn="letters"
                                    />
                                  </div> */}
                            </a>
                          </div>
                        ))
                      : null}
                  </div>
                  {more === 6 && (
                    <div
                      className="text-center cursor-pointer font-18 weight-600 mt-5"
                      onClick={(e) => setMore(1000)}
                    >
                      Load More{" "}
                      <img
                        src={nextIcon}
                        className="ms-2"
                        style={{ transform: "rotate(90deg)", width: "8px" }}
                      />
                    </div>
                  )}
                  {/* <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 mb-3 mb-md-5">
                      <div className="topic mb-4 mt-lg-5">
                        <nav
                          aria-label="Page navigation example"
                          className="text-center pt-4"
                        >
                          <ul className="pagination d-inline-flex">
                            <li
                              className={
                                pagination.pager.previous
                                  ? "page-item"
                                  : "page-item disabled"
                              }
                            >
                              <a
                                className="page-link cursor-pointer"
                                onClick={(e) =>
                                  handlePagination(pagination.pager.first)
                                }
                                aria-label="First"
                              >
                                <span aria-hidden="true">
                                  <img src={nextIcon} className="rotate-180" />
                                  <img src={nextIcon} className="rotate-180" />
                                </span>
                              </a>
                            </li>
                            <li
                              className={
                                pagination.pager.previous
                                  ? "page-item me-4"
                                  : "page-item me-4 disabled"
                              }
                            >
                              <a
                                className="page-link cursor-pointer"
                                onClick={(e) =>
                                  handlePagination(pagination.pager.previous)
                                }
                                aria-label="Previous"
                              >
                                <span aria-hidden="true">
                                  <img src={nextIcon} className="rotate-180" />
                                </span>
                              </a>
                            </li>
                            {pagination.link.map((val, index) => (
                              <li
                                key={index}
                                className={
                                  val.active ? "page-item active" : "page-item"
                                }
                              >
                                <a
                                  className="page-link cursor-pointer"
                                  onClick={(e) => handlePagination(val.page)}
                                >
                                  {val.page}
                                </a>
                              </li>
                            ))}
                            <li
                              className={
                                pagination.pager.next
                                  ? "page-item ms-4"
                                  : "page-item ms-4 disabled"
                              }
                            >
                              <a
                                className="page-link cursor-pointer"
                                onClick={(e) =>
                                  handlePagination(pagination.pager.next)
                                }
                                aria-label="Next"
                              >
                                <span aria-hidden="true">
                                  <img src={nextIcon} className="" />
                                </span>
                              </a>
                            </li>
                            <li
                              className={
                                pagination.pager.next
                                  ? "page-item"
                                  : "page-item disabled"
                              }
                            >
                              <a
                                className="page-link cursor-pointer"
                                onClick={(e) =>
                                  handlePagination(pagination.pager.last)
                                }
                                aria-label="Next"
                              >
                                <span aria-hidden="true">
                                  <img src={nextIcon} className="" />
                                  <img src={nextIcon} className="" />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <Footer page="news" sub="gallery"/>
        </div>
      </div>
      {modalgallery.view ? (
        <Modalgallery
          show={modalgallery.view}
          onHide={() => setModalgallery({ view: false })}
          dataUrl={modalgallery.dataUrl}
          folder={"news"}
          gallery={modalgallery.gallery}
          active={modalgallery.active}
          id={modalgallery.id}
        />
      ) : null}
    </>
  );
};

export default Home;
