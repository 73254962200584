import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';

import Header from '../../layout/header';
import Footer from '../../layout/footer';


import img from '../../assets/img/img.png';

import content1 from '../../assets/img/content1.png';
import doc from '../../assets/img/icon/Document.svg';
import vdocall from '../../assets/img/icon/VideoCall.svg';

import Loading from "../../components/loading";
import { getData } from '../../services/getService';

const TrainingProgrammes = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(true)

    const [mainData, setMainData] = useState([]);

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false)
            });
        }
        return () => {
            called.current = false
        }
    }, []);

    const fetchData = async () => {

        getData('activity', pr.id).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    console.log(mainData.photo ? JSON.parse(mainData.photo) : null)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="training" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start" style={{ backgroundColor: '#F5F5F5' }}>
                                <div className="col-12 col-md-12 col-lg-11 pb-5 pt-5 mt-md-5">
                                    {mainData ?
                                        <div className='board-01 py-4 px-3 px-md-5'>
                                            <div className=''>
                                                <Link to="/activities/trainingProgrammes" className='btn btn-default weight-600 px-0'>CENTRE'S ACTIVITIES</Link>
                                                <span className='mx-3'>|</span>
                                                <span className='text-decoration-underline'>
                                                    {mainData.topic}
                                                </span>
                                                <div className='float-lg-end mt-3'>
                                                    <Link to="/" className='btn btn-register'>
                                                        Register
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='mt-5 px-lg-3 pb-5'>
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-5">
                                                        <div className=''>
                                                            {/* <img src={content1} className="w-100 rounded-30" /> */}
                                                            <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + mainData.id + '/' + mainData.banner} className="w-100 rounded-30" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-7">
                                                        <div className='py-5 px-lg-5' style={{}}>
                                                            <div className='font-32 weight-700'>
                                                                {mainData.topic}
                                                            </div>
                                                            <div className='mt-2 color-gray font-18'>
                                                                {mainData.dateText}
                                                            </div>
                                                            <div className='mt-5 font-18' style={{ lineHeight: '2' }}>
                                                                <div dangerouslySetInnerHTML={{ __html: mainData.detail }} />
                                                            </div>
                                                            <div className='my-5 pb-5'>
                                                                {mainData.linkDoc ?
                                                                    <a href={mainData.linkDoc} target={'_blank'} className='btn btn-doc'>
                                                                        <img src={doc} className="p-1" style={{ height: "30px" }} /> Document
                                                                    </a>
                                                                    : null}
                                                                {mainData.linkVdo ?
                                                                    <a href={mainData.linkVdo} target={'_blank'} className='btn btn-vdocall ms-md-3 mt-3 mt-md-0'>
                                                                        <img src={vdocall} className="p-1" style={{ height: "30px" }} /> Video Call
                                                                    </a>
                                                                    : null}
                                                            </div>
                                                            {mainData.photo ? JSON.parse(mainData.photo).length ?
                                                                <div className='mt-5 pt-5'>
                                                                    <div className='font-28 weight-700'>Photos</div>
                                                                    <div className='mt-3 row px-2'>
                                                                        {JSON.parse(mainData.photo).map((valx, indexx) => (
                                                                            <div className="col-6 col-md-3 col-lg-2 p-1">
                                                                                <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + mainData.id + '/' + valx} className="w-100" />
                                                                            </div>
                                                                        ))
                                                                        }

                                                                    </div>
                                                                </div>
                                                                : '' : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer />
                </div>
            </div>

        </>
    );
};

export default TrainingProgrammes;