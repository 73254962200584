import React, { useEffect, useRef, useState } from "react";
// import { useParams, Link } from "react-router-dom";
import { Animated } from "react-animated-css";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

// import bg1 from "../../assets/img/about-bg.png";
import content1 from "../../assets/img/content-concept.jpg";
import content from "../../assets/img/concept/content-concept-2.png";
// import bgfoot from "../../assets/img/about-bg-foot.png";
// import bgBottom from "../../assets/img/about-bg-bottom.jpg";

import m1 from "../../assets/img/concept/concept_1.png";
import m2 from "../../assets/img/concept/concept_2.png";
import m3 from "../../assets/img/concept/concept_3.png";
import m4 from "../../assets/img/concept/concept_4.png";
import m5 from "../../assets/img/concept/concept_5.png";
import m6 from "../../assets/img/concept/concept_6.png";
import m7 from "../../assets/img/concept/concept_7.png";
import m8 from "../../assets/img/concept/concept_8.png";

const CentresOverview = () => {

  const ref01 = useRef(null);
  const ref02 = useRef(null);
  const ref03 = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  // const handleDragStart = (e) => e.preventDefault();

  // const responsive = {
  //     0: { items: 1.2 },
  //     568: { items: 2 },
  //     1024: { items: 5 },
  // };

  useEffect(() => {
    // fetchData(0)
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //   const handleScroll = (e) => {
  //     console.log(e)
  //     const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //     if (bottom) { 
  //         console.log("bottom")
  //     }
  //  }

  const handleScroll = () => {

    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    if (bottom) {
      console.log('at the bottom');
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  };



  const handleClick = (id) => {

    id === 1 ?
      ref01.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
      : id === 2 ?
        ref02.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
        :
        ref03.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
  };

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="about" sub="sepconcept" />
        <div className="wrapper bg-white box-index menuleft-margin">


          <div className={isLoading ? "menu-about-left text-start block-effect" : "menu-about-left text-start"}>
            <ul className="align-items-center">
              <li className="" onClick={e => handleClick(1)}>
                <div className="font-38 weight-600 topic-res-menu" style={{ lineHeight: '1' }}>01</div>
                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu">The Evolution <br className="d-none d-md-block" />of SEP</div>
              </li>
              <li className="" onClick={e => handleClick(2)}>
                <div className="font-38 weight-600 topic-res-menu" style={{ lineHeight: '1' }}>02</div>
                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu">The SEP Concept</div>
              </li>
              <li onClick={e => handleClick(3)}>
                <div className="font-38 weight-600 topic-res-menu" style={{ lineHeight: '1' }}>03</div>
                <div className="font-20 pb-2 weight-300 pe-3 text-res-menu">Model of<br className="d-none d-md-block" />the SEP Concept</div>
              </li>

            </ul>
          </div>

          <div className="position-relative mt-5" >
            <div className="w-100">
              <div className="container top-about-line">
                <div className="row text-start justify-content-center" >
                  <div className="col-12 col-md-12 col-lg-10">
                    <div className="font-60 font-res-80 weight-700">
                      SEP Concept
                    </div>
                    <div className='sub-topic' style={{ scrollMarginTop: '150px' }} ref={ref01}>The Evolution of SEP</div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row text-start justify-content-center" >
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="position-relative mt-5 mx-5">
                      <img src={content} className="w-100" alt="" />
                      <div className="position-absolute w-100" style={{ left: 0, top: '54%', transform: 'translate(0%,-50%)', height: '70px' }}>
                        <div className="h-100" style={{ padding: '0 120px', marginRight: '25px' }}>
                          <ul className="ul-concept h-100">
                            <li className="concept-li-1">
                              <div className="concept-full concept-full-1 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m1} className="w-100" alt=""/>
                              </div>
                            </li>
                            <li className="concept-li-2">
                              <div className="concept-full concept-full-2 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m2} className="w-100" alt=""/>
                              </div>
                            </li>
                            <li className="concept-li-3">
                              <div className="concept-full concept-full-3 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m3} className="w-100" alt=""/>
                              </div>
                            </li>
                            <li className="concept-li-4">
                              <div className="concept-full concept-full-4 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m4} className="w-100" alt=""/>
                              </div>
                            </li>
                            <li className="concept-li-5">
                              <div className="concept-full concept-full-5 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m5} className="w-100" alt=""/>
                              </div>
                            </li>
                            <li className="concept-li-6">
                              <div className="concept-full concept-full-6 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m6} className="w-100" alt=""/>
                              </div>
                            </li>
                            <li className="concept-li-7">
                              <div className="concept-full concept-full-7 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m7} className="w-100" alt=""/>
                              </div>
                            </li>
                            <li className="concept-li-8">
                              <div className="concept-full concept-full-8 position-absolute w-100" style={{ top: '50%', transform: 'translate(0%,-50%)', left: 0 }}>
                                <img src={m8} className="w-100" alt=""/>
                              </div>
                            </li>
                           
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row text-start justify-content-center" >
                  <div className="col-12 col-md-12 col-lg-10">

                    <div className='sub-topic mt-5' style={{ scrollMarginTop: '150px' }} ref={ref02}>The SEP Concept</div>
                    <div className="text-justify content-custom">
                      Sufficiency Economy Philosophy (SEP), a legacy of His Majesty the late King Bhumibol Adulyadej of Thailand, provides a people-centred framework for the achievement of sustainable development. SEP focuses on restoring balance across the economy, society, environment and culture; strengthening capacity to give people greater control of their lives; and building resilience to changing circumstances and unforeseen events in today’s increasingly complex world. To achieve these goals, SEP nurtures a SEP Mindset that builds on the existing knowledge base, cultivates virtues and guides the decision-making process through the application of the core principles of moderation, reasonableness and prudence across all aspects of life.
                      <br />
                      <br />

                      Since no two individuals, families or communities are alike, SEP offers no one-size fits-all, fixed formula. SEP is instead responsive and flexible and can be applied in many ways to suit a variety of contexts, conditions, circumstances, communities and cultures. In Thailand, the Philosophy has been tried and tested at the local level and mainstreamed as the vehicle to drive sustainability in national policy since 2002.  SEP is also Thailand’s contribution to the global sustainable development agenda. Beyond Thailand’s borders, SEP may complement or bolster the strategies adopted in other countries. Exploring SEP’s similarities with other approaches will enable SEAMEO SEPS to showcase good role models and change-makers in the region, thereby strengthening Southeast Asia’s knowledge base on sustainability and facilitating the establishment of collaborative regional networks.
                    </div>
                    <div className='sub-topic mt-5 pt-5' style={{ scrollMarginTop: '100px' }} ref={ref03}>Model of the SEP Concept</div>
                    <div className="weight-400">Designed by SEAMEO SEPS</div>
                    <div className="py-5 p-md-5">
                      <img src={content1} className="w-100 px-5" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer page="about" sub="sepconcept" />
        </div>
      </div>
    </>
  );
};

export default CentresOverview;
