import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import backIcon from '../../assets/img/icon/back.svg';

import Loading from "../../components/loading";
import { getData } from '../../services/getService';

const Main = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(true)

    const [mainData, setMainData] = useState([]);

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false)
            });
        }
        return () => {
            called.current = false
        }
    }, []);

    const fetchData = async () => {

        getData('upcomingEvent', pr.id).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    // console.log(mainData.photo ? JSON.parse(mainData.photo) : null)

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="activities" sub="upcoming" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-10 pb-5 pt-5 mt-md-5">
                                    {mainData ?
                                        <div className='board-01 py-4 px-3 px-md-5'>
                                            <div className='navigate'>
                                                <Link to="/activities/upcomingEvent" className='btn btn-default weight-400 px-0'>Upcoming Events</Link>
                                                <span className='mx-3'>|</span>
                                                <span className='text-decoration-underline'>
                                                    {mainData.topic}
                                                </span>
                                                <div className='float-lg-end'>
                                                    <Link to="/activities/upcomingEvent" className='btn btn-back weight-700 ps-0'>
                                                        <img src={backIcon} style={{ width: '30px' }} /> Back
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='mt-4 pb-5'>
                                                <div className="row">
                                                    {mainData.banner &&
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className=''>
                                                                <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + mainData.id + '/' + mainData.banner} className="w-100 rounded" />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className='pt-3 pb-5' style={{}}>
                                                            <div className='font-32 weight-700'>
                                                                {mainData.topic}
                                                            </div>
                                                            <div className='mt-2 font-18'>
                                                                {mainData.dateText}
                                                            </div>
                                                            <div className='font-18 mb-4'>
                                                                {mainData.subTopic}
                                                            </div>
                                                            <hr />
                                                            <div className='mt-4 font-18 content-custom'>
                                                                <div dangerouslySetInnerHTML={{ __html: mainData.detail }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer page="activities" sub="upcoming" />
                </div>
            </div>

        </>
    );
};

export default Main;