import React, { useState, useEffect, useRef, useCallback } from "react";
// import { useParams, Link } from "react-router-dom";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

// import bg1 from "../../assets/img/bg-sufficiency.png";
import content from "../../assets/img/content-sufficiency.png";
// import footer from "../../assets/img/about-footer.png";
// import bgfoot from "../../assets/img/about-bg-foot.png";
// import bgBottom from "../../assets/img/about-bg-bottom.jpg";

// import m1 from "../../assets/img/icon/Mission-01.svg";
// import m2 from "../../assets/img/icon/Mission-02.svg";
// import m3 from "../../assets/img/icon/Mission-03.svg";
// import m4 from "../../assets/img/icon/Mission-04.svg";
// import m5 from "../../assets/img/icon/Mission-05.svg";

const SufficiencyEconomy = () => {
  const handleDragStart = (e) => e.preventDefault();

  const responsive = {
    0: { items: 1.2 },
    568: { items: 2 },
    1024: { items: 5 },
  };

  useEffect(() => {
    // fetchData(0)
  }, []);

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="about" sub="sufficiencyEconomy" />
        <div className="wrapper bg-white box-index">
          <div className="position-relative bg-top" style={{ zIndex: "1" }}>
            <img src={content} className="w-100 mt-5 mt-md-0 pt-5 pt-md-0" />
            <div className="w-100 py-3 py-md-5 position-absolute absolute-center top-0">
              <div className="container pb-md-5 w-100">
                <div className="row align-items-center justify-content-center text-start">
                  <div className="col-12 col-md-12 col-lg-10">
                    <div className="text-center pt-md-5">
                      <div className="font-30 weight-700">
                        Sufficiency Economy Philosophy
                      </div>
                      <div className="font-100 weight-700">SEP</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative pb-5 mb-5">
            <div className="w-100">
              <div className="container">
                <div className="row text-center">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="font-60 weight-700 mb-4">
                      The SEP Concept
                    </div>
                    <div className="font-20">
                      Sufficiency Economy Philosophy (SEP), a legacy of His
                      Majesty the late King Bhumibol Adulyadej of Thailand,
                      provides a people-centred framework for the achievement of
                      sustainable development. SEP focuses on restoring balance
                      across the economy, society, environment and culture;
                      strengthening capacity to give people greater control of
                      their lives; and building resilience to changing
                      circumstances and unforeseen events in today’s
                      increasingly complex world. To achieve these goals, SEP
                      nurtures a SEP Mindset that builds on the existing
                      knowledge base, cultivates virtues and guides the
                      decision-making process through the application of the
                      core principles of moderation, reasonableness and prudence
                      across all aspects of life.
                      <br />
                      <br />
                      Since no two individuals, families or communities are
                      alike, SEP offers no one-size fits-all, fixed formula. SEP
                      is instead responsive and flexible and can be applied in
                      many ways to suit a variety of contexts, conditions,
                      circumstances, communities and cultures. In Thailand, the
                      Philosophy has been tried and tested at the local level
                      and mainstreamed as the vehicle to drive sustainability in
                      national policy since 2002.  SEP is also Thailand’s
                      contribution to the global sustainable development agenda.
                      Beyond Thailand’s borders, SEP may complement or bolster
                      the strategies adopted in other countries. Exploring SEP’s
                      similarities with other approaches will enable SEAMEO SEPS
                      to showcase good role models and change-makers in the
                      region, thereby strengthening Southeast Asia’s knowledge
                      base on sustainability and facilitating the establishment
                      of collaborative regional networks.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer page="about" sub="sufficiencyEconomy"/>
        </div>
      </div>
    </>
  );
};

export default SufficiencyEconomy;
