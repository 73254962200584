import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link } from "react-router-dom";

import moment from "moment";

import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import nextIcon from "../../assets/img/icon/sorn.svg";

import Loading from "../../components/loading";
import YouTubeView from "../../components/viewYoutube";
import { getData } from "../../services/getService";

const Main = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [mainData, setMainData] = useState([]);
  const [dataOne, setDataOne] = useState({});

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      Promise.all([fetchData(), fetchDataOne()]).then((res) => {
        setIsLoading(false);
      });
    }
    return () => {
      called.current = false;
    };
  }, []);

  const fetchData = async () => {
    getData("decoding")
      .then((response) => {
        if (response.status === "success") {
          setMainData(response.data);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const fetchDataOne = async () => {
    getData("bannerpage", "decoding")
      .then((response) => {
        if (response.status === "success") {
          setDataOne(response.data)
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
      });
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      // <div
      //   className={className}
      //   style={{ ...style, display: "block", background: "red" }}
      //   onClick={onClick}
      // />
      <div className={className}>
        <img
          src={nextIcon}
          className="ms-2"
          style={{ width: "12px", marginTop: "-20px" }}
          onClick={onClick}
        />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      // <div
      //   className={className}
      //   style={{ ...style, display: "block", background: "green" }}
      //   onClick={onClick}
      // />
      <div className={className}>
        <img
          src={nextIcon}
          className="me-2"
          style={{
            transform: "rotate(180deg)",
            width: "12px",
            marginTop: "-20px",
          }}
          onClick={onClick}
        />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    // className: "center",
    // centerMode: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="knowledge" sub="decoding" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="bg-top">
            <div className="container-fluid">
              <div className="row justify-content-center pb-md-5 text-start">
                {dataOne.img &&
                  <div className="col-12 col-md-12 col-lg-12 p-0">
                    <div>
                      <img
                        src={
                          process.env.REACT_APP_IMG_LINK +
                          "/uploads/bannerpage/" +
                          dataOne.id +
                          "/" +
                          dataOne.img
                        }
                        className="w-100"
                        alt=""
                      />
                    </div>
                  </div>
                }
                 <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                  {/* {mainData ? */}

                    <div className="pb-5">
                      <div className="font-60 weight-700" style={{ lineHeight: '1.3' }}>
                        {dataOne.topic}
                      </div>
                      <div className="mt-3 sub-topic">
                        {dataOne.subtopic}
                      </div>
                      <div className="mt-3">
                        <div className="font-18 text-justify content-custom"
                          dangerouslySetInnerHTML={{
                            __html: dataOne.detail,
                          }}
                        />
                      </div>
                    </div>
                    {mainData.length ? (
                      mainData.map((val, index) =>
                        val.data.length ? (
                          <div className="box-decoding-list" key={index}>
                            <div className="row">
                              <div className="col-12 col-md-12 col-lg-12 mb-5 mb-md-0">
                                <div className="mb-4 ms-1">
                                  <div className="sub-topic">{val.topic}</div>
                                  <div>{val.data.length} Episodes</div>
                                </div>
                                <div className="box-slide-detail">
                                  <Slider {...settings}>
                                    {val.data.map((vall, indexx) => (
                                      <div className="px-2" key={indexx}>
                                        <div className="">
                                          <YouTubeView
                                            youtubeId={vall.youtubeId}
                                          />
                                        </div>
                                        <div className="font-20 weight-700 mt-2">
                                          {vall.topic}
                                        </div>
                                      </div>
                                    ))
                                    }
                                  </Slider>
                                </div>

                                {/* {val.data.map((vall, indexx) => (
                                  <div
                                    className="ps-4 ps-md-5 position-relative mb-4"
                                    key={indexx}
                                  >
                                    <div className="absolute-center-left font-24 weight-700">
                                      {indexx + 1}
                                    </div>
                                    <div className="row">
                                      <div className="col-12 col-md-6 col-lg-6">
                                        <div>
                                          <YouTubeView
                                            youtubeId={vall.youtubeId}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6 col-lg-6">
                                        <div className="news-topic">
                                          {vall.topic} | {val.topic}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))} */}
                              </div>
                            </div>
                          </div>
                        ) : null
                      )
                    ) : (
                      <div>-- No data -- </div>
                    )}
                  {/* : null} */}
                </div>
              </div>
            </div>
          </section>

          <Footer page="knowledge" sub="decoding" />
        </div>
      </div>
    </>
  );
};

export default Main;
