import React, { useState, useEffect, useRef } from 'react';
// import { useParams, Link } from 'react-router-dom';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import footer from "../../assets/img/bg-or.png";

import Loading from "../../components/loading";
import { getData } from "../../services/getService";


const CentresGoverningBoard = () => {

    const [isLoading, setIsLoading] = useState(true)

    const [mainDataOne, setMainDataOne] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [dataTwo, setDataTwo] = useState([]);

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            Promise.all([fetchData(), fetchDataTwo()]).then(res => {
                setIsLoading(false)
            });
        }
        return () => {
            called.current = false
        }
    }, []);

    const fetchData = async () => {

        getData('board').then(
            response => {
                if (response.status === 'success') {
                    setMainDataOne(response.data[0])
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const fetchDataTwo = async () => {
        getData("whatwedo", "struc")
            .then((response) => {
                if (response.status === "success") {
                    setDataTwo(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="about" sub="organisationalStructure" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <div className="position-relative d-none d-md-block">
                        <img src={footer} className="w-100" />
                        <div className="position-absolute absolute-center-top top-about-line w-100 pb-5">
                            <div className="container">
                                <div className="row text-start justify-content-center" >
                                    <div className="col-12 col-md-12 col-lg-10">
                                        <div className="row text-start" >
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="font-60 weight-700 yellow-black">
                                                    Organisational Structure
                                                </div>
                                                {dataTwo.length ? dataTwo.map((val, index) => (
                                                    <div key={index}>
                                                        <div className='sub-topic yellow-black'>{val.topic}</div>
                                                        <div className="">
                                                            <div className="text-justify content-custom yellow-black"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: val.detail,
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="mt-5 mb-3 mb-md-5 text-center">
                                                            {/* <a href="#" className="btn btn-wedo green-passtel position-relative">About SEAMEO</a>
                                                            <a href="#" className="btn btn-wedo blue-passtel ms-1 ms-md-3 position-relative">SEAMEO Regional Centres</a> */}
                                                            {val.button.map((vall, indexx) => (
                                                                <a href={vall.link} target={'_blank'} key={indexx} className={"btn btn-wedo position-relative me-2 " + vall.color}>
                                                                    {vall.name}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="position-relative d-block d-md-none bg-top-left"
                        style={{ backgroundImage: `url(${footer})` }}
                    >

                        <div className="w-100 py-5 box-res-white">
                            <div className="container">
                                <div className="row text-start">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="font-60 weight-700">
                                            Organisational Structure
                                        </div>
                                        {dataTwo.length ? dataTwo.map((val, index) => (
                                            <div key={index}>
                                                <div className='sub-topic yellow-black'>{val.topic}</div>
                                                <div className="">
                                                    <div className="text-justify content-custom yellow-black"
                                                        dangerouslySetInnerHTML={{
                                                            __html: val.detail,
                                                        }}
                                                    />
                                                </div>
                                                <div className="mt-4 mb-3 mb-md-5 text-center">
                                                    {/* <a href="#" className="btn btn-wedo green-passtel position-relative">About SEAMEO</a>
                                                            <a href="#" className="btn btn-wedo blue-passtel ms-1 ms-md-3 position-relative">SEAMEO Regional Centres</a> */}
                                                    {val.button.map((vall, indexx) => (
                                                        <a href={vall.link} target={'_blank'} key={indexx} className={"btn btn-wedo position-relative me-2 " + vall.color}>
                                                            {vall.name}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        )) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="bg-top-contain box-margin-top-lop position-relative yellow-box-margin-top-lop">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-12 col-lg-10 text-start">
                                    <div className='sub-topic weight-700'>Governing Board</div>
                                    <div className="text-justify content-custom">
                                        SEAMEO SEPS is governed by the Centre’s Governing Board. Members of the Governing Board are nominated by
                                        the SEAMEO education ministers and subsequently approved by the SEAMEO Council President.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row justify-content-center py-md-5 text-start ">
                                <div className="col-11 col-md-11 col-lg-11 mt-5 mt-md-0" >
                                    <div className="row justify-content-center py-md-5 text-start ">
                                        <div className="col-12 col-md-6 col-lg-6 my-5" >
                                            <div className='position-relative box-board h-100' style={{}}>
                                                <div className='position-absolute icon-board text-center'>
                                                    <img src={process.env.REACT_APP_IMG_LINK + "/uploads/board/" + mainDataOne.id + '/' + mainDataOne.img} className="w-75" />
                                                </div>
                                                <div className='py-5 px-4 board-01 text-center' style={{}}>
                                                    <div className='weight-500 mt-5'>{mainDataOne.country}</div>
                                                    <div className='new-topic weight-700 mt-3'>
                                                        {mainDataOne.name}
                                                    </div>
                                                    <div className='font-14 color-gray mt-3 mb-5'>
                                                        {mainDataOne.position}
                                                    </div>
                                                </div>
                                                <div className='font-12 position-absolute text-board'>
                                                    {mainDataOne.text_date}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center pb-md-5 text-start ">
                                        {mainData.length ? mainData.map((val, index) => (
                                            index !== 0 &&
                                            <div className="col-12 col-md-6 col-lg-4 my-5 col-md-20" key={index}>
                                                <div className='position-relative box-board h-100' style={{}}>
                                                    <div className='position-absolute icon-board text-center'>
                                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/board/" + val.id + '/' + val.img} className="w-75" />
                                                    </div>
                                                    <div className='py-5 px-4 board-01 text-center' style={{}}>
                                                        <div className='weight-500 mt-5'>{val.country}</div>
                                                        <div className='new-topic weight-700 mt-3'>
                                                            {val.name}
                                                        </div>
                                                        <div className='font-14 color-gray mt-3 mb-5'>
                                                            {val.position}
                                                        </div>
                                                    </div>
                                                    <div className='font-12 position-absolute text-board'>
                                                        {val.text_date}
                                                    </div>
                                                </div>
                                            </div>

                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer page="about" sub="organisationalStructure" />
                </div>
            </div>

        </>
    );
};

export default CentresGoverningBoard;