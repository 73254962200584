import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

import banner from "../../../assets/img/banner-webDetail.jpg";
import content3 from "../../../assets/img/content3.jpg";
import content4 from "../../../assets/img/content4.jpg";
import download from "../../../assets/img/icon/download.svg";
import qrcode from "../../../assets/img/qr-content.jpg";
import web03 from "../../../assets/img/web-03.jpg";

import Loading from "../../../components/loading";
import { getData } from "../../../services/getService";

const Main = () => {
  // const pr = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [mainData, setMainData] = useState([]);

  const called = useRef(true);

  useEffect(() => {
    if (called.current) {
      // Promise.all([fetchData()]).then(res => {
      setIsLoading(false);
      // });
    }
    return () => {
      called.current = false;
    };
  }, []);

  // const fetchData = async () => {

  //     getData('upcomingEvent', pr.id).then(
  //         response => {
  //             if (response.status === 'success') {
  //                 setMainData(response.data)
  //             }
  //         }).catch(e => {
  //             console.log(e.response.data.error)
  //         })
  // }

  // console.log(mainData.photo ? JSON.parse(mainData.photo) : null)
  const settings = {
    dots: true,
    infinite: true,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div id="outer-container" style={{}}>
        <Header page="activities" sub="information" />
        <div className="wrapper bg-white box-index">
          {isLoading ? <Loading /> : null}
          <section className="pb-md-5 bg-top">
            <div className="container-fluid">
              <div className="row justify-content-center pb-md-5 text-start">
                <div className="col-12 col-md-12 col-lg-10 pt-md-5 mt-md-5">
                  {/* {mainData ? */}
                  <div className="px-3 py-5 py-md-0 px-md-5">
                    <div className="">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="">
                            <img src={banner} className="w-100 rounded" />
                            {/* <img src={process.env.REACT_APP_IMG_LINK + "/uploads/activity/" + mainData.id + '/' + mainData.banner} className="w-100 rounded" /> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-8">
                          <div className="topic mt-3">
                            SEP-SDGs in Action: Virtual Learning Series
                          </div>
                          <div className="mt-3">
                            Fri 6 Oct 2923 14.00 - 16.00 hrs (BKK Time/GMT+7)
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                          <a
                            href=""
                            className="btn btn-green py-2 mt-3 mt-md-0"
                          >
                            Pre-Registration
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="font-18 text-justify content-custom mt-5"
                      style={{ lineHeight: "2" }}
                    >
                      <div className="weight-700">A. Background</div>
                      SEAMEO Regional Centre for Sufficiency Economy Philosophy
                      for Sustainability (SEAMEO SEPS) was established as one of
                      the 26 SEAMEO Regional Centres under the Southeast Asian
                      Ministers of Education Organization (SEAMEO). SEAMEO SEPS
                      was established in 2019 and hosted by the Ministry of
                      Education of Thailand, with a mandate to promote the
                      philosophy, principles and values of the Sufficiency
                      Economy as an instrument for achieving Sustainable
                      Development Goals (SDG) among 11 Southeast Asian
                      Countries, namely, Brunei Darussalam, Cambodia, Indonesia,
                      Lao PDR, Malaysia, Myanmar, Philippines, Singapore,
                      Thailand, Timor-Leste, and Vietnam.
                      <br />
                      <br />
                      The concept of Sufficiency Economy Philosophy (SEP) was
                      first introduced by the late King Bhumibol Adulyadej of
                      Thailand in 1974. After the 1997 Asian crisis, Thailand
                      began to highlight SEP in the country’s five-year national
                      development plans and educational reforms through school
                      curricula. The SEP movement in Thai education started in
                      2007.
                      <br />
                      <br />
                      SEP focuses on developing a sufficiency mindset. The 3
                      pillars of SEP are: 1) moderation, 2) reasonableness and
                      3) prudence. These pillars are supported by two underlying
                      conditions: “knowledge and skills” and “ethics and
                      attributes”. Having a SEP mindset leads to balanced
                      decision-making, stability and resilience in terms of the
                      economy, society, environment, and culture for a
                      sustainable future. The SDGs were introduced by the United
                      Nations in 2015 as a global framework to end extreme
                      poverty, reduce inequality, and protect our planet by
                      2030. They came as a response to the challenges of
                      globalization and have become an important framework for
                      enhancing social development, economy and a sustainable
                      environment.
                      <br />
                      <br />
                      The SDGs framework contains 17 goals as follows.
                      <div className="p-3 p-md-5">
                        <img src={content3} className="w-100 rounded" alt="" />
                      </div>
                      The covid experience has brought to the fore the relevance
                      and close alignment of SEP and the SDGs. Increasingly
                      international organisations, governments, communities and
                      individuals look for ways to build resilience,
                      acknowledging the role of families, communities and local
                      networks in meeting basic needs and creating a safety net,
                      especially in times of crisis.
                      <br />
                      <br />
                      The SEP-SDGs in Action: Virtual Learning Series will
                      provide the foundation of the SDGs, explore the linkages
                      to SEP in various aspects, as well as highlight successful
                      case studies from various sectors such as educational
                      institutions, communities, and industries that have
                      successfully applied SDGs and SEP in their policies and
                      practices.
                      <div className="weight-700 mt-4">B. Objectives</div>
                      <div>
                        <ul>
                          <li>
                            To enrich educators, practitioners, and teachers on
                            the concept of Sufficiency Economy Philosophy (SEP)
                            and Sustainable Development Goals (SDGs) and how to
                            integrate or apply the concepts for educational
                            development and transformation in the Southeast
                            Asian countries,
                          </li>
                          <li>
                            To share up-to-date knowledge and expertise from the
                            SEAMEO SEPS’s global and regional partners that can
                            contribute to the educational development and
                            transformation for the Southeast Asian region, and
                          </li>
                          <li>
                            To provide a platform for exchanging creative ideas,
                            innovations, and best practices in relation to SDGs
                            and SEP that the educational institutions can apply
                            for the school improvement programme.
                          </li>
                        </ul>
                      </div>
                      <div className="weight-700 mt-4">C. Speakers</div>
                      <div>
                        The following speakers are invited to share in-depth
                        knowledge and expertise.
                      </div>
                      <div>
                        <ul>
                          <li>
                            Policy influencers at national, regional, and global
                            levels,
                          </li>
                          <li>Experts from advanced institutions,</li>
                          <li>School Principals/ Directors, and</li>
                          <li>Partitioners of SEP and SDGs.</li>
                        </ul>
                      </div>
                      <div className="weight-700 mt-4">C. Speakers</div>
                      <div>
                        SEP-SDGs in Action: Virtual Learning Series is offered
                        for free and on first come, first serve basis.
                        Participants are required to pre-registered before the
                        learning series.
                        <br />
                        <br />
                        The participants can include:
                      </div>
                      <div>
                        <ul>
                          <li>
                            School leaders, teachers/ lecturers from schools,
                            TVET institutions, and universities,
                          </li>
                          <li>
                            Educational personnel, ministry officers,
                            researchers, and practitioners,
                          </li>
                          <li>School/TVET/ university students,</li>
                          <li>General public</li>
                        </ul>
                      </div>
                      <div className="weight-700 mt-4">
                        E. The Learning Series
                      </div>
                      <div className="px-4">
                        <div className="weight-700 mt-4">
                          Session 1: SEP to SDGs
                        </div>
                        <div>
                          Wednesday 30 August 2023, 14.00-15.30 hrs (GMT+7/BKK
                          Time)
                        </div>
                        <div
                          className="px-3 rounded mt-2 position-relative mb-4 box-ddd"
                          style={{ background: "#F5F5F5" }}
                        >
                          Programme Agenda
                          <div className="position-absolute absolute-center-right">
                            <a className="btn btn-download">
                              <img
                                src={download}
                                className=""
                                alt=""
                                style={{ width: "25px" }}
                              />
                              Download
                            </a>
                          </div>
                        </div>
                        <div>
                          <div>What you will learn:</div>
                          <ul>
                            <li>The concepts of SEP and the SDGs</li>
                            <li>
                              The connection of SEP with the SDGs, both in
                              theory and in practice
                            </li>
                            <li>
                              Successful practices on SDGs and SEP’s integration
                              in school and community
                            </li>
                          </ul>
                        </div>
                        <div>
                          <div>Speakers</div>
                          <ul>
                            <li>
                              Dr Priyanut Dharmapiya, Senior Advisor,
                              Sufficiency Economy Philosophy and Sustainable
                              Development Centre, National Institute of
                              Development Administration Bangkok, Thailand
                            </li>
                            <li>
                              Ms Margarete Sachs-Israel, Chief, Inclusive
                              Quality Education, UNESCO Multisector Regional
                              Office, Bangkok, Thailand
                            </li>
                            <li>
                              Mr Ilan Enverga, Director for Administration &
                              Student Affairs, International School for Better
                              Beginnings, Lucena City, Philippines
                            </li>
                          </ul>
                        </div>
                        <div className="py-3 py-md-5">
                          <img src={content4} className="w-75 rounded" alt="" />
                        </div>
                        <div className="weight-700 mt-4">
                          Session 2: SDGs, Climate Action, and Culture
                        </div>
                        <div>
                          Friday 6 October 2023, 15.00-17.00 hrs (GMT+7/BKK
                          Time)
                          <ul>
                            <li>
                              Co-organised with SEAMEO Regional Centre for
                              Archaeology and Fine Arts (SEAMEO SPAFA) In
                              continuation from Session 1 which provided the
                              fundamental concept of SDGs in both theory and
                              practice focusing on school and community, the
                              three invited speakers in this session will share
                              their experience relating to SDGs from cultural
                              perspectives. Compared to other sectors, the role
                              of the cultural sector in SDGs implementation
                              seems limited. However, sustainability will hardly
                              be achieved without culture which underpins the
                              foundation of humanity and its civilisation.
                              Therefore, this session aims to provide anoverview
                              of the recognition and contribution to SDGs from
                              cultural sectors and their practitioners. The case
                              studies in SDGs implementation on cultural
                              practices and probably the consequences of the
                              UNESCO World Conference on Cultural Policies and
                              Sustainable Development will also be shared. Last
                              but not least, a toolkit for education on the SDGs
                              and relevant matters will be introduced in order
                              to provide a practical manual to be used, applied,
                              or adapted for the school community.
                            </li>
                          </ul>
                        </div>
                        <div>What you will learn:</div>
                        <ul>
                          <li>
                            The overview and roles of culture in SDGs especially
                            in relation to climate action
                          </li>
                          <li>
                            Showcases of practices professionals in the cultural
                            heritage sector in response to SDGs
                          </li>
                          <li>
                            An introduction to the toolkit entitled “Living with
                            Water, Heritage Risks: An Educator’s Toolkit for
                            Global Citizenship” which is a teaching tool being
                            conceptualized with adapted pedagogical principles
                            found in the spheres of Global Citizenship Education
                            (GCED), Sustainable Development Goals (SDGs),
                            Intangible Cultural Heritage (ICH), and Disaster
                            Risk Management (DRM).
                          </li>
                        </ul>
                        <div>Speakers:</div>
                        <ul>
                          <li>
                            Mr Henry Mcghie, Curating Tomorrow, UK, a consultant
                            specializing in museums, sustainable development and
                            the Sustainable Development Goals, climate education
                            and action, ecology and nature conservation,
                            leadership and strategy
                          </li>
                          <li>
                            Mr Gabriel Caballero, Focal Point,
                            ICOMOS-International Council on Monuments and Sites-
                            Sustainable Development Goals Working Group
                          </li>
                          <li>
                            Ms Karen Chin, Independent Consultant, Singapore
                          </li>
                        </ul>
                        <div className="weight-700 mt-4">
                          Session 3: Transforming Education to a Brighter Future
                        </div>
                        <div>
                          Wednesday 25 October 2023, 14.00-15.30 hrs (GMT+7/BKK
                          Time)
                        </div>
                        <div>Programme Agenda (TBC)</div>
                        <div className="mt-3">What you will learn:</div>
                        <div>
                          <ul>
                            <li>
                              Global and regional trends in education
                              transformation
                            </li>
                            <li>
                              Implication and challenges in addressing the
                              education transformation.
                            </li>
                            <li>
                              The important elements of SEP and SDGs to ensure
                              the sustainable development.
                            </li>
                            <li>
                              Values and new skills of teachers and learners for
                              the sustainable futures
                            </li>
                            <li>Successful practices at school level</li>
                          </ul>
                        </div>
                        <div className="mt-3">Speakers:</div>
                        <div>
                          <ul>
                            <li>
                              Dr Maki Hayashikawa, Director, UNESCO
                              Multisectoral Regional office, Indonesia
                            </li>
                            <li>
                              Dr Ethel Agnes Pascua-Valenzuela, Advisor, The
                              ASEAN Secretariat, Indonesia
                            </li>
                            <li>
                              Dr Sampun Sillapanart, Vice President and General
                              Manager (Hard Disk Drive Department), Western
                              Digital Corporation, Thailand
                            </li>
                            <li>The winner of SEAMEO-Japan ESD Award</li>
                          </ul>
                        </div>
                        <div className="weight-700 mt-4">
                          Session 4: Equity and Inclusion
                        </div>
                        <div>
                          Wednesday 15 November 2023, 14.00-15.30 hrs (GMT+7/BKK
                          Time)
                        </div>
                        <div>Programme Agenda (TBC)</div>
                        <div>What you will learn:</div>
                        <div>
                          <ul>
                            <li>(To be confirmed)</li>
                          </ul>
                        </div>
                        <div className="mt-3">Speakers:</div>
                        <div>
                          <ul>
                            <li>
                              SEAMEO Regional Centre for Special Education Needs
                            </li>
                            <li>Nisai Group, UK</li>
                            <li>
                              Dr Kanit Pamanee, Academic Officer, Special
                              Education Bureau, Thailand
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="weight-700 mt-4">
                        Pre-registration Link
                      </div>
                      <div>
                        Prior to the learning sessions, participants should
                        pre-register to the following link for obtaining the
                        meeting link of the live session.
                      </div>
                      <div>
                        <img src={qrcode} className="py-3" />
                      </div>
                      <div className="mt-3">
                        <a className="btn btn-outline-dark px-5">Go to link</a>
                      </div>
                    </div>
                  </div>
                  {/* : null} */}
                </div>
              </div>
            </div>
          </section>

          <Footer page="activities" sub="information"/>
        </div>
      </div>
    </>
  );
};

export default Main;
