import React, { useState, useEffect, useRef } from "react";

import Header from "../../layout/header";
import Footer from "../../layout/footer";

import img from "../../assets/img/bg-awards.jpg";


import Loading from "../../components/loading";
import { getData } from "../../services/getService";

const CentresOverview = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState({});

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("pageawards", "awards")
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="awards" sub="seaWasteHeroAwards" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="pb-md-5 bg-top">
                        <div className="container-fluid">
                            <div className="row justify-content-center pb-md-5 text-start">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div>
                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/awards/" + dataOne.id + '/' + dataOne.img} className="w-100" alt="" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 mt-3 mt-md-4">
                                    <div className="font-50 weight-700">{dataOne.topic}</div>
                                    
                                    <div className="font-18 text-justify content-custom pt-3"
                                        dangerouslySetInnerHTML={{
                                            __html: dataOne.detail,
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                        <Footer page="awards" sub="seaWasteHeroAwards" />
                    </section>
                </div>
            </div>
        </>
    );
};

export default CentresOverview;
