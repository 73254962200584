import { base_api, axiosHeader, axiosJson} from "../configs/fetcher";

const getData = async (type,id) => {
    let url;
    if (id) { url = `${base_api}/${type}/${id}`} else { url = `${base_api}/${type}`}
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const getDataPagination = async (page,key,type,id) => {
    let url;
    if (id) { url = `${base_api}/${type}/${id}?page=${page}&search=${key}`} else { url = `${base_api}/${type}?page=${page}&search=${key}`}
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const getUsers = async (id) => {
    let url;
    if (id) { url = `${base_api}/auth/users/${id}`} else { url = `${base_api}/auth/users`}
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}

const getBanner = async (id) => {
    let url;
    url = `${base_api}/banner`
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const getPartners = async (id) => {
    let url;
    url = `${base_api}/partners`
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}


export {
    getData,
    getDataPagination,
    getUsers,
    getBanner,
    getPartners
};
