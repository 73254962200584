import React, { useState, useEffect, useRef } from 'react';
// import { useParams, Link } from 'react-router-dom';

import Header from '../../layout/header';
import Footer from '../../layout/footer';

import bg1 from '../../assets/img/bg-board.png';

import Loading from "../../components/loading";
import { getData } from '../../services/getService';

const CentresGoverningBoard = () => {

    const [isLoading, setIsLoading] = useState(true)

    const [mainData, setMainData] = useState([]);

    const called = useRef(true)

    useEffect(() => {

        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false)
            });
        }
        return () => {
            called.current = false
        }
    }, []);

    const fetchData = async () => {

        getData('board').then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="about" sub="board" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="py-md-5 bg-top-contain" style={{ backgroundImage: `url(${bg1})` }}>
                        <div className="container py-5">
                            <div className="row justify-content-center py-md-5">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className='font-60 weight-700 mb-3'>Governing Board <br />Members</div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-10 px-5 px-md-0">
                                    <div className="row justify-content-center py-md-5 text-start ">
                                        {mainData.length ? mainData.map((val, index) => (
                                            <div className="col-12 col-md-6 col-lg-4 my-5" key={index}>
                                                <div className='position-relative box-board h-100' style={{}}>
                                                    <div className='position-absolute icon-board'>
                                                        <img src={process.env.REACT_APP_IMG_LINK + "/uploads/board/" + val.id + '/' + val.img} className="w-100" />
                                                    </div>
                                                    <div className='py-5 px-4 board-01 text-center' style={{}}>
                                                        <div className='weight-500 mt-5'>{val.country}</div>
                                                        <div className='font-26 weight-700 mt-3'>
                                                            {val.name}
                                                        </div>
                                                        <div className='color-gray mt-3 mb-5'>
                                                            {val.position}
                                                        </div>
                                                    </div>
                                                    <div className='position-absolute text-board'>
                                                        {val.text_date}
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <Footer />
                </div>
            </div>

        </>
    );
};

export default CentresGoverningBoard;