import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { getData } from '../../services/getService';

import Header from '../../layout/header';
import Footer from '../../layout/footer';
import Loading from "../../components/loading";


const Home = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const [mainData, setMainData] = useState();

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(prFormat(pr.id))]).then(res => {
                setIsLoading(false)
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const prFormat = (id) => {
        if(id === 'sepCulture'){
            return 1
        }else if(id === 'sepEnvironment'){
            return 2
        }else if(id === 'sepTechnology'){
            return 3
        }else if(id === 'sepLifeSkills'){
            return 4
        }
    }


    const fetchData = async (id) => {

        getData('thematicareas', id).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    
    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="thematicAreas" sub={pr.id} />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}

                    <section className="mb-5" id="page-wrap">
                        <div className="container-fluid">
                            <div className="row text-start justify-content-center">
                                <div className="col-12 col-md-12 col-lg-12 p-0" style={{minHeight:'100vh'}}>
                                    {mainData &&
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 col-lg-5 position-relative">
                                                <img src={process.env.REACT_APP_IMG_LINK + "/uploads/thematicareas/" + mainData.id + '/' + mainData.img} className="w-100" alt={mainData.topic} />
                                            </div>
                                            <div className="col-12 col-md-7 col-lg-7">

                                                <div className='px-3 px-md-5 py-5 py-md-0'>
                                                    <div className='topic mb-3'>
                                                        {mainData.topic}
                                                    </div>
                                                    <div className='position-relative'>
                                                        <div className='mt-3 font-18' style={{ lineHeight: '2' }}>
                                                            <div dangerouslySetInnerHTML={{ __html: mainData.detail }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer page="thematicAreas" sub={pr.id}/>
                </div>
            </div>
        </>
    );
};

export default Home;