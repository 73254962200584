import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// import banner from "../../assets/img/banner-wasteHero.png";
import backIcon from "../../assets/img/icon/back.svg";
import nextIcon from "../../assets/img/icon/sorn.svg";
import ta1 from "../../assets/img/ta1.jpg";
import ta2 from "../../assets/img/ta2.jpg";
import ta3 from "../../assets/img/ta3.jpg";
import ta4 from "../../assets/img/ta4.jpg";
import ta5 from "../../assets/img/ta5.jpg";
import ta6 from "../../assets/img/ta6.jpg";
import ta7 from "../../assets/img/1702004725c.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loading from "../../components/loading";
import { getData } from "../../services/getService";
import Modalgallery from "../../components/modalGallery";

const Main = () => {
    const pr = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [dataOne, setDataOne] = useState();

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataOne()]).then(
                (res) => {
                    setIsLoading(false);
                }
            );
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataOne = async () => {
        getData("vinformation", pr.id)
            .then((response) => {
                if (response.status === "success") {
                    setDataOne(response.data)
                }
            })
            .catch((e) => {
                console.log(e.response.data.error);
            });
    };

    const [modalgallery, setModalgallery] = useState({ view: false });

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className}>
                <img
                    src={nextIcon}
                    className="ms-2"
                    style={{ width: "12px", marginTop: "-20px" }}
                    onClick={onClick}
                />
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (

            <div className={className}>
                <img
                    src={nextIcon}
                    className="me-2"
                    style={{
                        transform: "rotate(180deg)",
                        width: "12px",
                        marginTop: "-20px",
                    }}
                    onClick={onClick}
                />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        // className: "center",
        // centerMode: true,
        centerPadding: "60px",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                <Header page="news" sub="archives" />
                <div className="wrapper bg-white box-index">
                    {isLoading ? <Loading /> : null}
                    <section className="my-5" id="page-wrap">
                        <div className="container-fluid">
                            <div className="row justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-10">
                                    {dataOne ? (
                                        <div className="px-3 px-md-3 px-lg-5">
                                            <div className="row align-items-center mb-3 mb-md-5 mt-lg-5">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="font-60 weight-700 yellow-black">
                                                        Archives
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-10">
                                                    <div className="">
                                                        {/* <Link
                                                                to="/newsandgallery/archives"
                                                                className="btn btn-back ps-0 pe-1 weight-700 font-14"
                                                            >Archives</Link>| <span className="font-14">{dataOne.name}</span> */}
                                                        <span className="">Programmes and Activities</span>|
                                                        <span className="">SEAMEO SEPS Awards </span>|
                                                        <span className="text-decoration-underline">{dataOne.name + ' ' + dataOne.year}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-2 text-end">
                                                    <div className="">
                                                        <Link
                                                            to="/newsandgallery/archives"
                                                            className="btn btn-back ps-0 weight-700"
                                                        >
                                                            <img
                                                                src={backIcon}
                                                                style={{ width: "30px" }}
                                                                alt=""
                                                            />{" "}
                                                            Back
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="mt-3">
                                                        <div className="topic weight-700"
                                                            dangerouslySetInnerHTML={{
                                                                __html: dataOne.name,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="pt-4">
                                                        {dataOne.img ?
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_IMG_LINK +
                                                                    "/uploads/vinformation/" +
                                                                    dataOne.id +
                                                                    "/" +
                                                                    dataOne.img
                                                                }
                                                                className="w-100 rounded"
                                                                alt=""
                                                            />
                                                            : <div style={{ background: "#D9D9D9", height: '400px' }}></div>}
                                                    </div>

                                                    {/* <div className="font-18 text-justify content-custom pt-5"
                                                            dangerouslySetInnerHTML={{
                                                                __html: dataOne.detail,
                                                            }}
                                                        /> */}
                                                    <div className="font-18 text-justify content-custom pt-5">
                                                        {/* <div className="font-24 weight-700">Announcement of Winners</div>
                                                    <p>
                                                        SEAMEO Regional Centre for Sufficiency Philosophy Economy for Sustainability (SEAMEO SEPS), Indorama Ventures PCL and our supporting partners would like to express our appreciation to all schools, teachers, and students from Southeast Asian countries and Bhutan for their participation in the Southeast Asian Waste Hero Awards.
                                                    </p> */}
                                                        <p>
                                                            The Southeast Asian Waste Hero Awards recognised the commitment of schools, teachers and students to the Waste Hero: Reduce to Zero initiative of SEAMEO Regional Centre for Sufficiency Philosophy Economy for Sustainability (SEAMEO SEPS), Indorama Ventures PCL and supporting partners. The Awards facilitated the sharing and exchange of ideas, creativities and best practices, inspiring all those involved to be proactive in their schools, families and communities
                                                        </p>
                                                        <br />
                                                        <p>The awards generated an enthusiastic response with 126 entries for school category, 111 entries for teacher category, 242 entries for student category (Grade 9-10-11) and 456 entries for student category (Grade 4-5-6). </p>
                                                        <br />
                                                        {/* <p>
                                                        We are pleased to announce the winners for all categories as follows:
                                                    </p> */}
                                                        <div className="mt-4 pt-3">
                                                            <div className="font-24 weight-700 text-center mb-2">Congratulations to the Winners</div>
                                                            <div className="text-center">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-12 col-md-7 col-lg-6">
                                                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/5PKTh1N7T9c?si=LKcwShnsghUKJxc0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="mt-5 mb-4">
                                                                <table className="table-bordered w-100">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2} className="text-center bg-table text-white py-2">SCHOOL CATEGORY</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="text-center">
                                                                        <tr>
                                                                            <td className="w-50">
                                                                                <img src={ta1} className="w-100 p-2" alt="" />
                                                                            </td>
                                                                            <td>
                                                                                <img src={ta2} className="w-100 p-2" alt="" />
                                                                            </td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                        <td className="weight-700">
                                                                            Kindergarten and Primary Schools
                                                                        </td>
                                                                        <td className="weight-700">
                                                                            Secondary Schools
                                                                        </td>
                                                                    </tr> */}
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                1<sup>st</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Sekolah Alam Pacitan, Indonesia</p>
                                                                                <p className="pt-2 pb-3 px-3">Project: The Waste Project
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&v=yPLjvJh4z1Q" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">St. Patrick’s Secondary School, Malaysia</p>
                                                                                <p className="pt-2 pb-3 px-3">Project: The Seven (7) Greens Programme
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&v=okmyFJnndD4&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                2<sup>nd</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Dadap Elementary School, Philippines</p>
                                                                                <p className="pt-2 pb-3 px-3">Project: Efficient Dadap Elementary School Solid Waste Management: A Sustainable Future
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&v=2ZHiW5hHt40" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Goa Science High School, Philippines</p>
                                                                                <p className="pt-2 pb-3 px-3">Project: Green Dreams and Golden Achievements: A Trash-to-Triumph Journey
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&feature=shared&v=rq_WXi090jc" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                3<sup>rd</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">SJK (C) Foon Yew 2, Malaysia</p>
                                                                                <p className="pt-2 pb-3 px-3">Project: Waste Management Programme: Low Carbon Practices
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&v=PFhV_Rni4YU&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Silliman University Senior High School, Philippines</p>
                                                                                <p className="pt-2 pb-3 px-3">Project: Beyond Plastics: Silliman's Pursuit of a Zero Waste Legacy
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&v=oPqw6AHwOyY" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="mb-4">
                                                                <table className="table-bordered w-100">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2} className="text-center bg-table text-white py-2">TEACHER CATEGORY</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="text-center">
                                                                        <tr>
                                                                            <td className="w-50">
                                                                                <img src={ta3} className="w-100 p-2" alt="" />
                                                                            </td>
                                                                            <td>
                                                                                <img src={ta4} className="w-100 p-2" alt="" />
                                                                            </td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                        <td className="weight-700">
                                                                            Kindergarten and Primary Schools
                                                                        </td>
                                                                        <td className="weight-700">
                                                                            Secondary Schools
                                                                        </td>
                                                                    </tr> */}
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                1<sup>st</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Ms. Putri Rismantia, S.T., SD Negeri 17 Talang Ubi, Indonesia
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&v=cbHzlNJWxcI&t=7s" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Ms. Divina Gripon Garcia, Naguilian National High School, Philippines
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&si=Ptj47qfEV0oY6NdZ&v=LaBf5rDYVBs&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                2<sup>nd</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">

                                                                                <p className="pt-2 pb-3 px-3">Mr. Mohd Hafiz bin Yatin, SK Sandau Kinabatangan, Malaysia
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&si=zs9G8to5PRY74p9q&v=0iFmRkGzA2s&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                            <td className="">

                                                                                <p className="pt-2 pb-3 px-3">Ms. Daisy C Sadian, Mariano Marcos State University Laboratory High School Batac, Philippines
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&si=ka6pgA-Pe7fcMgFp&v=VEjGqpDCA6w&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                3<sup>rd</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Ms. Yulia Suci Rejeki, SD Negeri Bintaro 12, Indonesia
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&v=HNLx1zPShhQ&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Ms. Nguyen Hai Nhi, Nguyen Thi Minh Khai High School, Vietnam
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&si=IcYuIU6sOZnTTqHx&v=CGlT-6VkrD8&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="mb-4">
                                                                <table className="table-bordered w-100">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2} className="text-center bg-table text-white py-2">STUDENT CATEGORY</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="text-center">
                                                                        <tr>
                                                                            <td className="w-50">
                                                                                <img src={ta5} className="w-100 p-2" alt="" />
                                                                            </td>
                                                                            <td>
                                                                                <img src={ta6} className="w-100 p-2" alt="" />
                                                                            </td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                        <td className="weight-700">
                                                                            Student Grade 4-5-6
                                                                        </td>
                                                                        <td className="weight-700">
                                                                            Student Grade 9-10-11
                                                                        </td>
                                                                    </tr> */}
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                1<sup>st</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Jemyma Lai Nen Ching, SJK C Masai, Malaysia
                                                                                    <a href="https://drive.google.com/file/d/1aZ_m445B5w7yXx-n26yTesSz5TIvEkej/view" target={'blank'} className="ms-2">(hand drawing)</a></p>
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Team Garvesters, San Quintin National High School, Philippines
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&si=CNlrvQ4Vjay6Hs4g&v=QgdKEX-swiM&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                2<sup>nd</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">

                                                                                <p className="pt-2 pb-3 px-3">Nguyen Phuong Nghi, An Lac 1 Primary School, Vietnam
                                                                                    <a href="https://drive.google.com/file/d/1nxo7xc0BZE6VQYtbSENMSWGt0RAGKMf-/view" target={'blank'} className="ms-2">(hand drawing)</a></p>
                                                                            </td>
                                                                            <td className="">

                                                                                <p className="pt-2 pb-3 px-3">Arts N’ Trash, SMA Negeri 1 Situbondo, Indonesia
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&si=yLCa-8txbYasvkwU&v=cqSdFGCw788&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} className="weight-700 p-2">
                                                                                3<sup>rd</sup> Prize Winner
                                                                            </td>
                                                                        </tr>
                                                                        <tr className="text-start">
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Amira Muthmainnah, SDN 196 Sukarasa, Indonesia
                                                                                    <a href="https://drive.google.com/file/d/1Uo-YjJAi7SR4DLRh8JLMCA9qj-yjNPZj/view" target={'blank'} className="ms-2">(hand drawing)</a></p>
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="pt-2 pb-3 px-3">Four Recycling Girls, SMJK Convent Datuk Keramat, Malaysia
                                                                                    <a href="https://www.youtube.com/watch?app=desktop&si=gcybWl0i-CANjmQA&v=OKH4yo_ta4Y&feature=youtu.be" target={'blank'} className="ms-2">(video)</a></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="weight-700 mt-5">
                                                                <p>Download the list of winners and shortlisted entries.<a href="https://drive.google.com/file/d/1Zv_exFxmvVISo-1I6rdcKkpu4BTi-jpf/view" className="ms-1 font-18 weight-700 text-decoration-underline" target={'blank'} >(Download)</a></p>
                                                            </div>
                                                            <div className="mt-3">
                                                                <div className="event-topic">Award Information</div>
                                                                <div className="font-20 weight-700">A. Three Categories</div>
                                                                <div>
                                                                    <ol className="ps-3 ms-1">
                                                                        <li>
                                                                            Schools
                                                                            <div>Success story of the school’s waste management programmes/activities through a 5-minute video clip. </div>
                                                                        </li>
                                                                        <li>
                                                                            Teachers
                                                                            <div>Success story of your innovative classroom activity on waste management through a 3-minute video clip. </div>
                                                                        </li>
                                                                        <li>
                                                                            Students
                                                                            <div>Ideas or solutions on how to reduce waste and improve the environment of your school and/or community</div>
                                                                            <div>
                                                                                <ul>
                                                                                    <li>Grade/year 4-5-6: Hand drawing by an individual student</li>
                                                                                    <li>Grade/year 9-10-11: A 3-minute video clip by teams of between 3-6 students</li>
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                                <div className="font-20 weight-700">B. Prizes</div>
                                                                <div>
                                                                    <img src={ta7} className="w-75 rounded" alt="" />
                                                                </div>
                                                                <div>
                                                                    <div className="weight-700">Note:</div>
                                                                    <div>
                                                                        <ul>
                                                                            <li>Selected winning entries were showcased in the SEAMEO SEPS Webinar on Waste Hero in January 2024.​</li>
                                                                            <li>Certificates of Recognition were provided to all winners and shortlisted entries.​</li>
                                                                            <li>Certificates of Participation were provided to all entries which met the minimum requirements and were relevant to the theme of the Award.</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="font-20 weight-700 mt-4">C. Guidelines for Entry Submission<a href="https://drive.google.com/file/d/19WKQlhD1FfgJ6FvDGBTk0QNbxqkVqMCu/view" target={'blank'} className="ms-1">(Download)</a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {dataOne.news && dataOne.news.length ?
                                                        <div>
                                                            <div className="topic mb-4 mt-5">News</div>
                                                            <div className="row">
                                                                {/* {dataNews.length ? dataNews.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => ( */}
                                                                {dataOne.news.sort((a, b) => new Date(b.dateActive) - new Date(a.dateActive)).map((val, index) => (
                                                                    <div
                                                                        className="col-12 col-md-12 col-lg-4"
                                                                        key={index}
                                                                    >
                                                                        <Link
                                                                            to={"/newsandgallery/news/" + val.newsId}
                                                                            className="a-news pb-4 mb-4 mb-md-5"
                                                                        >
                                                                            <div className="row text-start">
                                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                                    <div>
                                                                                        <img
                                                                                            src={
                                                                                                process.env.REACT_APP_IMG_LINK +
                                                                                                "/uploads/news/" +
                                                                                                val.newsId +
                                                                                                "/" +
                                                                                                val.banner
                                                                                            }
                                                                                            className="w-100 rounded"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                                    <div className="font-14 mt-2">
                                                                                        {moment(val.dateActive).format("D MMMM Y")}
                                                                                    </div>

                                                                                    <div className="news-topic mt-3 mt-md-0"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: val.topic,
                                                                                        }}
                                                                                    />
                                                                                    {/* <div className="mt-1">
                                                                    <TagView myString={val.tag} />
                                                                </div> */}
                                                                                    <div className="mt-4">
                                                                                        <LinesEllipsis
                                                                                            text={val.subTopic}
                                                                                            maxLine="3"
                                                                                            ellipsis="..."
                                                                                            trimRight
                                                                                            basedOn="letters"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mt-3">
                                                                                        <a style={{ color: "#e6c12f" }}>
                                                                                            Read More
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        : null}


                                                    <div className="pb-5 " style={{}}>
                                                        <div class="topic mb-4"> Photos of Activities</div>
                                                        <div className="mt-3 box-slide-detail">
                                                            <Slider {...settings}>
                                                                {JSON.parse(dataOne.photo).length
                                                                    ? JSON.parse(dataOne.photo)
                                                                        .slice(0, 5)
                                                                        .map((val, index) => (
                                                                            <div className="" key={index}>
                                                                                <div
                                                                                    className="px-1 cursor-pointer"

                                                                                    onClick={() =>
                                                                                        setModalgallery({
                                                                                            view: true,
                                                                                            gallery: JSON.parse(
                                                                                                dataOne.photo
                                                                                            ),
                                                                                            dataUrl:
                                                                                                process.env
                                                                                                    .REACT_APP_IMG_LINK +
                                                                                                "/uploads/vinformation/" +
                                                                                                dataOne.id +
                                                                                                "/" +
                                                                                                val,
                                                                                            active: index,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            process.env.REACT_APP_IMG_LINK +
                                                                                            "/uploads/vinformation/" +
                                                                                            dataOne.id +
                                                                                            "/" +
                                                                                            val
                                                                                        }
                                                                                        className="w-100 rounded"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    : null}
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer page="activities" sub="partnership" />
                </div>
            </div>
            {modalgallery.view ? (
                <Modalgallery
                    show={modalgallery.view}
                    onHide={() => setModalgallery({ view: false })}
                    dataUrl={modalgallery.dataUrl}
                    folder={"vinformation"}
                    gallery={modalgallery.gallery}
                    active={modalgallery.active}
                    id={pr.id}
                />
            ) : null}
        </>
    );
};

export default Main;
